import Layout from '../../components/general/layout';
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode } from '../../store/user';
import { Helmet } from 'react-helmet';
import SeoData from '../../utils/seo.json';
import AiaLogo from "../../images/landings/palestine/aia-logo.png"
import MatwLogo from '../../images/general/MATW-Logo.png';
import FosteringBrighterFuturesImg from "../../images/landings/palestine/fostering-brighter-futures.jpg"
import HelpEveyOrphanImg from "../../images/landings/palestine/help-every-orphan.jpg"

import FundraiserProductBox from '../../components/general/fundraiserProductBox';
import { Loader, OurPromiseSection } from '../../components/general';
import { useEffect, useRef, useState } from 'react';
import services from '../../services';
import CustomAccordion from '../../components/general/customAccordion';
import { orphanSponsorshipFaqs } from './components/faqs';
import OrphansAIATabs from './components/orphansAIATabs';
import { selectProducts } from '../../store/products';

const OrphansAiaSponsorshipLandingPage = () => {
  /* ----------------------------- Start variables ---------------------------- */
  const selectedcountryCode = useSelector(selectSelectedCountryCode) || 'AU';
  const products = useSelector(state => selectProducts(state));
  const [isLoaded, setIsLoaded] = useState(false);
  const tabsRef = useRef(null);
  const seo = SeoData[selectedcountryCode];
  const [futureProducts, setFutureProducts] = useState();
  const [studentSponsorShipProduct, setStudentSponsorShipProduct] = useState([0]);
  const [familySponsorShipProduct, setFamilySponsorShipProduct] = useState([0]);
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  const getAllProducts = () => {
    setIsLoaded(true);
    let tempStudentPrd = [];
    let tempFamilyPrd = [];
    products.map(item => {
      item.sub_categories.map(inner_item => {
        inner_item.products.map(inner_item_product => {
          // Student sponsorship
          if (inner_item_product.creator === 'EMR-AIA-24-25') tempStudentPrd.push(inner_item_product);

          // Family sponsorship
          if (inner_item_product.creator === 'EMR-AIA-24-26') tempFamilyPrd.push(inner_item_product);
          return inner_item_product;
        });
        return inner_item;
      });
      return item;
    });
    console.log("prs==", tempFamilyPrd, tempStudentPrd)
    setStudentSponsorShipProduct(tempStudentPrd);
    setFamilySponsorShipProduct(tempFamilyPrd);
  };
  const getProductsByName = async (name) => {
    let tempFutureProducts = [];
    try {
      const response = await services.categoryProductsByName(name);
      response.data.products.map(item => {
        item.raised = 0;
        if (Number(item.status) === 1) {
          if (item.name === 'General Orphan Support') {
            // item.description = 'Give whatever you can and help MATW support these orphans in any way possible';
            tempFutureProducts.push(item)
          }
          if (item.name === 'Nurturing Futures') {
            item.goal = 200000;
            tempFutureProducts.push(item)
          }
          if (item.name === 'HealthGuard Orphans') {
            item.goal = 200000;
            tempFutureProducts.push(item)
          }
          if (item.name === 'Orphan Centre Lebanon') {
            item.goal = 500000;
            tempFutureProducts.push(item)
          }
        }
        return item;
      });
      getOrphanProductsReports(tempFutureProducts);
    } catch (error) {
      console.error('error========', error);
    }
  };
  const getOrphanProductsReports = async (tempFutureProducts) => {
    try {
      const response = await services.orphanProductTransactions();
      // console.log("response=", response, tempFutureProducts)
      response.data.length && response.data.map((item, i) => {
        tempFutureProducts.map(future_item => {
          if (future_item.name === item.product_name) {
            future_item.raised = item.total_sales
          }
          return future_item
        })
        return null;
      })
      setFutureProducts(tempFutureProducts)
      // console.log('future products===', tempFutureProducts);
    } catch (error) {
      console.error('error========', error);
    }
  };
  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    products && Object.keys(products).length && !isLoaded && getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);
  useEffect(() => {
    getProductsByName('support an orphan');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  /* -------------------------------------------------------------------------- */
  //init

  return (
    <Layout className="!bg-white pt-5-" childClasses="pb-[98px] md:pb-0" isRamadanPage hideNavbar hideHighlights hideFooter hideMainFooter>
      {seo && (
        <Helmet>
          <title>{`${seo['orphans-aia-sponsorship']['title']}`}</title>
          <meta name="title" content={`${seo['orphans-aia-sponsorship']['title']}`} />
          <meta name="description" content={`${seo['orphans-aia-sponsorship']['description']}`} />
        </Helmet>
      )}

      <section className='flex justify-center items-center py-4 md:py-6 px-4 md:px-0 mb-12 gap-2 md:gap-4 bg-[url("../src/images/landings/palestine/palestine-partenship-banner.png")] bg-cover'>
        <img src={MatwLogo} alt="MATW-Project Logo" className="max-w-[25%] md:max-w-[150px] object-contain" />
        <h1 className="text-2xl md:text-4xl text-center text-white font-medium-">
          AIAE Orphan
          <br />
          Sponsorship Project
        </h1>
        <img src={AiaLogo} alt="Australian International Academy Logo" className="max-w-[25%] md:max-w-[180px] object-contain" />
      </section>

      <section className='max-w-[1280px] mx-auto'>
        {/* /* --------------------------------- Banner --------------------------------- */}
        <iframe
          className={`video aspect-[16/9]`}
          width={'100%'}
          title={'youtube'}
          frameBorder={0}
          sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
          src={"https://www.youtube.com/embed/NnysXG8OJBc?controls=1&autoplay=0&playlist=NnysXG8OJBc"}
        ></iframe>
        {/* /* -------------------------------------------------------------------------- */}
      </section>

      {/* /* -------------------------------- Our Promise Section -------------------------------- */}
      <OurPromiseSection alt="Donate to palestine with our 100% Donation policy" />
      {/* /* -------------------------------------------------------------------------- */}


      {/* /* --------------------------------- Joint Orphan Sponsorship Section --------------------------------- */}
      <div className="w-full" ref={tabsRef}>
        <OrphansAIATabs
          studentSponsorShipProduct={studentSponsorShipProduct}
          familySponsorShipProduct={familySponsorShipProduct}
        />
      </div>
      {/* /* -------------------------------------------------------------------------- */}

      {/* /* --------------------------------- Heartfelt Connections Section --------------------------------- */}
      <section>
        <div className="md:container w-full mx-auto px-4 md:px-0 py-4 md:py-12 flex flex-col gap-y-2.5 md:gap-y-5">
          <h1 className='text-[25px] md:text-[40px] font-brandingBold font-bold text-center text-[#253B7E]'><span className='font-gotcha font-normal text-[#ED1463] mr-2'>Heartfelt</span>  Connections</h1>
          <p className='text-[#78716C] max-w-[1080px] mx-auto text-center text-base md:text-lg font-medium font-brandingMedium'>Every sponsor will receive a personalized video message from the orphan they sponsor. These messages will bridge distances and create meaningful connections. Additionally, AIAE staff members plan to visit the orphanage, exchanging gifts and video messages between sponsors and the orphans. It’s a beautiful way to strengthen bonds and share love across borders.</p>
        </div>
      </section>

      {/* /* -------------------------------- Donate Section -------------------------------- */}
      <section className='w-full min-h-[392px] h-[calc(100vw/2.5)] !bg-cover !bg-center flex items-center' style={{ background: "linear-gradient(180deg, rgba(37, 59, 126, 0.70) 0%, rgba(246, 3, 98, 0.42) 100%), url(/images/banners/Support-Orphans-Banner.jpeg)" }}>
        <div className="md:container w-full mx-auto px-4 md:px-0 py-4 md:py-12 flex flex-col gap-y-2.5 md:gap-y-5">
          <h1 className='text-[25px] leading-7 md:leading-10 lg:text-[40px] uppercase font-brandingMedium text-white'><span className='font-brandingBold font-bold'>Together</span>, We Can Make a Difference</h1>
          <p className='mt-5 max-w-[760px] text-white lg:text-lg text-base font-brandingMedium'>
            By participating in this joint project, you’re not only changing individual lives but also contributing to a brighter future for our global community. Let’s stand together for compassion and hope.
            <br /><br />
            Join us in this noble cause. Together, we can create a world where every child feels loved, supported, and empowered.
            <br /><br />
            For inquiries, reach out to <a href="mailto:orphans@aia.vic.edu.au" className='font-bold underline' >orphans@aia.vic.edu.au</a>
          </p>
        </div>
      </section>
      { /* -------------------------------------------------------------------------------- */}

      {/* /* ------------------- Fundraiser Products ------------------------------- */}
      <div className="w-full flex flex-wrap justify-center md:container md:mx-auto px-4 md:px-0 py-8 md:py-16">
        <div className="basis-full flex flex-col justify-center mb-8 md:mb-16">
          <h1 className="text-2xl md:text-4xl text-[#253B7E] text-center font-medium mb-2 md:mb-4">
            Build a Brighter Future for Them
          </h1>
          <p className='w-full text-center mb-4 md:mb-8 text-lg md:text-xl'>
            Help us raise funds to provide orphans with essential needs, education, and a chance at a better future. Every contribution counts!
          </p>
        </div>
        {futureProducts
          ? <div className="flex flex-wrap justify-center">{futureProducts.map((item, index) => {
            return <div key={`fundrising${index}`} className="basis-full min-[400px]:basis-1/2 md:basis-1/3 p-2 overflow-hidden">
              <FundraiserProductBox hideStatus goal={item.goal} subTitle='Orphan Education' product={item} currency={'AUD'} disableOnClick onClick={() => window.scrollTo({ behavior: 'smooth', top: tabsRef.current.offsetTop + 150 })} />
            </div>
          })}</div>
          : <Loader />
        }
      </div>
      {/* /* -------------------------------------------------------------------------- */}


      {/* /* ------------------------  Help us -------------------------- */}
      <div className="w-full flex flex-col lg:flex-row justify-between md:container md:mx-auto px-4 md:px-0 pt-4 md:pt-8 mb-8 md:mb-0 mt-8 md:mt-16">
        <div className="flex w-full lg:w-1/2 justify-center items-center lg:pr-8">
          <img src={HelpEveyOrphanImg} alt="" className='w-full h-[280px] md:h-[400px] rounded-lg object-cover' />
        </div>
        <div className="mt-8 lg:mt-0 flex w-full lg:w-1/2 flex-col justify-center items-start md:pl-8">
          <h1 className="font-bold text-[25px] md:text-[40px] leading-[27px] md:leading-[42px] text-[#253B7E] mb-2 md:mb-4 mt-4 md:mt-0 font-brandingBold">
            Help us reach every orphan around the world
          </h1>
          <p className='text-base md:text-lg text-[#78716C] font-brandingMedium'>
            Every single day, 5,700 children become orphaned. We have to help them.
            <br />
            <br />
            One of the most important societal issues that requires immediate attention is the general support of orphans.
            Orphans are among society's most vulnerable groups, and they require a nurturing environment in order to develop and flourish.
            We are an international nonprofit organisation called MATW Project and we work tirelessly to support orphans all over the world.
          </p>
        </div>
      </div>
      {/* /* -------------------------------------------------------------------------- */}

      {/* /* ------------------------  Fostering Brighter Futures Section -------------------------- */}
      <div className="w-full flex flex-col lg:flex-row justify-between md:container md:mx-auto px-4 md:px-0 pt-4 md:pt-8 mb-8 md:mb-0 mt-8 md:mt-16">
        <div className="flex w-full lg:w-1/2 justify-center items-center lg:order-2 lg:pl-8">
          <img src={FosteringBrighterFuturesImg} alt="" className='w-full h-[280px] md:h-[400px] rounded-lg object-cover' />
        </div>
        <div className="mt-8 lg:mt-0 flex w-full lg:w-1/2 flex-col justify-center items-start md:pl-8">
          <h1 className="font-bold text-[25px] md:text-[40px] leading-[27px] md:leading-[42px] text-[#253B7E] mb-2 md:mb-4 mt-4 md:mt-0 font-brandingBold">
            Fostering Brighter Futures
          </h1>
          <p className='text-base md:text-lg text-[#78716C] font-brandingMedium'>
            Become a part of their incredible journey, where every penny you give makes a lasting difference in the lives of these remarkable children.
            <br /><br />
            Embrace the opportunity to nurture the growth and well-being of our precious orphan centers. Your heartfelt contribution carries immeasurable
            significance, enabling us to provide a warm and loving sanctuary where orphaned children can not only survive but truly flourish.
          </p>
        </div>
      </div>
      {/* /* -------------------------------------------------------------------------- */}

      {/* /* -------------------------------- FAQ's -------------------------------- */}
      <section className="max-w-[1440px] md:mx-auto px-4 xl:px-20 mt-10">
        <div className="md:container md:mx-auto flex flex-col flex-wrap items-center justify-center md:px-0 mt-6">
          <CustomAccordion>
            <CustomAccordion.Title tag="h2">
              Orphan Sponsorship  <span className="text-[#F60362]"> FAQ’s</span>
            </CustomAccordion.Title>
            <CustomAccordion.Header>
              {orphanSponsorshipFaqs &&
                orphanSponsorshipFaqs.map(
                  ({ question, answer }, index) =>
                    index < 3 && (
                      <CustomAccordion.HeaderContent key={index}>
                        <CustomAccordion.HeaderQuestion>{question}</CustomAccordion.HeaderQuestion>
                        <CustomAccordion.HeaderAnswer>{answer}</CustomAccordion.HeaderAnswer>
                      </CustomAccordion.HeaderContent>
                    ),
                )}
            </CustomAccordion.Header>
            <div className="mt-6">
              <CustomAccordion.Accordion items={orphanSponsorshipFaqs.filter((_, index) => index > 2)} />
            </div>
          </CustomAccordion>
        </div>
      </section>
      {/* /* -------------------------------------------------------------------------- */}

      {/* /* --------------------------------- Footer --------------------------------- */}
      <div className="bg-[#282828] py-6 px-4 lg:px-12 w-full mt-20">
        <p className="text-center text-[#aaa] text-[15px] copy-right">
          Muslim Around The World (MATW) Project
        </p>
      </div>
      {/* /* -------------------------------------------------------------------------- */}
    </Layout>
  );
};
export default OrphansAiaSponsorshipLandingPage;