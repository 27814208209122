import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../store/user';
import { OurPromiseSection } from '../../components/general';
import { masjidsFaqs } from './faqs';
import Layout from '../../components/general/layout';
import Accordion from '../../components/general/accordion';
import ProductBox from '../../components/general/productBox';
import { Helmet } from 'react-helmet';
import SeoData from '../../utils/seo.json';
import CrisisWidget from '../crisis-and-emergencies/components/crisisWidget';
import ZakatWidget from '../zakat/zakatWidget';
import { useKeenSlider } from 'keen-slider/react';
import Process from '../../images/sadaqah/process.jpg';
import WhatIf from '../../images/sadaqah/whatIf.jpg';
import Masjid1 from '../../images/sadaqah/masjid1.jpg';
import Masjid2 from '../../images/sadaqah/masjid2.jpg';
import Masjid3 from '../../images/sadaqah/masjid3.jpg';
import Masjid4 from '../../images/sadaqah/masjid4.jpg';
import Masjid5 from '../../images/sadaqah/masjid5.jpg';
import bannerMobile from '../../images/sadaqah/Masjid-Generic-Hero-Banner-Mobile.jpg';
// import { useNavigate } from 'react-router-dom';
import { selectProducts } from '../../store/products';
import CustomButton from '../../components/general/button';
const images = [
  { src: Masjid1, alt: 'build a mosque sadaqah jariyah' },
  { src: Masjid2, alt: 'best charity to build a mosque' },
  { src: Masjid3, alt: 'build a mosque donation' },
  { src: Masjid4, alt: 'donate to build a mosque' },
  { src: Masjid5, alt: 'how to build a mosque, charity' },
];

const MasjidsNewLandingPage = ({ type = 'buildAMasjid' }) => {
  /* ----------------------------- Start variables ---------------------------- */
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const products = useSelector(state => selectProducts(state));
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [masjidProducts, setMasjidProducts] = useState([0, 0, 0, 0, 0, 0, 0, 0]);
  const selectedcountryCode = useSelector(selectSelectedCountryCode);
  const seo = SeoData[selectedcountryCode];
  const [isLoaded, setIsLoaded] = useState(false);
  // const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);
  const [currentSlideImages, setCurrentSlideImages] = useState(0);
  const [sliderRefImages, instanceRefImages] = useKeenSlider(
    {
      mode: 'free-snap',
      loop: true,
      slideChanged(slider) {
        setCurrentSlideImages(slider.track.details.rel);
      },
      created() {
        setLoaded(true);
      },
      slides: {
        origin: 'auto',
        perView: 1,
        spacing: 8,
      },
    },
    [
      slider => {
        let timeout;
        let mouseOver = false;
        function clearNextTimeout() {
          clearTimeout(timeout);
        }
        function nextTimeout() {
          clearTimeout(timeout);
          if (mouseOver) return;
          timeout = setTimeout(() => {
            slider.next();
          }, 2000);
        }
        slider.on('created', () => {
          slider.container.addEventListener('mouseover', () => {
            mouseOver = true;
            clearNextTimeout();
          });
          slider.container.addEventListener('mouseout', () => {
            mouseOver = false;
            nextTimeout();
          });
          nextTimeout();
        });
        slider.on('dragStarted', clearNextTimeout);
        slider.on('animationEnded', nextTimeout);
        slider.on('updated', nextTimeout);
      },
    ],
  );
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */

  const getAllProducts = () => {
    setIsLoaded(true);
    let masjidProducts = [];
    products.map(item => {
      item.sub_categories.map(inner_item => {
        inner_item.products.map(inner_item_product => {
          if (inner_item_product.creator === 'MSJ-CON-PAK-2024-01') {
            // Pakistan Masjid
            inner_item_product = Object.assign({}, inner_item_product, {
              alt: 'Build a Masjid in Pakistan',
            });
            masjidProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'MSJ-CON-YEM-2024-02') {
            // Yemen Masjid
            inner_item_product = Object.assign({}, inner_item_product, {
              alt: 'Build a Masjid in Yemen',
            });
            masjidProducts[1] = inner_item_product;
          }
          if (inner_item_product.creator === 'MSJ-CON-IMJ-2023-01-014') {
            // Indonesia Masjid
            inner_item_product = Object.assign({}, inner_item_product, {
              alt: 'Build a Mosque in Indonesia',
            });
            masjidProducts[2] = inner_item_product;
          }
          if (inner_item_product.creator === 'GD-MP015') {
            // General Masjid Donations
            inner_item_product = Object.assign({}, inner_item_product, {
              alt: 'Build a Mosque',
            });
            masjidProducts[3] = inner_item_product;
          }
          if (inner_item_product.creator === 'MSJ-WA-SMJ-2023-01') {
            // Africa Small Masjid
            inner_item_product = Object.assign({}, inner_item_product, {
              alt: 'Build a Mosque in Africa',
            });
            masjidProducts[4] = inner_item_product;
          }
          if (inner_item_product.creator === 'MSJ-WA-LRG-2023-02') {
            // Africa Large Masjid
            inner_item_product = Object.assign({}, inner_item_product, {
              alt: 'Build a Masjid in Africa',
            });
            masjidProducts[6] = inner_item_product;
          }
          masjidProducts[5] = { video_src: 'https://www.youtube.com/embed/1tqSJqoKJ30' };
          masjidProducts[7] = { video_src: 'https://www.youtube.com/embed/QAQgj4qQKcU' };
          return inner_item_product;
        });
        return inner_item;
      });
      return item;
    });
    console.log('masjid-Products=', masjidProducts);
    masjidProducts = masjidProducts.filter(n => n);
    setMasjidProducts(masjidProducts);
  };
  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
  }, [selectedCurrencyStore]);
  useEffect(() => {
    products && Object.keys(products).length && !isLoaded && getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);
  /* -------------------------------------------------------------------------- */
  //init

  return (
    <Layout className="bg-white" hideFooter>
      {seo && (
        // <Helmet>
        //     <title>{`${seo['sadaqahJariyah']['buildAMasjid']['title']}`}</title>
        //     <meta name="title" content={`${seo['sadaqahJariyah']['buildAMasjid']['title']}`} />
        //     <meta name="description" content={`${seo['sadaqahJariyah']['buildAMasjid']['description']}`} />
        // </Helmet>
        <Helmet>
          <title>{`${seo['sadaqahJariyah'][type]['title']}`}</title>
          <meta name="title" content={`${seo['sadaqahJariyah'][type]['title']}`} />
          <meta name="description" content={`${seo['sadaqahJariyah'][type]['description']}`} />
        </Helmet>
      )}
      {/* /* --------------------------------- Banner ---------------------------------  */}
      <section className="flex justify-center">
        <img
          src="https://cdn.matwproject.org/images/banners/Masjid-Generic-Hero-Banner-Desktop.2bef3ebceb7a2522ac71.jpg"
          alt="Build a Mosque Sadaqah Jariyah"
          className="max-[500px]:hidden w-full"
        />
        <img src={bannerMobile} alt="Build a Masjid, Sadaqah Jariyah" className="min-[501px]:hidden w-full" />
      </section>
      <section className="bg-[#DFEEF2]">
        <section className="max-w-[1440px] mx-auto">
          <div className="text-[#093686] pt-5 leading-[45px] text-center text-[35px] font-bold">
            {type === 'buildAMasjid' ? ' Make a donation' : 'Donation for Masjid'}
          </div>
          <div className="w-full hidden lg:mx-auto px-4 xl:px-10 py-4 md:py-6 sm:flex justify-center items-center">
            <CrisisWidget
              className={'bg-[#DFEEF2] px-6 h-auto pt-10'}
              defaultProduct={{
                creator: 'GD-MP015',
                label: 'General Masjid Donations',
                value: 168,
              }}
            />
          </div>
          <div className="w-full sm:hidden lg:mx-auto px-4 xl:px-10 py-4 md:py-6 flex justify-center items-center">
            <ZakatWidget
              hasSuggestPrice
              defaultProduct={{
                creator: 'GD-MP015',
                label: 'General Masjid Donations',
                value: 168,
              }}
            />
          </div>
        </section>
      </section>
      <OurPromiseSection />
      <main className="max-w-[1440px] mx-auto">
        <section className="xl:px-20 mb-8 sm:mb-16">
          <div className="relative overflow-hidden flex flex-wrap">
            <div
              className="order-2 z-10 sm:order-1 bg-[#F60362] flex flex-col items-center md:items-start justify-center w-full md:max-w-[365px] min-h-[300px]
                        md:min-h-[300px] lg:min-h-[305px] md:top-1/2 md:-translate-y-1/2
                        right-16 bg-no-repeat bg-cover bg-center md:rounded-2xl md:absolute  "
            >
              <div className="leading-[50px]  w-full text-[24px] sm:text-[50px] md:text-[30px] lg:text-[28px] px-8 text-white text-center font-brandingBold">
                The Prophet PBUH said:
              </div>
              <p className="text-[18px] sm:text-[18px] font-brandingMedium leading-6 mt-3 px-10 text-white text-center">
                "Whoever builds a mosque for the sake of Allah, like a sparrow's nest for Allah or even smaller, Allah will build for him a house in
                Paradise."
              </p>
            </div>
            <div className="hidden sm:block absolute w-full h-full bg-[#00000085]" />
            <div className=" basis-full max-[380px]:min-h-[209px] min-[381px]:min-h-[215px] min-[410px]:min-h-[230px] sm:h-[300px] md:h-[600px] lg:h-[1020px]">
              <iframe
                className="video pointer-events-none"
                width={'100%'}
                height={'100%'}
                title={'youtube'}
                frameBorder={0}
                sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                src={`https://www.youtube.com/embed/ZD92px6M8JQ?controls=0&autoplay=1&loop=1&mute=1&playlist=ZD92px6M8JQ`}
              ></iframe>
            </div>
          </div>
        </section>
        <section className="xl:px-10">
          <div className="flex justify-center">
            {type === 'buildAMasjid' ? (
              <div className="text-center font-bold text-[#093686] leading-[30px] sm:leading-[55px] text-2xl sm:text-6xl px-4 w-full sm:w-10/12">
                Build your very own Masjid for the sake of Allah, and <span className="text-[#F60362] mr-2">build your palace in Jannah,</span> just
                as Ali Banat did.
              </div>
            ) : (
              <h1 className="text-center font-bold text-[#093686] leading-[30px] sm:leading-[55px] text-2xl sm:text-6xl px-4 w-full sm:w-10/12">
                Donation for Masjid: Build Your House in Jannah By Building a Masjid on Earth
              </h1> 
            )}
          </div>
          {type !== 'buildAMasjid' ? (
            <div className=" px-4 md:px-6 mt-2 sm:mt-10  font- leading-5">
              <h2 className="text-[#78716C] leading-6 text-[24px] text-left sm:text-center font-brandingMedium">
                Invest In Eternal Rewards And Secure Your Future By Helping Impoverished Communities Come Together For The Sake Of Allah SWT
              </h2>
            </div>
          ) : (
            ''
          )}

          <div className="px-4 md:px-6 mt-2 sm:mt-10  font- leading-5">
            <div className="text-[#78716C] leading-6 text-[18px] text-left sm:text-center font-brandingMedium">
              The ultimate investment in your Akhirah is a Sadaqah Jariyah that keeps on giving. What better investment is there than that of place of
              worship. By building a Masjid or Mosque, you’re not only providing a place of worship, you’re providing a hub for communities to gather,
              learn, hold community festivities and so much more!
              <br />
              <br />
              Imagine the reward for every Sujood in prayer. Imagine the reward for every letter in the Quran that is learned and recited - what a
              blessing!
            </div>
            {/* <div className='flex justify-center mt-8 '>
                            <button onClick={() => navigate('/zakat-calculator')} className='bg-[#F60362] text-sm sm:text-lg rounded-full w-[455px] text-white h-[50px]'>
                                Calculate and give your Zakat Al Mal here
                            </button>
                        </div> */}

            <div className="flex flex-row justify-center flex-wrap col-span-12 mt-10 mb-6 sm:mb-10">
              {masjidProducts &&
                masjidProducts.map((item, index) => {
                  return (
                    <div key={`makedonation${index}`} className="basis-1/2 sm:basis-1/3 md:basis-1/3 xl:basis-1/4 p-2">
                      {item.video_src ? (
                        <div
                          key={index}
                          className={`basis-full border p-4 bg-white border-[#dddddd] shadow-[0px_10px_25px_0px_rgba(37,42,52,0.08)] h-full rounded-[10px]`}
                        >
                          <iframe
                            className="video rounded-xl"
                            width={'100%'}
                            height={'100%'}
                            title={'youtube'}
                            sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                            src={item.video_src}
                          />
                        </div>
                      ) : (
                        <ProductBox
                          product={item}
                          alt={item.alt}
                          isStaticProduct={item.isStaticProduct || false}
                          currency={selectedCurrency ? selectedCurrency : 'AUD'}
                        />
                      )}
                    </div>
                  );
                })}
            </div>
          </div>
        </section>

        <section className="xl:px-20  py-8">
          <div className="mb-12">
            <div className="flex justify-center pt-4">
              <h1 className="text-left sm:text-center font-bold text-[#253B7E] text-2xl sm:text-6xl px-4 w-full sm:w-8/12">
                {type === 'buildAMasjid'
                  ? 'Build a Mosque, Sadaqah Jariyah. Eternal charity through building a Masjid'
                  : 'Donation For Masjid - A Powerful Sadaqah Jariyah'}
              </h1>
            </div>
            <div className="px-4 md:px-28 text-[18px] mt-8 leading-5">
              {type === 'buildAMasjid' ? (
                <p className='className="text-[#78716C] leading-[20px] sm:leading-[25px] font-brandingMedium text-[18px] "'>
                  We take every single pound, penny, euro and dollar seriously here at MATW. It’s an honour to be the custodians of this Amanah -
                  especially when it comes to investment in your Akhirah.
                  <br />
                  <br />
                  From the moment you process your donation, and commit to building your Masjid (your house in Jannah Insha’Allah!), our teams refer
                  to sites that have been surveyed based on community needs. Often, this would be a village without a Masjid, or villages where
                  they’ve been destroyed due to conflict or natural disasters.
                  <br />
                  <br />
                  We have various options for Masjids depending on the type you wish to build and the location. All you need to do is make this
                  beautiful intention, process your donation and leave the rest to our team.
                </p>
              ) : (
                <p p className='className="text-[#78716C] leading-[20px] sm:leading-[25px] font-brandingMedium text-[18px] "'>
                  The Prophet Muhammad SAW emphasised the significance of ongoing charity after one’s death, saying:
                  <br />
                  <span className="italic">
                    ‘When a man dies, all his good deeds come to an end except three: ongoing charity (sadaqah jariyah), beneficial knowledge, or a
                    righteous son who will pray for him.’ (Muslim).
                  </span>
                  <br />
                  By contributing to the construction of a masjid, every act of worship, every prayer, and every good deed that happens within its
                  walls will generate ongoing rewards for you (or your loved ones). It's a divine investment that keeps on giving, ensuring you reap
                  the benefits in this world and in the Hereafter!
                  <br />
                  <br />
                  <span className="font-bold"> The Prophet SAW also said:</span>
                  <br />
                  ‘Whoever builds a Mosque for Allah, Allah will build for him a similar house in Jannah.’ (Sahih Muslim) Building a house in this
                  world is hard work - you spend years working, saving money and sacrificing your time, health and sanity to own your own home… Only
                  to leave it to others when you pass away! Now imagine donating a small amount every month to help build and maintain mosques in
                  underdeveloped countries only to find that in the hereafter, Allah SWT has built one for you in Jannah! And what could be better
                  than a house in Jannah? A home built from bricks of gold and silver for you to enjoy for an eternity… And unlike a house in this
                  dunya, it won’t cost you a small fortune…
                  <br />
                  <br />
                  <span className="font-bold text-2xl">The Plight of Communities Without Mosques</span>
                  <br />
                  Despite the unimaginable rewards of building a masjid, sadly, thousands of Muslim communities across the world don’t have one (or if
                  they do, it’s poorly maintained due to a lack of funds, natural disasters and other challenges). Here are just a few ways these
                  communities suffer…
                  <br />
                  <br />
                  <span className="font-bold italic">Spiritual Isolation</span>
                  <br />
                  <span className="font-bold">
                    Without a central place of worship, Muslims are deprived of a communal space to gather for prayers, learn about their faith, and
                    support each other. This isolation can lead to weakened faith and a sense of loneliness.
                  </span>
                  <br />
                  <br />
                  <span className="italic font-bold"> Lack of Religious Education</span>
                  <br />
                  Children lack access to proper Islamic education, which means they might not learn the basics of their faith, the Quran, or even how
                  to pray. Without this foundation, they risk losing their religious identity over time.
                  <span className="italic bold">
                    {' '}
                    Cultural Disconnection Without a masjid, there is no hub for cultural and religious events. Important traditions and celebrations
                    like Ramadan, Eid, and community iftars are neglected, leading to a loss of unity.
                  </span>
                  <br />
                  <br />
                  <span className="font-bold">Social Challenges</span>
                  <br />
                  Without a masjid, new Muslim converts often feel isolated and unsupported. They lack a community to help them navigate their new
                  faith and face the challenges of integrating Islamic practices into their daily lives.
                  <br />
                  <br />
                  <span className="font-bold">Safety and Security</span>
                  <br />
                  In communities without masjids, Muslims may feel unsafe practising their faith openly. The absence of a mosque as a recognized and
                  respected place of worship can lead to misunderstandings and prejudices from the wider community. That’s why we need your help…
                </p>
              )}
            </div>
          </div>
        </section>

        <section className="xl:px-10 py-8">
          <div className="mb-12">
            <div className="flex justify-center pt-4">
              <h1 className="text-left sm:text-center font-bold text-[#253B7E] text-2xl sm:text-3xl px-4 w-full sm:w-8/12">
                Donation For Masjid: The Ultimate Investment In Your Akhirah
              </h1>
            </div>
            <div className="px-4 md:px-28 text-[18px] mt-8 leading-5 pt-4">
              <p className="text-[#000000] leading-[20px] sm:leading-[25px] font-brandingMedium text-[18px] mt-6">
                A masjid is not just a place of worship. It’s a central hub which encourages Muslims to unite, a place of learning and, a supportive
                environment for the community.
              </p>
              <p className="text-[#000000] leading-[20px] sm:leading-[25px] font-brandingMedium text-[18px] mt-6">
                A masjid donation ensures you’re not only investing in the community, but you’re also investing in your akhirah. As the Prophet SAW
                said:
              </p>
              <p className="text-[#000000] leading-[20px] sm:leading-[25px] font-brandingMedium text-[18px] ">
                <span className="font-bold">“Whoever builds a mosque for Allah, Allah will build for him a house like it in Paradise.”</span>
                <br />
                (Bukhari, Muslim)
              </p>
              <p className="text-[#000000] leading-[20px] sm:leading-[25px] font-brandingMedium text-[18px] mt-6">
                An ongoing donation for masjid means continual rewards in this life and the hereafter and makes an excellent form of sadaqah jariyah.
                Imagine being rewarded for every sujood that takes place in the masjid... Imagine the reward for every letter of the Quran that’s
                learnt and recited - what an incredible blessing!
              </p>
            </div>
          </div>
        </section>

        <section className="px-4 xl:px-20 mt-10 bg-white">
          <div className="flex gap-x-10 flex-wrap sm:flex-nowrap">
            <div className="basis-full sm:basis-1/2">
              <img src={Process} alt="process to build a mosque" />
            </div>
            <div className="basis-full sm:basis-1/2 flex justify-center items-start flex-col">
              <h4 className="text-[#253B7E] text-[24px] sm:text-[45px] leading-[55px] font-brandingBold">Our Process</h4>
              <h5 className="text-[#253B7E] text-[24px] font-brandingBold">After you make your donation: </h5>

              <ul className="pl-6 list-decimal text-[16px] sm:text-[18px] text-[#78716C] leading[19px] sm:leading-6 font-brandingMedium my-4">
                <li>Our donor care team will be in touch with a welcome pack</li>
                <li>
                  You will receive a progress report a few months into the project containing progress images and an update. Usually this is to notify
                  you that the location has been selected.{' '}
                </li>
                <li>You will receive a final report upon completion with information on the construction, and media for your Masjid!</li>
              </ul>
              {/* <button onClick={() => { window.scroll({ top: 500, left: 0, behavior: 'smooth' }) }} className='bg-[#F60362] rounded-xl text-white w-[190px] py-3 text-[18px]  font-bold'>Build My Masjid</button> */}
              <CustomButton
                onClick={() => {
                  window.scroll({ top: 500, left: 0, behavior: 'smooth' });
                }}
                title="Build My Masjid"
                className="mt-6 !bg-[#F60362] w-[190px]"
              />
            </div>
          </div>
          <div className="flex gap-x-10 flex-wrap sm:flex-nowrap mt-8">
            <div className="basis-full sm:basis-1/2">
              <img src={WhatIf} alt="donate to build a mosque" />
            </div>
            <div className="basis-full sm:basis-1/2 flex justify-center items-start flex-col">
              <h5 className="text-[#253B7E] text-[24px] sm:text-[45px] leading-[55px] font-brandingBold">“What if I can't?”</h5>

              <div className="text-[16px] sm:text-[18px] text-[#78716C] leading[19px] sm:leading-6 font-brandingMedium my-4">
                We don’t just offer full project solutions. We allow for contributions to parts of a Masjid. Any amount that you contribute to our
                general Masjid fund will go towards building bigger Mosque structures. For example, our most recent completion, Masjid Mahmoud in Togo
                is one of these projects.
                <br />
                <br />
                Every amount, no matter how big or small goes a long way - especially in the eyes of Allah.
              </div>
              <CustomButton
                onClick={() => {
                  window.scroll({ top: 500, left: 0, behavior: 'smooth' });
                }}
                title="Donate Now"
                className="mt-6 !bg-[#F60362] w-[190px]"
              />
              {/* <button onClick={() => { window.scroll({ top: 500, left: 0, behavior: 'smooth' }) }}
                                className='bg-[#F60362] rounded-xl text-white w-[190px] py-3 text-[18px]  font-bold'>Donate Now</button> */}
            </div>
          </div>
        </section>

        {/* /* --------------------------------- Images --------------------------------- */}
        <section className="bg-[#F8F8F8] mt-10 sm:mt-20 py-10">
          <div className="md:container md:mx-auto flex justify-center flex-wrap  mt-12">
            <div className="w-full  flex-wrap justify-center items-center px-6 sm:px-0 relative">
              <div ref={sliderRefImages} className="keen-slider">
                {images.map((item, index) => {
                  return (
                    <div key={`carousel_images${index}`} className={`keen-slider__slide basis-full  flex justify-center rounded-xl sm:rounded-none`}>
                      <img src={item.src} alt={item.alt} className=" bg-top object-cover w-full object-top" />
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="w-full  flex justify-center">
              {loaded && instanceRefImages.current && (
                <div className="dots flex pt-3 pb-6">
                  {[...Array(instanceRefImages.current.track.details.slides.length).keys()].map(idx => {
                    return (
                      <button
                        key={idx}
                        onClick={() => {
                          instanceRefImages.current?.moveToIdx(idx);
                        }}
                        className={
                          'dot border-0 w-[10px] h-[10px] mx-[5px] rounded-full p-1 cursor-pointer' +
                          (currentSlideImages === idx ? ' active bg-[#253A7E]' : ' bg-gray-300')
                        }
                      ></button>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </section>
        {/* /* -------------------------------------------------------------------------- */}

        {/* -------------------------------------FAQS--------------------------------- */}
        <section className="px-4 xl:px-20 mt-10 bg-white">
          <h2 className="text-[#253B7E] text-center mb-8 text-[30px] sm:text-[60px] font-bold">
            Build a Masjid: Donate to build a mosque, <span className="text-[#F60362]"> FAQ</span>
          </h2>
          <div className="grid grid-cols-3 gap-x-16 gap-y-8">
            <div className="col-span-3 sm:col-span-1">
              <h1 className="text-[#253B7E] font-bold text-2xl leading-7">{masjidsFaqs[0].question}</h1>
              <p className="text-base font-brandingMedium leading-4 mt-3">{masjidsFaqs[0].answer}</p>
            </div>
            <div className="col-span-3 sm:col-span-1">
              <h1 className="text-[#253B7E] font-bold text-2xl leading-7">{masjidsFaqs[1].question}</h1>
              <p className="text-base font-brandingMedium leading-4 mt-3 line-clamp-4">{masjidsFaqs[1].answer}</p>
            </div>
            <div className="col-span-3 sm:col-span-1">
              <h1 className="text-[#253B7E] font-bold text-2xl leading-7">{masjidsFaqs[2].question}</h1>
              <p className="text-base font-brandingMedium leading-4 mt-3">{masjidsFaqs[2].answer}</p>
            </div>
          </div>
          <div className="md:container md:mx-auto flex flex-col flex-wrap items-center justify-center md:px-0 mt-6">
            {masjidsFaqs.map((item, index) => {
              return (
                index > 2 && (
                  <div className="bg-white rounded-md p-4 mt-2 w-full shadow-md" key={`faq${index}`}>
                    <Accordion key={`keyallfaq${index}`} shadow title={item.question} index={index} level={1} noBorder={true}>
                      {item.answer}
                    </Accordion>
                  </div>
                )
              );
            })}
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default MasjidsNewLandingPage;
