import { useEffect, useState } from 'react';
import services from '../../services';
import Layout from '../../components/general/layout';
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../store/user';
import Accordionn from '../../components/general/accordion';
import ZakatWidget from '../zakat/zakatWidget';
import { Loader } from '../../components/general';
import { supportFaqs } from './faqs';
import OrphanWidget from './orphanWidget';
import FundraiserProductBox from '../../components/general/fundraiserProductBox';
import { Helmet } from 'react-helmet';
import SeoData from '../../utils/seo.json';

const OrphanSupportLandingPage = () => {
    /* ----------------------------- Start variables ---------------------------- */
    const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    const [futureProducts, setFutureProducts] = useState();
    const [selectedCurrency, setSelectedCurrency] = useState();
    const selectedcountryCode = useSelector(selectSelectedCountryCode);
    const seo = SeoData[selectedcountryCode];
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    const getProductsByName = async (name) => {
        let tempFutureProducts = [];
        try {
            const response = await services.categoryProductsByName(name);
            response.data.products.map(item => {
                item.raised = 0;
                if (Number(item.status) === 1) {
                    if (item.name === 'Nurturing Futures') {
                        item = Object.assign({}, item, {
                            alt: 'Sponsor an orphan: nurturing futures',
                            goal: 200000
                        });
                        tempFutureProducts.push(item)
                    }
                    if (item.name === 'HealthGuard Orphans') {
                        item = Object.assign({}, item, {
                            alt: 'sponsor an orphan: healthguard orphans',
                            goal: 200000
                        });
                        tempFutureProducts.push(item)
                    }
                    if (item.name === 'Orphan Centre Lebanon') {
                        item = Object.assign({}, item, {
                            alt: 'sponrsor an orphan: orphan centre lebanon',
                            goal: 500000
                        });
                        tempFutureProducts.push(item)
                    }
                }
                return item;
            });
            getOrphanProductsReports(tempFutureProducts);
        } catch (error) {
            console.error('error========', error);
        }
    };
    const getOrphanProductsReports = async (tempFutureProducts) => {
        try {
            const response = await services.orphanProductTransactions();
            response.data.length && response.data.map((item, i) => {
                tempFutureProducts.map(future_item => {
                    if (future_item.name === item.product_name) {
                        future_item.raised = item.total_sales
                    }
                    return future_item
                })
                return null;
            })
            // let tempFutureProducts = futureProducts;
            // Object.keys(response.data.data).length && Object.keys(response.data.data).map((keyName, i) => {
            //     tempFutureProducts.map(future_item => {
            //         if (future_item.name === keyName) {
            //             future_item.raised = response.data.data[keyName]
            //         }
            //         return future_item
            //     })
            //     console.log('response get Orphan Products Reports=', i, response.data.data[keyName]);
            //     return null;
            // })
            setFutureProducts(tempFutureProducts)
            console.log('futur products===', tempFutureProducts);
        } catch (error) {
            console.error('error========', error);
        }
    };
    /* -------------------------------------------------------------------------- */
    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD')
    }, [selectedCurrencyStore])
    useEffect(() => {
        window.scroll(0, 0);
        getProductsByName('support an orphan');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    /* -------------------------------------------------------------------------- */
    //init

    return (
        <Layout className='!bg-white'>
            {seo &&
                <Helmet>
                    <title>{`${seo['orphans']['supportAnOrphans']['title']}`}</title>
                    <meta name="title" content={`${seo['orphans']['supportAnOrphans']['title']}`} />
                    <meta name="description" content={`${seo['orphans']['supportAnOrphans']['description']}`} />
                </Helmet>
            }
            <div className="w-full flex flex-wrap bg-[url('../public/images/landings/orphan/orphans-support.webp')] md:bg-[url('../public/images/landings/orphan/orphans-support.webp')] bg-right sm:bg-center bg-cover md:py-8 max-[380px]:min-h-[135px] min-[381px]:min-h-[165px] min-[501px]:min-h-[205px] sm:min-h-[260px] lg:min-h-[400px] xl:min-h-[500px] 2xl:min-h-[600px] px-4 md:px-0">
                <div className="flex w-full justify-between items-center md:container md:mx-auto md:px-4">
                    <div className="hidden lg:flex">
                        <ZakatWidget hasSuggestPrice defaultProduct={{
                            creator: "MKD-GEN-MOR-2023-01-119",
                            label: "Orphans Around The World",
                            value: 105
                        }} />
                    </div>
                </div>
            </div>
            {/* /* ------------------------------- Widget on Mobile ---------------------------- */}
            <div className='w-full bg-[#F60362] p-4 flex justify-center items-center lg:hidden'>
                <ZakatWidget hasSuggestPrice defaultProduct={{
                    creator: "MKD-GEN-MOR-2023-01-119",
                    label: "Orphans Around The World",
                    value: 105
                }} />
            </div>
            {/* /* -------------------------------------------------------------------------- */}

            {/* /* -------------------------- Section One - Support ------------------------- */}
            <div className="w-full flex flex-wrap justify-center md:container md:mx-auto p-4 md:p-8 mt-6 ">
                <h1 className="w-full text-2xl md:text-5xl text-[#253B7E] mb-4 text-center font-medium">
                    Support an Orphan
                </h1>
                <p className='w-full text-center text-lg md:text-xl mt-4 mb-6 px-2 md:px-6 text-[#78716C]'>
                    Your donation will touch the lives of more than 6000 orphaned children across Yemen, Palestine, Bangladesh, Lebanon, Jordan, and Togo.
                    With your support, we can provide comprehensive care, encompassing spiritual guidance, nutritious meals, top-tier education, and essential healthcare.
                </p>
            </div>
            {/* /* -------------------------------------------------------------------------- */}

            {/* /* ------------------------------- Widget ----------------------------------- */}
            <div className='w-full lg:container lg:mx-auto px-4 lg:px-0 py-4 flex justify-center items-center my-4 md:my-12'>
                <OrphanWidget />
            </div>
            {/* /* -------------------------------------------------------------------------- */}

            {/* /* ------------------- Section Two - Products ------------------------------- */}
            <div className="w-full flex flex-wrap justify-center md:container md:mx-auto px-4 md:px-0 py-8 md:py-16">
                <div className="basis-full flex flex-col justify-center mb-8 md:mb-16">
                    <h1 className="text-2xl md:text-4xl text-[#253B7E] text-center font-medium mb-2 md:mb-4">
                        Build a Brighter Future for Them
                    </h1>
                    <p className='w-full text-center mb-4 md:mb-8 text-lg md:text-xl'>
                        Help us raise funds to provide orphans with essential needs, education, and a chance at a better future. Every contribution counts!
                    </p>
                </div>
                {futureProducts
                    ? <div className="flex flex-wrap justify-center">{futureProducts.map((item, index) => {
                        return <div key={`fundrising${index}`} className="basis-full min-[400px]:basis-1/2 md:basis-1/3 xl:basis-1/4 p-2 overflow-hidden">
                            <FundraiserProductBox goal={item.goal} subTitle='Orphan Education' product={item} currency={selectedCurrency ? selectedCurrency : 'AUD'} />
                        </div>
                    })}</div>
                    : <Loader />
                }
            </div>
            {/* /* -------------------------------------------------------------------------- */}

            {/* /* --------------------------- Section Three - Youtube ---------------------- */}
            <div className="md:container md:mx-auto px-4 md:px-0 mt-5">
                <div className="grid grid-cols-12 px-3- md:px-4-">
                    <div className="col-start-1 col-span-12 rounded-md">
                        {<div className="col-span-12 rounded-xl mt-6 bg-white p-2 lg:p-12 text-center shadow-lg">
                            <div className="col-span-12 h-[200px] sm:h-[300px] md:h-[400px] lg:h-[600px]">
                                <iframe className='video'
                                    width={'100%'}
                                    height={'100%'}
                                    title={'youtube'}
                                    sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
                                    src={`https://youtube.com/embed/0FO3OfWACKY?autoplay=0`}
                                >
                                </iframe>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
            {/* /* -------------------------------------------------------------------------- */}

            {/* /* ------------------------ Section Four - Help us -------------------------- */}
            <div className="w-full flex flex-wrap md:container md:mx-auto px-4 md:px-0 pt-4 md:pt-8 mb-8 md:mb-0 mt-8 md:mt-16">
                <div className="basis-full md:basis-6/12 flex justify-center items-center">
                    <img src={'/images/landings/orphan/help-us.jpg'} alt="sponsor orphans around the world" className='w-full- max-h-[490px] rounded-lg' />
                </div>
                <div className="basis-full md:basis-5/12 flex flex-col justify-around items-start md:pl-8">
                    <h1 className="text-xl font-medium md:text-4xl text-[#253B7E] mb-2 md:mb-4 mt-4 md:mt-0">
                        Help us reach every orphan around the world
                    </h1>
                    <p className='text-lg md:text-xl text-[#78716C]'>
                        Every single day, 5,700 children become orphaned. We have to help them.
                        <br />
                        <br />
                        One of the most important societal issues that requires immediate attention is the general support of orphans.
                        Orphans are among society's most vulnerable groups, and they require a nurturing environment in order to develop and flourish.
                        We are an international nonprofit organisation called MATW Project and we work tirelessly to support orphans all over the world.
                    </p>
                    <button className='text-white flex items-center justify-center mt-4 text-sm md:text-lg transition-all duration-200 bg-[#f60362] hover:bg-[#00a3da] h-[48px] px-4 sm:px-6 rounded-md' onClick={() => window.scroll(0, 0)}>
                        DONATE NOW
                    </button>
                </div>
            </div>
            {/* /* -------------------------------------------------------------------------- */}

            {/* /* ------------------------ Section Five - Support An Orphan ---------------- */}
            <div className="w-full flex flex-wrap justify-end md:container mt-8 md:mt-16 md:mx-auto px-4 md:px-0 py-4 md:py-8">
                <div className="basis-full md:basis-5/12 flex flex-col justify-around items-start md:pr-8 order-2 md:order-1">
                    <h1 className="text-xl font-medium md:text-4xl text-[#253B7E] mb-4 md:mb-6 mt-4 md:mt-0">
                        Support An Orphan for 1 Year, 2 months or 1 month
                    </h1>
                    <p className='text-lg md:text-xl text-[#78716C]'>
                        You can support an orphan for a year, three months, or one month through one of the several orphan support programs offered by MATW Project.
                        You can decide to help an orphan in Yemen, Togo, Bangladesh, Lebanon, or Jordan.
                        The support programs cover the cost of food, shelter, education, and healthcare for the orphan.
                    </p>
                    <button className='text-white flex items-center justify-center mt-4 text-sm md:text-lg transition-all duration-200 bg-[#f60362] hover:bg-[#00a3da] h-[48px] px-4 sm:px-6 rounded-md' onClick={() => window.scroll(0, 0)}>
                        DONATE NOW
                    </button>
                </div>
                <div className="basis-full md:basis-1/2 flex justify-center items-center order-1 md:order-2">
                    <img src={'/images/landings/orphan/support-an-orphan.jpg'} alt="" className='w-full- max-h-[390px] rounded-lg' />
                </div>
            </div>
            {/* /* -------------------------------------------------------------------------- */}

            {/* /* ------------------ Section Six - Fostering ------------------------------- */}
            <div className="w-full flex flex-wrap justify-center md:container px-4- md:px-0 md:mx-auto py-4 md:py-8">
                <div className="w-full flex flex-wrap md:container md:mx-auto px-4 md:px-0 pt-4 md:pt-8 mb-8 md:mb-0">
                    <div className="basis-full md:basis-1/2 flex justify-center items-center">
                        <img src={'/images/landings/orphan/fostering.jpg'} alt="sponsor an orphan, fostering muslim children" className='w-full- max-h-[420px] rounded-lg' />
                    </div>
                    <div className="basis-full md:basis-5/12 flex flex-col justify-around items-start md:pl-8">
                        <h1 className="text-xl font-medium md:text-4xl text-[#253B7E] mb-2 md:mb-6 mt-4 md:mt-0">
                            Fostering Brighter Futures
                        </h1>
                        <p className='text-lg md:text-xl text-[#78716C]'>
                            Become a part of their incredible journey, where every penny you give makes a lasting difference in the lives of these remarkable children.
                            <br />
                            <br />
                            Embrace the opportunity to nurture the growth and well-being of our precious orphan centers.
                            Your heartfelt contribution carries immeasurable significance,
                            enabling us to provide a warm and loving sanctuary where orphaned children can not only survive but truly flourish.
                        </p>
                        <button className='text-white flex items-center justify-center mt-4 text-sm md:text-lg transition-all duration-200 bg-[#f60362] hover:bg-[#00a3da] h-[48px] px-4 sm:px-6 rounded-md' onClick={() => window.scroll(0, 0)}>
                            DONATE NOW
                        </button>
                    </div>
                </div>
            </div>
            {/* /* -------------------------------------------------------------------------- */}


            {/* /* --------------------------- Section Seven - FAQ -------------------------- */}
            <div className='w-full h-auto flex flex-col justify-center items-center bg-[#E2EFF3]- py-16'>
                <h2 className="text-2xl md:text-4xl font-medium text-center mt-8 mb-4 text-[#253B7E]">Orphan Support FAQs</h2>
                <div className="md:container md:mx-auto flex flex-col flex-wrap items-center justify-center px-8 md:px-0 mt-6">
                    {supportFaqs.map((item, index) => {
                        return (
                            <div className='bg-white rounded-md p-4 mt-2 w-full shadow-md' key={`faq${index}`}>
                                <Accordionn key={`keyallfaq${index}`} shadow title={item.question} index={index} level={1} noBorder={true}>
                                    {item.answer}
                                </Accordionn>
                            </div>
                        );
                    })}
                </div>
            </div>
            {/* /* -------------------------------------------------------------------------- */}

        </Layout>
    );
}

export default OrphanSupportLandingPage;
