import React, { useEffect, useState } from 'react'
import Layout from '../../components/general/layout'
import ProductBox from '../../components/general/productBox'
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../store/user'
import { useSelector } from 'react-redux'
import { zakatFaqs } from './faq'
import { Link, useNavigate } from 'react-router-dom'
import { getProducts } from '../../store/products'
import Accordion from '../../components/general/accordion'
import { useKeenSlider } from 'keen-slider/react'
import { OurPromiseSection } from '../../components/general'
import ZakatAlFitrWidget from './zakatAlfitrWidget'
import WhyShould from '../../images/zakat/whyShould.jpg';
import Difference from '../../images/zakat/differenceZakat.jpg';
import zakat1 from '../../images/zakat/zakat-uat-1.jpg'
import zakat2 from '../../images/zakat/zakat-uat-2.jpg'
import zakat3 from '../../images/zakat/zakat-uat-3.jpg'
import zakat4 from '../../images/zakat/zakat-uat-4.jpg'
import banner from '../../images/zakat/zakat-hero-banner.jpg'
import bannerMobile from '../../images/zakat/zakat-hero-banner-mobile.jpg'
import SeoData from '../../utils/seo.json';
import { Helmet } from 'react-helmet'
import YoutubeVideoHero from '../../components/general/youtubeVideoHero'
import { generateLinkWithLang } from '../../utils/linkGenerator'
import { useTranslation } from 'react-i18next'


const images = [
    zakat1,
    zakat2,
    zakat3,
    zakat4,

];
const calculator = {
    isStaticProduct: true,
    category_id: 0,
    id: 0,
    image_link: '/images/zakat/zakat-cal.jpg',
    name: 'Zakat Calculator',
    description: 'Use our calculator to find out what you need to give in zakat.',
    creator: ''
};
const ZakatOnGold = () => {
    /* ----------------------------- Start variables ---------------------------- */
    const [selectedCurrency, setSelectedCurrency] = useState();
    const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    const [ramadanProducts, setRamadanProducts] = useState([0, 0]);
    const [isLoaded, setIsLoaded] = useState(false);
    const products = useSelector((state) => getProducts(state, 'Zakat', 'give zakat'));
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [currentSlideImages, setCurrentSlideImages] = useState(0);
    const [sliderRefImages, instanceRefImages] = useKeenSlider({
        mode: "free-snap",
        loop: true,
        slideChanged(slider) {
            setCurrentSlideImages(slider.track.details.rel)
        },
        created() {
            setLoaded(true)
        },
        slides: {
            origin: "auto",
            perView: 1,
            spacing: 8,

        },
    },
        [
            slider => {
                let timeout;
                let mouseOver = false;
                function clearNextTimeout() {
                    clearTimeout(timeout);
                }
                function nextTimeout() {
                    clearTimeout(timeout);
                    if (mouseOver) return;
                    timeout = setTimeout(() => {
                        slider.next();
                    }, 2000);
                }
                slider.on('created', () => {
                    slider.container.addEventListener('mouseover', () => {
                        mouseOver = true;
                        clearNextTimeout();
                    });
                    slider.container.addEventListener('mouseout', () => {
                        mouseOver = false;
                        nextTimeout();
                    });
                    nextTimeout();
                });
                slider.on('dragStarted', clearNextTimeout);
                slider.on('animationEnded', nextTimeout);
                slider.on('updated', nextTimeout);
            },
        ]

    )
    const selectedcountryCode = useSelector(selectSelectedCountryCode) || 'AU';
    const seo = SeoData[selectedcountryCode];
    const { i18n } = useTranslation();
    const lang = i18n.language;
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    const getAllProducts = () => {
        setIsLoaded(true);
        let tempProductsList = [];
        tempProductsList[0] = calculator;
        let p = products.subCategory.products;

        p.forEach(item => {
            if (item.creator === 'RM24-ZM-013') {
                tempProductsList[1] = {
                    ...item,
                    isStaticProduct: true,
                    staticLinkText: "Give Zakat Al Mal"
                };
                tempProductsList[1].image_link = '/images/zakat/zakat-al-mal.jpg';

            }
        });
        tempProductsList = tempProductsList.filter(n => n)
        setRamadanProducts(tempProductsList);
    };


    /* -------------------------------------------------------------------------- */
    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
    }, [selectedCurrencyStore]);
    useEffect(() => {

        products && Object.keys(products).length && !isLoaded && getAllProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products])

    /* -------------------------------------------------------------------------- */

    return (
        <Layout className='bg-white' hideFooter>

            {seo &&
                <Helmet>
                    <title>{`${seo['zakat']['zakatOnGold']['title']}`}</title>
                    <meta name="title" content={`${seo['zakat']['zakatOnGold']['title']}`} />
                    <meta name="description" content={`${seo['zakat']['zakatOnGold']['description']}`} />
                </Helmet>
            }
            <section className="flex justify-center" >
                <img src={banner} alt="banner" className='max-[500px]:hidden w-full' />
                <img src={bannerMobile} alt="banner" className='min-[501px]:hidden w-full' />
            </section>
            <section className='bg-[#E1EFF2]'>
                <section className='max-w-[1440px] mx-auto'>
                    <div className='text-[#093686] pt-5 leading-[45px] text-center text-[35px] font-bold'>Make a Donation</div>
                    <div className='w-full  lg:mx-auto px-4 xl:px-10 py-4 md:py-6 sm:flex justify-center items-center'>
                        {/* <CrisisWidget className={'bg-[#DFEEF2] px-6 h-auto pt-10'}
                            defaultProduct={{
                                creator: "RM24-ZM-013",
                                label: "Zakat Al Mal",
                                value: 255
                            }}
                            showZakatCalculatorBtn={true}
                        /> */}
                        <ZakatAlFitrWidget className={'bg-[#DFEEF2]  h-auto py-6'}
                            defaultProduct={{
                                creator: "RM24-ZM-013",
                                label: "Zakat Al Mal",
                                value: 255
                            }}
                            zakatCalculator={true}
                        />
                    </div>
                    {/* <div className='w-full sm:hidden lg:mx-auto px-4 xl:px-10 py-4 md:py-6 flex justify-center items-center'>
                        <ZakatWidget hasSuggestPrice defaultProduct={{
                            creator: "RM24-ZM-013",
                            label: "Zakat Al Mal",
                            value: 255
                        }} />
                    </div> */}
                </section>
            </section>
            <OurPromiseSection />
            <main className='max-w-[1440px] mx-auto'>
                <section className='xl:px-20 mb-8 sm:mb-16'>
                    <div className='relative overflow-hidden flex flex-wrap'>
                        <div className='order-2 z-10 sm:order-1 bg-[#F60362] flex flex-col items-center md:items-start justify-center w-full md:max-w-[315px] min-h-[300px]
                        md:min-h-[300px] lg:min-h-[305px] md:top-1/2 md:-translate-y-1/2
                        right-16 bg-no-repeat bg-cover bg-center md:rounded-2xl md:absolute  ' >
                            <div className='leading-[50px] pt-8 w-full text-[30px] sm:text-[50px] md:text-[30px] lg:text-[50px] px-8 text-white text-center font-gotcha'>Zakat Al Mal</div>
                            <p className='text-[18px] sm:text-[18px] font-montserratMedium leading-6 mt-3 px-10 text-white text-center'>
                                There is a reason Allah made Zakat Al Mal a pillar of Islam. An obligation for those who can afford it and a means of survival for those who need it. It can truly transform lives.<br /><br />

                            </p>
                        </div>
                        <div className='hidden sm:block absolute w-full h-full bg-[#00000085]' />
                        <YoutubeVideoHero src={`https://www.youtube.com/embed/t2uAa_13sMU?controls=0&autoplay=1&loop=1&mute=1&playlist=t2uAa_13sMU`}/>

                    </div>
                </section>
                <section className='xl:px-20'>
                    <div className='flex justify-center'>
                        <div className='text-left sm:text-center font-bold text-[#093686] text-[30px] sm:text-6xl px-4 w-full sm:w-8/12'>
                            The <span className='text-[#F60362]'>Power</span> of Zakat Al Mal
                        </div>
                    </div>
                    <div className='px-4 md:px-24 mt-2 sm:mt-10 text-[18px] font- leading-5'>
                        <div className='text-[#78716C] text-left  leading-[19px] sm:leading-[26px]  sm:text-[24px] font-montserratMedium'>
                            Zakat Al Mal can be one of the most powerful things a Muslim can give. It can transform entire communities,  lifting people out of poverty. It is a system that looks after those in need-an obligation upon us, but a right for our beneficiaries. For orphans, widows and displaced families, it is a much-needed lifeline. On the Day of Judgement, it will be a lifeline for us, inshallah.

                        </div>
                        <div className='flex justify-center mt-8 '>
                            <button onClick={() => navigate(generateLinkWithLang('/zakat-calculator', lang))} className=' bg-gradient-to-b from-[#F60362] to-[#9A033E]  text-sm sm:text-lg rounded-lg w-[455px] text-white h-[50px]'>
                                Calculate and give your Zakat Al Mal here
                            </button>
                        </div>

                        <div className="flex  flex-row justify-center flex-wrap col-span-12 mt-10 mb-16">
                            {ramadanProducts.map((item, index) => {
                                return (
                                    <div key={`makedonation${index}`} className="basis-1/2 sm:basis-2/4 md:basis-1/3 xl:basis-1/4 p-2">
                                        <ProductBox product={item} isStaticProduct={item.isStaticProduct || false}
                                            staticLinkText={item.staticLinkText}
                                            scrollTop={500} currency={selectedCurrency ? selectedCurrency : 'AUD'} />
                                    </div>
                                );
                            })}
                        </div>

                    </div>
                </section>



                <section className='xl:px-20 bg-white'>

                    <div className='mb-12'>
                        <div className='flex justify-center'>
                            {/* <div className='text-left sm:text-center font-bold text-[#093686] text-[30px] sm:text-6xl px-4 w-full sm:w-8/12'>
                                What is Zakat Al Mal (Zakat)?
                            </div> */}
                        </div>
                        <div className='px-4 md:px-28 text-[18px]  text-brandingMedium mt-8 leading-5'>
                            <p className='text-[#78716C] font-montserratMedium sm:text-[24px] leading-[20px] sm:leading-[26px]'>
                                Zakat, one of the Five Pillars of Islam, is a form of almsgiving that is considered both a religious duty and a form of worship.
                                Among the various types of wealth on which Zakat is applicable, gold holds significant importance. Here we delve into the intricacies
                                of calculating Zakat on gold, a practice that not only purifies the wealth of the giver but also aids those in need.
                            </p>
                        </div>
                    </div>

                    <div className='mb-12'>
                        <div>
                            <h1 className='text-[30px] sm:text-[50px] md:leading-[60px] font-bold text-center text-[#253B7E]'>
                                Understanding Zakat on Gold
                            </h1>
                            <p className='mt-5 sm:mt-10 text-[#78716C] text-[18px] sm:text-[24px] font-montserratMedium'>
                                Zakat mandates Muslims who meet certain criteria to donate 2.5% of their qualifying wealth and savings, including gold, annually.
                                This act of giving is not just about charity; it's a purification process for one's wealth and a means to support the less fortunate in the community.
                            </p>
                        </div>

                        <div>
                            <div className='mt-10'>
                                <h2 className='text-[#253B7E] text-[24px] sm:text-[40px] font-bold'>
                                    The Nisab Threshold
                                </h2>
                                <p className='mt-5 text-[#78716C] text-[16px] sm:text-[24px] font-montserratMedium'>
                                    The concept of Nisab is crucial in determining Zakat eligibility. Nisab is the minimum amount of wealth one must possess to be obligated to pay Zakat.
                                    For gold, the Nisab is set at 87.48 grams (or approximately 3 ounces). If your gold holdings are below this threshold, you're exempt from paying Zakat on them.
                                </p>
                           </div>
                            <div className='mt-10'>
                                <h2 className='text-[#253B7E] text-[24px] sm:text-[40px] font-bold'>
                                    Calculating Zakat on Gold
                                </h2>
                                <p className='mt-5 text-[#78716C] text-[16px] sm:text-[24px] font-montserratMedium'>
                                    To calculate Zakat on gold, one must first understand the current market value of gold. For example, if a gram of gold is valued at 32, the Zakat due per gram
                                    would be 2.5% of that value, equating to approximately 0.80. This rate applies regardless of the form in which the gold is held, be it jewelry, coins, or bullion.
                                </p>
                           </div>
                            <div className='mt-10'>
                                <h2 className='text-[#253B7E] text-[24px] sm:text-[40px] font-bold'>
                                    Practical Steps for Calculation
                                </h2>
                                <ul className='mt-5 space-y-8'>
                                    <li>
                                        <p className='text-[#78716C] text-[16px] sm:text-[20px] font-montserratMedium'>
                                            <strong className='block text-gray-700 mb-2'>Assess Your Gold</strong>
                                            Determine the total weight of the gold you own that is above the Nisab threshold.
                                        </p>
                                    </li>
                                    <li>
                                        <p className='text-[#78716C] text-[16px] sm:text-[20px] font-montserratMedium'>
                                            <strong className='block text-gray-700 mb-2'>Current Market Value:</strong>
                                            Check the current market value of gold per gram or ounce, depending on how you've measured your gold.
                                        </p>
                                    </li>
                                    <li>
                                        <p className='text-[#78716C] text-[16px] sm:text-[20px] font-montserratMedium'>
                                            <strong className='block text-gray-700 mb-2'>Calculate Zakat:</strong>
                                            Multiply the total value of your gold by 2.5% to find the amount of Zakat due.
                                        </p>
                                        <p className='mt-2 text-[#78716C] text-[16px] sm:text-[20px] font-montserratMedium'>
                                            For example, if you own 100 grams of gold, and the current market price is 32 per gram, the total value of your gold is 3,200. Your Zakat payment would be 2.5% of 3,200, which amounts to 80.
                                        </p>
                                    </li>
                                </ul>
                           </div>
                        </div>

                        <div className='mt-10'>
                            <h3 className='text-[#253B7E] text-[20px] sm:text-[36px] font-bold'>
                                The Impact of Your Zakat
                            </h3>
                            <p className='mt-5 text-[#78716C] text-[16px] sm:text-[24px] font-montserratMedium'>
                                Paying Zakat on gold is not just a fulfillment of a religious duty; it's a means to make a tangible difference in the lives of those in need.
                                MATW Project,ensure that 100% of your Zakat contribution goes directly to
                                those who need it most, ensuring that your act of giving brings about positive change in the world.
                            </p>
                            <p className='mt-5 text-[#78716C] text-[16px] sm:text-[24px] font-montserratMedium'>
                                Zakat on gold serves as a reminder of the wisdom encapsulated in the Hadith, "Charity never decreases wealth." By purifying our wealth through Zakat,
                                we not only comply with a fundamental pillar of Islam but also contribute to a cycle of generosity and care within the community. Calculating and paying Zakat on gold
                                is a meaningful practice that upholds the spirit of giving and solidarity in Islam.
                            </p>
                        </div>
                    </div>

                </section>
                <section className="h-[350px] sm:h-[450px] md:h-[550px] flex justify-center  bg-cover bg-center bg-no-repeat bg-[url('../src/images/zakat/banner-mobile.jpg')] sm:bg-[url('../src/images/zakat/banner.jpg')]" >
                    <div className='mx-auto w-full relative overflow-hidden'>
                        {/* <img src={Banner} className='h-[550px] hidden md:block'/> */}
                        <div className='absolute  w-full left-6 top-1/2 -translate-y-1/2' >
                            <div className='md:container mx-auto xl:px-20 text-white'>
                                <h1 className='text-white text-left md:text-left text-[30px] leading-[30px] sm:text-[60px] md:text-[60px] sm:leading-[60px] uppercase font-montserratMedium'>
                                    So how much <span className='font-montserratBold text-white'> Zakat Al Mal</span> <br />
                                    do you have <span className='font-montserratBold '> to pay?</span><br />

                                </h1>
                                <p className='text-[16px] w-8/12 sm:w-full sm:text-[24px] leading-[18px] sm:leading-6 font-montserratMedium mt-2'>
                                    Zakat Al Mal is worked out to 2.5% of a person’s savings. This is calculated at the
                                    same time every year. It is only payable if a person has savings
                                    which exceed the Nisab value. This is equal to 85 grams of gold or 612 grams of silver.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='xl:px-20 bg-white  py-8 sm:mt-8'>
                    <div >
                        <div className='flex justify-center'>
                            <div className='text-left sm:text-center font-bold text-[#093686] text-[30px] sm:text-6xl px-4 w-full sm:w-10/12'>
                                What do MATW do with Your Zakat?
                            </div>
                        </div>
                        <div className='px-4 md:px-28 text-[18px] mt-8 '>
                            <p className='text-[#78716C] font-montserratMedium leading-[20px] sm:leading-[28px] sm:text-[24px]'>
                                The poor and needy make up the majority of our Zakat Al Mal distribution. These are our t
                                op priorities when it comes to Zakat.  <br /><br />
                                When you pay your Zakat through MATW, you are
                                sending your charitable offering directly to people in abject poverty and dire need,
                                orphans, widows, displaced families refugees and victims of war and disaster come
                                first on our list.
                                <br /><br />
                                MATW has a 100% Zakat donation policy, where we ensure every penny of your donation goes towards
                                our work in the field.
                            </p>
                        </div>

                    </div>




                </section>


                <section className='px-4 xl:px-20 mt-10 bg-white'>

                    <div className='flex gap-x-10 flex-wrap sm:flex-nowrap'>
                        <div className="basis-full sm:basis-1/2"><img src={WhyShould} alt="WhyShould" /></div>
                        <div className="basis-full sm:basis-1/2 flex justify-center items-start flex-col">
                            <h1 className='text-[#253B7E] text-[30px] sm:text-[45px] leading-[30px] my-4 sm:leading-[54px] font-montserratBold'>
                                Why should you pay your Zakat Al Mal?
                            </h1>

                            <p className='text-[#78716C] leading-[19px] sm:leading-6 text-[18px] font-montserratMedium'>
                                By paying your Zakat Al Mal, you will be fulfilling your religious obligation. This is the same as praying your Salah or believing in Allah. It is a commandment upon us and one of the five pillars of Islam. When you give your Zakat Al Mal, Allah purifies your wealth, cleanses your sins, blesses you with His bounty on this earth and the hereafter. Zakat Al Mal is a form of worship to Allah. On the Day of Judgement, when you are questioned about your Zakat Al Mal, the answer will be a resounding yes. Every person you helped with your Zakat Al Mal will testify for you.
                                <br /><br />
                                On this earth, the Zakat Al Mal you pay is not yours, but a right of the beneficiary who receives it. It will go towards transforming their lives, lifting them out of poverty, helping them get closer to Allah, reconciling distressed hearts, all for the sake of pleasing Allah. Zakat can actually transform entire communities.
                            </p>


                        </div>
                    </div>
                    <div className='flex gap-x-10 flex-wrap sm:flex-nowrap mt-8'>
                        <div className="basis-full sm:basis-1/2"><img src={Difference} alt="Difference" /></div>
                        <div className="basis-full sm:basis-1/2 flex justify-center items-start flex-col">
                            <h1 className='text-[#253B7E] text-[24px] sm:text-[45px] leading-[45px] font-montserratBold'>
                                What is the difference between Zakat Al Fitr and Zakat Al Mal?
                            </h1>


                            <div className='text-[16px] sm:text-[18px] text-[#78716C] leading[19px] sm:leading-6 font-montserratMedium my-4'>
                                These are the two types of Zakat, which can be confusing to some.
                                <br />
                                <br />
                                <span className='text-[16px] sm:text-[18px] text-[#253B7E] font-montserratBold'>
                                    Here are the two types:
                                </span>
                            </div>
                            <ul className='pl-6 list-decimal text-[16px] sm:text-[18px] text-[#78716C] leading[19px] sm:leading-6 font-montserratMedium '>
                                <li>Zakat Al-Fitr is something everyone pays at the end of Ramadan to help the poor and needy celebrate the festivities of Eid. Traditionally, this would have been two handfuls of dates or dried food such as rice. </li>
                                <li>Whereas Zakat Al Mal is a pillar of faith for Muslims and is an obligation for those who can afford to give it. It’s impact is greater and more transformative.</li>

                            </ul>
                        </div>
                    </div>


                </section>
                {/* /* --------------------------------- Images --------------------------------- */}
                <section className='bg-[#F8F8F8] mt-10 sm:mt-20 py-10'>
                    <div className="md:container md:mx-auto flex justify-center flex-wrap  mt-12">

                        <div className="w-full  flex-wrap justify-center items-center px-6 sm:px-0 relative">
                            <div ref={sliderRefImages} className="keen-slider">
                                {images.map((item, index) => {

                                    return <div key={`carousel_images${index}`} className={`keen-slider__slide basis-full  flex justify-center rounded-xl sm:rounded-none`}>
                                        {/* <div className={`bg-[url('../src/images/zakat/zakat-alfitr${index + 1}.jpg')] bg-center rounded bg-cover h-[300px] w-full`} /> */}
                                        <img src={item} alt={`carousel_images${index}`} className=' bg-top object-cover w-full object-top' />
                                    </div>
                                })}
                            </div>

                        </div>
                        <div className="w-full  flex justify-center">
                            {loaded && instanceRefImages.current && (
                                <div className="dots flex pt-3 pb-6">
                                    {[
                                        ...Array(instanceRefImages.current.track.details.slides.length).keys(),
                                    ].map((idx) => {
                                        return (
                                            <button
                                                key={idx}
                                                onClick={() => {
                                                    instanceRefImages.current?.moveToIdx(idx)
                                                }}
                                                className={"dot border-0 w-[10px] h-[10px] mx-[5px] rounded-full p-1 cursor-pointer" + (currentSlideImages === idx ? " active bg-[#253A7E]" : " bg-gray-300")}
                                            ></button>
                                        )
                                    })}
                                </div>
                            )}
                        </div>
                    </div>
                </section>
                {/* /* -------------------------------------------------------------------------- */}


                {/* -------------------------------------FAQS--------------------------------- */}
                <section className='px-4 xl:px-20 mt-10 bg-white'>
                    <h1 className='text-[#253B7E] text-left  sm:text-center mb-8 text-[30px] sm:text-[60px] font-bold'>
                        Zakat donations <span className='text-[#F60362]'> FAQ’s</span>
                    </h1>
                    <div className='grid grid-cols-3 gap-x-16 gap-y-8'>
                        <div className='col-span-3 sm:col-span-1'>
                            <h1 className='text-[#253B7E] font-bold text-2xl leading-7'>{zakatFaqs[0].question}</h1>
                            <p className='text-base font-montserratMedium leading-4 mt-3'>
                                {zakatFaqs[0].answer}
                            </p>
                        </div>
                        <div className='col-span-3 sm:col-span-1'>
                            <h1 className='text-[#253B7E] font-bold text-2xl leading-7'>{zakatFaqs[1].question}</h1>
                            <p className='text-base font-montserratMedium leading-4 mt-3'>
                                {zakatFaqs[1].answer} <Link to="/zakat-calculator" className='text-[#00a3da]'>2024 Zakat Calculator</Link>.
                            </p>
                        </div>
                        <div className='col-span-3 sm:col-span-1'>
                            <h1 className='text-[#253B7E] font-bold text-2xl leading-7'>{zakatFaqs[2].question}</h1>
                            <p className='text-base font-montserratMedium leading-4 mt-3'>
                                {zakatFaqs[2].answer}
                            </p>
                        </div>
                    </div>
                    <div className="md:container md:mx-auto flex flex-col flex-wrap items-center justify-center md:px-0 mt-6">
                        {zakatFaqs.map((item, index) => {
                            return index > 2 && (
                                <div className='bg-white rounded-md p-4 mt-2 w-full shadow-md' key={`faq${index}`}>
                                    <Accordion key={`keyallfaq${index}`} shadow title={item.question} index={index} level={1} noBorder={true}>
                                        {item.answer}
                                    </Accordion>
                                </div>
                            );
                        })}
                    </div>
                </section>
            </main>
        </Layout>
    )
}

export default ZakatOnGold;
