import { useSelector } from 'react-redux';
import { selectSelectedCountryCode } from '../../store/user';
import Layout from '../../components/general/layout';
import { Helmet } from 'react-helmet';
import SeoData from '../../utils/seo.json';
import { OurPromiseSection } from '../../components/general';
import ZakatCalculatorWidget from '../../components/widgets/zakatCalculatorWidget';
// import banner from '../../images/landings/zakat-calculator/zakat-calculator-hero-banner.jpg';
// import bannerMobile from '../../images/landings/zakat-calculator/zakat-calculator-hero-banner-mobile.jpg';

const ZakatCalculatorUATLandingPage = () => {
  /* ----------------------------- Start variables ---------------------------- */

  const selectedcountryCode = useSelector(selectSelectedCountryCode);
  const seo = SeoData[selectedcountryCode];
  /* -------------------------------------------------------------------------- */

  return (
    <Layout className="bg-white" hideFooter>
      {seo && (
        <Helmet>
          <title>{`${seo['zakat']['zakatCalculator']['title']}`}</title>
          <meta name="title" content={`${seo['zakat']['zakatCalculator']['title']}`} />
          <meta name="description" content={`${seo['zakat']['zakatCalculator']['description']}`} />
          <script type="application/ld+json">
            {JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'HowTo',
              name: 'How to Calculate Zakat',
              description: 'This guide outlines the steps to calculate Zakat, ensuring you fulfill your Islamic financial obligations.',
              step: [
                {
                  '@type': 'HowToStep',
                  name: 'Determine Nisab',
                  text: 'Calculate the current value of Nisab in your local currency to determine if you are eligible to pay Zakat.',
                },
                {
                  '@type': 'HowToStep',
                  name: 'Calculate Assets',
                  text: 'Total your assets that are subject to Zakat, including cash, gold, silver, and business commodities.',
                },
                {
                  '@type': 'HowToStep',
                  name: 'Deduct Liabilities',
                  text: 'Subtract any debts or liabilities from your total assets to find your net assets.',
                },
                {
                  '@type': 'HowToStep',
                  name: 'Apply Zakat Rate',
                  text: 'If your net assets exceed the Nisab, apply the Zakat rate of 2.5% to calculate the amount of Zakat due.',
                },
              ],
              estimatedCost: {
                '@type': 'MonetaryAmount',
                value: '0',
                description: 'Free',
              },
              tool: {
                '@type': 'HowToTool',
                name: 'Zakat Calculator 2024',
                url: 'https://matwproject.org/zakat-calculator',
              },
            })}
          </script>
        </Helmet>
      )}

      {/* /* --------------------------------- Calculator ---------------------------------  */}
      <div className="w-full py-6 md:py-12 px-4 md:px-0 bg-[url('../src/images/ali-banat/pattern.png')]">
        <ZakatCalculatorWidget />
      </div>
      <OurPromiseSection alt="Zakat Calculator 2024: Gold zakat calculator" />
      <div className="w-full bg-white p-4">
        <div className="w-full md:container md:mx-auto bg-white">
          <section className="xl:px-10 mt-8 md:mt-16">
            <h2 className="w-full text-center text-[25px] md:text-[40px] text-[#253b7e] font-medium mb-4 md:mb-8">
              Calculate zakat for <span className="text-[#F60362]">2024</span> with our easy to use zakat calculator
            </h2>
            <h2 className=" text-[#14A2DC] text-xl md:text-[25px] text-center mb-3 md:mb-6 font-medium">
              How to calculate Zakat: Use our Zakat Calculator to see how much you owe this year.
            </h2>
            <p className=" text-[#78716C]">
              To use our Zakat calculator, enter all the assets that have been in your possession over the course of a lunar year into the Zakat
              calculator. This will then give you the total amount of the Zakat you owe.
              <br />
              <br />
              Breaking your assets down into different categories makes the Zakat calculation process easier. The process presented on the calculator
              is broken up by Zakatable assets which include gold, silver, cash, savings, business assets etc. and deductible liabilities money you
              owe, other outgoings due so you can calculate the Zakat you owe easily. The amount of Zakat that appears will be what you need to pay.
              <br />
              <br />
              Zakat, one of the Five Pillars of Islam, is a form of almsgiving treated in Islam as a religious obligation or tax, which, by Quranic
              ranking, is next after prayer in importance. As a religious obligation, it is deeply embedded in the Islamic faith, and its calculation
              is an essential practice for Muslims worldwide. With the advent of technology, the process has been simplified through the use of a
              Zakat Calculator, making it easier for Muslims to fulfill this crucial duty.
            </p>
            <h2 className="w-full text-[18px] md:text-[25px] text-[#253b7e] font-medium my-4">Learn how to calculate zakat, we make it simple</h2>
            <p className=" text-[#78716C]">
              Calculating Zakat involves several steps and considerations to ensure one fulfills this religious obligation accurately. The process
              starts by evaluating all assets subject to Zakat, which include cash, gold, silver, business inventory, and other income-generating
              assets. The Zakat Calculator 2024 simplifies this task by providing a user-friendly interface where individuals can input their asset
              values, and the calculator will automatically compute the Zakat due.
              <br />
              <br />
              To calculate Zakat, one must first determine the Nisab value, which is the minimum amount of wealth a Muslim must possess before they
              are liable to pay Zakat. This value is typically based on the current price of gold or silver. Once the Nisab is determined, Muslims who
              own wealth above this threshold must pay 2.5% of their total qualifying assets as Zakat.
            </p>
            <h2 className="w-full text-[18px] md:text-[25px] text-[#253b7e] font-medium my-4">
              Zakat calculator for gold: Use our gold zakat calculator to see what you owe
            </h2>
            <p className=" text-[#78716C]">
              Gold is a significant asset in Zakat calculation due to its intrinsic value and common possession among Muslims. The Zakat Calculator on
              Gold simplifies the computation by considering the current gold prices and the weight of the gold owned. Whether you have gold in the
              form of jewelry, coins, or bars, the Gold Zakat Calculator allows you to input the total weight in grams or tolas and calculates the
              Zakat due based on the current gold Nisab value.
            </p>
            <h2 className="w-full text-[18px] md:text-[25px] text-[#253b7e] font-medium my-4">
              Zakat calculator for gold: Use our gold zakat calculator to see what you owe
            </h2>
            <p className=" text-[#78716C]">
              Zakat on cash involves assessing all liquid assets, including money in bank accounts, savings, and even cash at hand. The UK Zakat
              Calculator provides an easy-to-use platform for UK residents, ensuring they can calculate their Zakat on cash accurately, taking into
              account any region-specific considerations.
              <br />
              <br />
              To calculate Zakat on cash, one must sum up all liquid assets and apply the 2.5% rate. This includes checking and savings account
              balances, cash at home, and any other liquid assets. It is crucial to ensure that the total amount meets or exceeds the Nisab threshold
              for Zakat to be obligatory.
            </p>
          </section>
        </div>
      </div>
    </Layout>
  );
};

export default ZakatCalculatorUATLandingPage;
