import React, { useState, useEffect } from 'react';
import { useRef } from 'react';
import Layout from '../../components/general/layout';
import MaxWidthContainer from '../../components/general/maxWidthContainer';
import CrisisWidget from '../crisis-and-emergencies/components/crisisWidget';
import { useSelector } from 'react-redux';
import SeoData from '../../utils/seo.json';
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../store/user';

import ChildrenOfGazaProducts from '../childrenOfGaza/components/ChildrenOfGazaProducts';
import useWindowSize from '../../hooks/general/useWindowSize';
import { Helmet } from 'react-helmet';

const PalastineMedicalRelief = ({ lang = 'en' }) => {
  const bannerDesktop = 'https://cdn.matwproject.org/images/banners/banner-relief-image.webp';
  const bannerMobile = 'https://cdn.matwproject.org/images/banners/banner-relief-mobile.webp';

  const productRef = useRef(null);

  const [isLoaded, setIsLoaded] = useState(false);
  const { width } = useWindowSize();
  const isMobile = width <= 768;

  const { allProducts } = useSelector(state => state.products);
  const [products, setProducts] = useState([]);
  const selectedcountryCode = useSelector(selectSelectedCountryCode);
  const selectedCurrency = useSelector(selectSelectedCurrency);
  const seo = SeoData[selectedcountryCode];

  const getAllProducts = async () => {
    try {
      let products = [];
      allProducts.forEach(item => {
        item.sub_categories.forEach(inner_item => {
          inner_item.products.forEach(inner_item_product => {
            // Clone the product object to make it extensible
            let tempProduct = { ...inner_item_product };

            // Handling specific product and assigning default price
            if (tempProduct.creator === 'EMR-DIS-PSE-2023-01-01') {
              const price = {
                usd: 44,
                aud: 64,
                gbp: 35,
                eur: 41,
              };
              // Add defaultPrice property based on selected currency
              tempProduct.defaultPrice = price[selectedCurrency.toLowerCase()];
              products.push(tempProduct);
            }
            if (['EMR-DIS-MDPL-2023-01-102', 'EMR-DIS-BFPL-2023-01-201'].includes(tempProduct.creator)) {
              products.push(tempProduct);
            }
          });
        });
      });
      setProducts(products);
    } catch (error) {
      console.log('error===========', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await getAllProducts();
    };
    fetchData();
  });

  useEffect(() => {
    if (products.length > 0) {
      setIsLoaded(true);
    }
  }, [products]);
  const handleScrollToProducts = () => {
    if (productRef.current) {
      const topPos = productRef.current.getBoundingClientRect().top + window.scrollY - 100; // 100 pixels offset, adjust as needed
      window.scrollTo({
        top: topPos,
        behavior: 'smooth',
      });
    }
  };
  const handleScrollToButton = () => {
    if (productRef.current) {
      const topPos = productRef.current.getBoundingClientRect().top + window.scrollY - 100; // 100 pixels offset, adjust as needed
      window.scrollTo({
        top: topPos,
        behavior: 'smooth',
      });
    }
  };
  return (
    <Layout className={`${!isMobile && 'bg-[#fff]'}`}>
      {seo && (
        <Helmet>
          <title>{`${seo['medical-aid-for-palestinians']['title']}`}</title>
          <meta name="title" content={`${seo['medical-aid-for-palestinians']['title']}`} />
          <meta name="description" content={`${seo['medical-aid-for-palestinians']['description']}`} />
        </Helmet>
      )}
      {/* ---------------- BANNER IMAGE SECTION START ---------------- */}
      <section className="flex justify-center">
        <img
          src={bannerDesktop}
          alt={'Save the children of Gaza'}
          className="max-[500px]:hidden w-full cursor-pointer"
          onClick={handleScrollToProducts}
        />
        <img
          src={bannerMobile}
          alt={'Save the children of Gaza'}
          className="min-[501px]:hidden w-full cursor-pointer"
          onClick={handleScrollToProducts}
        />
      </section>
      {/* ---------------- BANNER IMAGE SECTION END  ---------------- */}
      <section className="bg-[#DFEEF2]">
        <section className="max-w-[1440px] mx-auto">
          <div className="w-full hidden lg:mx-auto px-4 xl:px-10 py-4 md:py-6 md:flex justify-center items-center">
            <CrisisWidget
              floating
              className={'bg-[#DFEEF2] px-6 h-auto pt-10'}
              defaultProduct={{
                creator: 'EMR-DIS-PSE-2023-01-01',
                label: 'Medical Relief',
                value: 335,
              }}
              lang={lang}
            />
          </div>
        </section>
      </section>
      {/* ---------------- Urgent Appeal  SECTION START ---------------- */}
      <section className="bg-[#FFFFFF] py-6 md:py-8 flex flex-col">
        <MaxWidthContainer className="!max-w-[1440px] !px-4 md:!px-6">
          <div className="text-content md:px-52">
            <h1 className="text-[20px] md:text-[30px] lg:text-[35px] xl:text-[40px] text-[#253b7e] mb-8 leading-8 md:leading-10 text-center font-brandingBold">
              Urgent Appeal: Heal Palestine & Save Lives With Medical Relief
            </h1>
            <h2 className="text-sm md:text-[30px] text-[#14A2DC] md:leading-10 mb-8 italic text-center font-brandingBold">
              “Blessed is the wealth of the Muslim, from which he gives to the poor, the orphan and the wayfarer”. [Muslim]
            </h2>
          </div>
        </MaxWidthContainer>
      </section>
      {/* ---------------- Urgent Appeal  SECTION END ---------------- */}

      {/* ---------------- NO ONE  SECTION START ---------------- */}
      <section>
        <p className="text-[#253B7E] font-bold md:text-[25px]  bg-[url('/src/images/children-of-gaza/text-bg-banner.webp')] text-center md:leading-7 font-brandingMedium">
          <MaxWidthContainer className="!max-w-[1440px] !px-4 md:!px-6">
            <div className="text-content md:py-10 xs:py-5">
              <p>No one should ever have to watch their loved ones suffer…</p>
              <p>No one should ever have to endure the loss of a family member…</p>
              <p>And no community should struggle with a lack of medical care…</p>
            </div>
          </MaxWidthContainer>
          <MaxWidthContainer className="!max-w-[1440px] !px-4 md:!px-6">
            <div className="  text-content md:px-52 p-5 ">
              <p className="text-lg  text-[#002f87]  text-center md:leading-5 font-brandingMedium">
                This is the sad reality of parents in Palestine where infants are facing a dire crisis due to the ongoing conflict.  Families are
                without access to basic necessities - and baby milk is in critically short supply.
              </p>
            </div>
          </MaxWidthContainer>
        </p>
      </section>
      {/* ---------------- NO ONE  SECTION END ---------------- */}

      {/* ---------------- CRISIS SECTION  SECTION START ---------------- */}
      <section className="bg-[#FFFFFF] py-6 md:py-8 flex flex-col">
        <MaxWidthContainer className="!max-w-[1440px] !px-4 md:!px-6">
          <div className="text-content md:px-52">
            <h2 className="text-[#f60362] text-center text-3xl md:text-4xl font-bold mb-2 md:mb-3">Crisis Situation</h2>
            <p className="text-[#78716C] mb-0 md:mb-1 text-center md:leading-5 font-brandingMedium">
              Most of the hospitals have either been destroyed due to the conflict, or are completely overwhelmed, with basic medical supplies
              critically low. Many of those wounded are being treated in poor conditions without anesthetic. Entire families are falling ill and
              facing life-threatening conditions without the care they desperately need.
            </p>
          </div>
        </MaxWidthContainer>
      </section>
      {/* ---------------- CRISIS SECTION  SECTION END ---------------- */}

      {/* -----------------PRODUCTS SECTION START -------------------- */}
      <section className="aid-section bg-[#FFFFFF]" ref={productRef}>
        <>
          <div className="bg-[#253b7e] ">
            <h2 className="text-4xl md:text-5xl text-[#C6E9FF] text-center pt-10 mx-auto max-w-[90%] md:max-w-[70%]">Your Help is Their Hope</h2>
            <p className="text-center text-[20px] md:text-[24px] text-[#FFFFFF] pt-5 mx-auto max-w-[95%] md:max-w-[80%]">
              Your support can bring immediate, life-saving relief. By donating today, you ensure that vital medical supplies reach the families who
              need them most. Every contribution helps provide the medical attention, medicines, and care that can save lives and keep families
              together.
            </p>
          </div>

          {isLoaded && (
            <div className="bg-[#253b7e]">
              <MaxWidthContainer className="!max-w-[1440px]">
                <ChildrenOfGazaProducts products={products} />
              </MaxWidthContainer>
            </div>
          )}
          <div className="md:bg-[#DFEEF2] xs:bg-[#fff] xs:px-16 xs:py-5 md:px-0 md:py-0">
            <p className="text-[#253B7E] text-5xl md:text-[30px] xs:text-[20px] font-extrabold text-center md:py-10 font-brandingMedium md:max-w-[70%] xs:max-w-[90%] mx-auto">
              Every second counts. Your donation can be the difference between life and death for a family in Palestine. Together, we can provide hope
              and heal the people of Palestine.
            </p>
          </div>
        </>
      </section>
      {/* -----------------PRODUCTS SECTION END -------------------- */}

      {/* ---------------- DONATE NOW  SECTION START ---------------- */}
      <section className="bg-[#FFFFFF] py-6 md:py-8 flex flex-col">
        <MaxWidthContainer className="!max-w-[1440px] !px-4 md:!px-6 text-center">
          <p className="text-[#78716C] text-xl md:text-2xl xl:text-4xl">Click the button below to make an impact now.</p>
          <button
            onClick={handleScrollToButton}
            className="mt-8 px-5 py-2.5 xl:px-[35px] xl:py-[15px] bg-[#F60362] text-white rounded-[100px] text-sm md:text-base lg:text-lg xl:text-xl font-brandingBold"
          >
            Donate Now - Save Lives
          </button>
        </MaxWidthContainer>
      </section>
      {/* ---------------- DONATE NOW  SECTION END ---------------- */}
    </Layout>
  );
};

export default PalastineMedicalRelief;