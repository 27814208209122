import React, { useEffect, useState } from 'react'
import Layout from '../../components/general/layout';
import Founder from '../../images/general/founder.jpg';
import AboutUS from '../../images/general/about-us.jpg';
import { useNavigate,
    // useParams
} from 'react-router-dom';
import useHandleAddProduct from '../../utils/handleAddProduct';
import { selectProducts } from '../../store/products';
import { useSelector } from 'react-redux';
import { selectLanguage, selectSelectedCountryCode, selectSelectedCurrency } from '../../store/user';
import { Helmet } from 'react-helmet';
import SeoData from '../../utils/seo.json';
import TabContent from '../home/components/tab-content';
import { generateLinkWithLang } from '../../utils/linkGenerator';
import { useTranslation } from 'react-i18next';

const DuaForPalestine = () => {
    const navigate = useNavigate();
    const [addProduct] = useHandleAddProduct();
    const [product, setProduct] = useState({});
    const [isLoaded, setIsLoaded] = useState(false);
    const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    const [selectedCurrency, setSelectedCurrency] = useState();
    const products = useSelector(state => selectProducts(state));
    const [palestineEmergencyProducts, setPalestineEmergencyProducts] = useState([0, 0, 0, 0]);
    const selectedcountryCode = useSelector(selectSelectedCountryCode);
    const seo = SeoData[selectedcountryCode];
    const { i18n } = useTranslation();
    const lang = i18n.language;
    /* ------------------------------ Start methods ----------------------------- */

    const getAllProducts = () => {
        setIsLoaded(true);
        let tempPalestineEmergencyProducts = [];

        products.map(item => {
            item.sub_categories.map(inner_item => {
                inner_item.products.map(inner_item_product => {
                    if (inner_item_product.creator === 'MKD-MN-001') {
                        setProduct(inner_item_product);
                    }

                    // palestineEmergencyProducts
                    if (inner_item_product.creator === 'EMR-DIS-PSE-2023-01') {
                        // inner_item_product = Object.assign({}, inner_item_product, {
                        //     name: 'PALESTINE APPEAL',
                        // });
                        tempPalestineEmergencyProducts[0] = inner_item_product;
                    }
                    if (inner_item_product.creator === 'EMR-DIS-PSE-2023-01-01') tempPalestineEmergencyProducts[1] = inner_item_product;
                    if (inner_item_product.creator === 'EMR-DIS-BFPL-2023-01-102') tempPalestineEmergencyProducts[2] = inner_item_product;

                    return inner_item_product;
                });
                return inner_item;
            });
            return item;
        });
        setPalestineEmergencyProducts(tempPalestineEmergencyProducts);
    };

    useEffect(() => {
        products && Object.keys(products).length && !isLoaded && getAllProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products])
    useEffect(() => {
        selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
    }, [selectedCurrencyStore]);


    const handleAddNewProduct = () => {
        addProduct({product: product, currency: selectedCurrencyStore})
    }
    return (
        <Layout hideFooter className="bg-white " childClasses={'pb-0'}>
            {seo &&
                <Helmet>
                    <title>{`${seo['duaForBreakingFast']['title']}`}</title>
                    <meta name="title" content={`${seo['duaForParents']['title']}`} />
                </Helmet>
            }
            <section className="h-[350px] text-white text-[30px] font-bold sm:h-[315px] flex flex-col items-center bg-center bg-cover  bg-no-repeat bg-[url('../src/images/landings/palestine/Palestine-Emergency-Hero-Banner-Mobile.jpg')] md:bg-[url('../src/images/landings/palestine/Palestine-Emergency-Hero-Banner-Desktop.jpg')]" >
                <div className='leading-[36px] md:hidden'>
                    We have a <span className='font-gotcha text-[50px] ml-2'>100%</span>
                </div>
                <div className='leading-[36px] md:hidden'>donation policy</div>
                <div className='text-[18px] md:hidden'>Which means we give <span className='font-gotcha text-[26px] mx-2'>Everything</span>you donate</div>
            </section>
            <section className={`bg-[url('../src/images/ali-banat/pattern.png')] bg-[length:400px_300px] bg-[#ecf5f7]`} dir={lang === 'ar' ? 'rtl' : 'ltr'}>
                <div className="md:container md:mx-auto  ">
                    <div className="grid grid-cols-12 px-3 md:px-4 lg:px-6 py-8 sm:py-20">
                        <div className=" col-span-12 rounded-2xl bg-white p-6 py-10 sm:py-20">
                            <div>
                                <h1 className='text-[30px] sm:text-[50px] md:leading-[60px] font-bold text-center text-[#253B7E]'>
                                    Dua for Palestine: A guide for making sincere supplications for a people in need
                                </h1>
                                <p className='mt-5 sm:mt-10 text-[#78716C] text-[18px] sm:text-[24px]'>
                                    The situation in Palestine has been a focal point of global attention for many years, stirring emotions and solidarity across the globe. Amidst the political, social, and humanitarian issues faced by Palestinians, we turn to spiritual support and prayers as a form of solace and assistance. Dua is a profound aspect of Muslim worship, embodying a personal and direct conversation with Allah (swt). It is seen as a means to seek God's guidance, mercy, and intervention in times of hardship. For Palestine, dua represents not just a plea for help but also an expression of solidarity and a call for peace and justice.
                                </p>
                            </div>

                            <div className='mt-10'>
                                <h2 className='text-[#253B7E] text-[24px] sm:text-[40px] font-bold'>
                                    The Significance of Dua for Palestine
                                </h2>
                                <p className='mt-5 text-[#78716C] text-[16px] sm:text-[24px]'>
                                    For many Muslims around the world, the situation in Palestine is a cause of deep
                                    concern and empathy. Duas for Palestine are made with the intention of seeking relief
                                    for its people, an end to their suffering, and a peaceful resolution to the conflict.
                                    These prayers are a manifestation of the global Muslim community's solidarity with
                                    Palestinians, reflecting a shared hope for dignity, freedom, and peace in the land.
                                    <br /><br />
                                    Common Duas for Palestine <br />
                                    There are many duas that can be made for Palestine, ranging from prayers for peace
                                    and safety to those asking for strength and perseverance.
                                    <br /><br />
                                    Asking for Peace: Prayers for peace in Palestine and the safety of its people are common.
                                    Muslims might pray for an end to violence and for the protection of civilians, hoping for a
                                    future where peace prevails over conflict.
                                    <br /><br />
                                    Seeking Justice: Many duas focus on the theme of justice, asking God to restore the rights
                                    of Palestinians and to bring about a fair resolution to their struggles.
                                    <br /><br />
                                    Strength and Patience: Duas often include prayers for strength and patience for those enduring
                                    hardship in Palestine, asking God to provide them with the resilience needed to overcome their challenges.
                                    <br /><br />
                                    Solidarity and Support: Prayers can also be for the global community to remain steadfast in their
                                    support of Palestine, promoting awareness, and advocating for peaceful solutions.
                                    How to Make Dua for Palestine <br />
                                    Making dua for Palestine involves sincerity, intention, and faith. Muslims are encouraged to be mindful of the
                                    situation, to educate themselves and to feel genuine empathy for those affected. When making dua, it's important
                                    to be specific in what is being asked, to pray with conviction, and to believe in God's power to bring about change.

                                </p>
                                <div className="flex flex-wrap items-center justify-center gap-6 md:gap-12 my-5 md:my-10">
                                    <div className="flex flex-col w-full justify-center">
                                        <TabContent
                                            products={palestineEmergencyProducts}
                                            currency={selectedCurrency}
                                            showDots={true}
                                            showArrows={false}
                                            showMultiProductsOnRow={false}
                                        />
                                    </div>
                                </div>
                                <ul className='mt-5 space-y-8 list-decimal'>
                                    <li className="space-y-5">
                                        <div>
                                            <h3 className='text-[#253B7E] text-[18px] sm:text-[24px] font-bold mb-4'>Dua for patience and victory for the Palestinian people</h3>
                                            <h4 className='text-[#253B7E] text-[18px] sm:text-[20px] font-bold'>"اللَّهُمَّ لَكَ صُمْتُ وَعَلَى رِزْقِكَ أَفْطَرْتُ"</h4>
                                            <p className='mt-3 text-[#78716C] text-[16px] sm:text-[20px]'>
                                                <strong className='block text-gray-700 mb-2'>Rabbana afrigh ‘alayhim sabran wa thabbit aqdaamahum wansurhum ‘ala al-qawm al-kafireen</strong>
                                                Our Lord! Shower them with perseverance, make their steps firm, and give them victory over the disbelieving people.

                                            </p>
                                        </div>

                                    </li>
                                    <li className="space-y-5">
                                        <div>
                                            <h3 className='text-[#253B7E] text-[18px] sm:text-[24px] font-bold mb-4'>
                                                Dua for the success of the Palestinians
                                            </h3>
                                            <h4 className='text-[#253B7E] text-[18px] sm:text-[20px] font-bold'>رَبَّنَآ ءَاتِهِم فِى ٱلدُّنْيَا حَسَنَةً وَفِى ٱلأخِرَةِ حَسَنَةً وَقِهِم عَذَابَ ٱلنَّارِ</h4>
                                            <p className='mt-3 text-[#78716C] text-[16px] sm:text-[20px]'>
                                                <strong className='block text-gray-700 mb-2'>
                                                    Rabbana aatihim fid-dunya hasanatan wa fil-akhirati hasanatan waqihim ‘adhaab an-nar
                                                </strong>
                                                Our Lord! Grant them the good of this world and the Hereafter, and protect them from the torment of the Fire.
                                            </p>
                                        </div>

                                    </li>
                                    <li className="space-y-5">
                                        <div>
                                            <h3 className='text-[#253B7E] text-[18px] sm:text-[24px] font-bold mb-4'>
                                                Du'a for their protection
                                            </h3>
                                            <h4 className='text-[#253B7E] text-[18px] sm:text-[20px] font-bold'>
                                                اللَّهُمَّ اسْتُرْ عَوْرَاتِهِم وَآمِنْ رَوْعَاتِهِم وَاحْفَظْهُم مِنْ بَيْنِ أَيْدِيهِم وَمِنْ خَلْفِهِم وَعَنْ أَيمَانِهِم وَعَنْ شَمَائلِهِم وَمِنْ فَوْقِهِم
                                            </h4>
                                            <p className='mt-3 text-[#78716C] text-[16px] sm:text-[20px]'>
                                                <strong className='block text-gray-700 mb-2'>
                                                    Allahumma ustur ‘awraatihim wa-aamin raw’aatihim wahfadhhum min bayni aydeehim wa min khalfihim
                                                    wa ‘an aymaanihim wa ‘an shamaa’ilihim wa min fawqihim
                                                </strong>
                                                O Allah, conceal their faults, calm their fears, and protect them from before them and behind them,
                                                from their right and from their left, and from above them.
                                            </p>
                                        </div>

                                    </li>
                                    <li className="space-y-5">
                                        <div>
                                            <h3 className='text-[#253B7E] text-[18px] sm:text-[24px] font-bold mb-4'>
                                                Du'a when facing an enemy
                                            </h3>
                                            <h4 className='text-[#253B7E] text-[18px] sm:text-[20px] font-bold'>
                                                اللَّهُمَّ مُنْزِلَ الْكِتَابِ سَرِيعَ الْحِسَابِ مُجْرِيَ السَّحَابِ هَازِمَ الأَحْزَابِ اهْزِمْهُمْ وَزَلْزِلْهُم وَانْصُرْنَا عَلَيْهِمْ
                                            </h4>
                                            <p className='mt-3 text-[#78716C] text-[16px] sm:text-[20px]'>
                                                <strong className='block text-gray-700 mb-2'>
                                                    Allahumma munzil al-kitabi saree’ al-hisabi mujriya as-sahaabi hazim
                                                    al-ahzaabi ihzimhum wa-zalzilhum wansurna ‘alayhim
                                                </strong>
                                                O Allah, the revealer of the Holy Book, and the swift in reckoning, and the mover of
                                                the clouds, and the defeater of the clans; defeat them, and shake them, and grant us victory over them.
                                            </p>
                                        </div>

                                    </li>
                                    <li className="space-y-5">
                                        <div>
                                            <h3 className='text-[#253B7E] text-[18px] sm:text-[24px] font-bold mb-4'>
                                                Du'a to strengthen their trust in Allah
                                            </h3>
                                            <h4 className='text-[#253B7E] text-[18px] sm:text-[20px] font-bold'>
                                                اللَّهُمَّ ارْزُقهُم صِدْقَ التَوَكُّلِ عَلَيكَ وحُسْنُ الظَنِّ بِك
                                            </h4>
                                            <p className='mt-3 text-[#78716C] text-[16px] sm:text-[20px]'>
                                                <strong className='block text-gray-700 mb-2'>
                                                    Allahumma urzuqhum sidq at-tawakkuli ‘alayka wa husn ad-dhanni bik
                                                </strong>
                                                O Allah grant them true reliance upon You, and good thoughts about You.
                                            </p>
                                        </div>

                                    </li>
                                </ul>
                                <p className='mt-5 text-[#78716C] text-[16px] sm:text-[24px]'>
                                    The practice of making dua for Palestine is a reflection of the global Muslim community's concern and compassion
                                    for the Palestinian people. It embodies the hope for an end to suffering and the
                                    establishment of peace and justice in the region. Through these prayers, individuals express their
                                    solidarity, seeking divine intervention while also being reminded of the importance of human action
                                    in addressing the challenges faced by Palestinians.
                                </p>

                            </div>
                            <div className='flex justify-center mt-10'>
                                <button onClick={() => handleAddNewProduct()} className='bg-[#F60362] text-white w-[300px] rounded-full text-[24px] font-bold h-[60px]'>
                                    Donate Now
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='bg-white my-20' dir={lang === 'ar' ? 'rtl' : 'ltr'}>
                <div className="md:container md:mx-auto px-3 md:px-4 lg:px-10 gap-x-3 gap-y-3 flex flex-wrap sm:flex-nowrap items-center">
                    <div className='order-2  basis-full sm:basis-1/2 sm:px-10'>
                        <div className='text-[#253B7E]  text-[24px] sm:text-[40px] font-bold mb-2'>Our Founder</div>
                        <div className='text-[#777777] text-[16px] sm:text-[24px] leading-[25px]'>
                            After decades of indulging in all that the dunya had to offer, and becoming a
                            successful businessman and entrepreneur - everything changed for Ali Banat after a
                            deadly diagnosis. MATW started as an act of kindness and is now a global movement.
                        </div>
                        <div className=' text-[16px] mt-6'>
                            <button onClick={() => navigate(generateLinkWithLang('/ali-banat', lang))} className='bg-[#14A2DC] text-white font-bold px-10 py-1 rounded-full'>Read more</button>
                        </div>
                    </div>
                    <div className='order-1  basis-full sm:basis-1/2'>
                        <img src={Founder} className='' alt="policy" />
                    </div>
                </div>
                <div className="md:container mt-10 md:mx-auto px-3 md:px-4 lg:px-10 gap-x-3 gap-y-3 flex flex-wrap sm:flex-nowrap  items-center">
                    <div className='order-2  basis-full sm:basis-1/2 sm:px-10'>
                        <div className='text-[#253B7E]  text-[24px] sm:text-[40px] font-bold mb-2' >About Us</div>
                        <div className='text-[#777777] text-[16 px] sm:text-[24px] leading-[25px]'>
                            Muslims Around The World was born of an act of kindness. Founded by Ali Banat
                            after receiving a deadly diagnosis, this organisation has grown from serving communities in one
                            country, to serving more than 96 million people in more than 20 countries around the world.
                        </div>
                        <div className=' text-[16px] mt-6'>
                            <button onClick={() => navigate(generateLinkWithLang('/purpose', lang))} className='bg-[#14A2DC] text-white font-bold px-10 py-1 rounded-full'>Read more</button>
                        </div>
                    </div>
                    <div className='order-1  basis-full sm:basis-1/2'>
                        <img src={AboutUS} className='' alt="policy" />
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default DuaForPalestine;