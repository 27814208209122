import { useEffect, useState } from 'react';
import Layout from '../../components/general/layout';
import { useSelector } from 'react-redux';
import { selectLanguage, selectSelectedCountryCode } from '../../store/user';
import { Helmet } from 'react-helmet';
import SeoData from '../../utils/seo.json';
import { palestineFaqs } from './components/faqs';
import { useKeenSlider } from 'keen-slider/react';
import CrisisWidget from './components/crisisWidget';
import VideosSection from '../../components/general/videosSection';
import { selectProducts } from '../../store/products';
import Palestinepreramadan1 from '../../images/landings/palestine/pre-ramadan-palestine1.jpeg';
import Palestinepreramadan2 from '../../images/landings/palestine/pre-ramadan-palestine2.jpeg';
import Palestinepreramadan3 from '../../images/landings/palestine/pre-ramadan-palestine3.jpeg';
import Palestinepreramadan4 from '../../images/landings/palestine/pre-ramadan-palestine5.jpeg';
import Palestinepreramadan5 from '../../images/landings/palestine/pre-ramadan-palestine5.jpeg';

// import banner from '../../images/landings/palestine/Palestine-mercy-wide-hero-banner.jpg';
// import bannerMobile from '../../images/landings/palestine/Palestine-mercy-mobile-hero-banner.jpg';
import banner from '../../images/landings/palestine/Palestine-Emergency-Hero-Banner-Desktop.jpg';
import bannerMobile from '../../images/landings/palestine/Palestine-Emergency-Hero-Banner-Mobile.jpg';
import matwImpactPhoto from '../../images/landings/palestine/matw-impact.png';
import impactSliderRightIcon from '../../images/landings/palestine/slider-icon-right.png';
import impactSliderLeftIcon from '../../images/landings/palestine/slider-icon-left.png';
import sponsorFamilyImg from '../../images/landings/palestine/crisis-and-emergencies-palestine-palestinian-refugee-egypt-settlement.jpg';
import palestineRefugeeImg from '../../images/landings/palestine/crisis-and-emergencies-palestine-palestinian-refugee-settlement-support.jpg';
import shelterOrphanImg from '../../images/landings/palestine/shelter-for-orphan.jpg';
import feedOrphanImg from '../../images/landings/palestine/feed-a-palestine-orphan.jpg';
import supportFamilyImg from '../../images/landings/palestine/support-a-family-with-orphans.jpg';
import babyMilkImg from '../../images/landings/palestine/food-and-water-aid-food-aid-baby-milk-for-52-weeks.jpg';
import donateMealImg from '../../images/landings/palestine/donate-a-meal.jpg';
import donateCleanWaterImg from '../../images/landings/palestine/donate-clean-water.jpg';
// import YoutubeVideoHero from '../../components/general/youtubeVideoHero';
import { OurPromiseSection } from '../../components/general';
import PalestineTabs from './components/palestineTabs';
import { impactInfo, impactSliderData, videos } from './fixtures';
import MuslimsAroundSection from './components/common-sections/muslimsAround';
import Accordion from '../../components/general/accordion';

const images = [
  { src: Palestinepreramadan1, alt: 'Donate to palestine, they need you' },
  { src: Palestinepreramadan2, alt: 'Palestine Charity to help the palestinian people' },
  { src: Palestinepreramadan3, alt: 'Palestine donation to make an impact' },
  { src: Palestinepreramadan4, alt: 'Palestine appeal, help them today' },
  { src: Palestinepreramadan5, alt: 'donate for palestine emergency appeal' },
];

const DonateToGaza = () => {
  /* ----------------------------- Start variables ---------------------------- */
  // const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const products = useSelector(state => selectProducts(state));
  const selectedcountryCode = useSelector(selectSelectedCountryCode) || 'AU';
  const seo = SeoData[selectedcountryCode];
  const [loaded, setLoaded] = useState(false);
  const [currentSlideImages, setCurrentSlideImages] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [palestineEmergencyProducts, setPalestineEmergencyProducts] = useState([0, 0, 0, 0]);
  const [saveOrphansProducts, setSaveOrphansProducts] = useState([0, 0, 0, 0]);
  const [palestineRefugeeSettlementProducts, setPalestineRefugeeSettlementProducts] = useState([0, 0]);
  const [palestineHungerProducts, setPalestineHungerProducts] = useState([0, 0]);
  const language = useSelector(selectLanguage);
  const [sponsorFamily] = useState({
    title: 'Sponsor a Family',
    description: 'Help provide ongoing support to Palestinian refugee families residing in Egypt. Offer monthly allowances to cover their essential needs, including food, medical expenses, rent and education costs.',
    img: sponsorFamilyImg,
    products: [],
  });
  const [palestineRefugee] = useState({
    title: 'Palestine Refugee Settlement',
    description: 'Help provide all the essentials required to set up a Palestinian refugee family as they resettle in Egypt, in their new home.',
    img: palestineRefugeeImg,
    products: [],
  });
  const [feedAPalestineOrphan] = useState({
    title: 'Feed a Palestinian Orphan',
    description: 'Deliver a hot meal to orphaned children in Gaza and protect them from sleeping hungry during a looming famine.',
    img: feedOrphanImg,
    products: [],
  });
  const [supportFamilyOrphan] = useState({
    title: 'Support A Family With Orphans',
    description: 'Your one-off donation can sustain a widow and families with orphaned children, who have lost their breadwinner and ability to earn a living for an entire month!  This donation will help deliver daily meals and provide access to clean water, helping them survive the famine and alleviate the suffering brought on by conflict.',
    img: supportFamilyImg,
    products: [],
  });
  const [shelterOrphan] = useState({
    title: 'Shelter for Orphans',
    description: `Protect orphans by sheltering them with your Sadaqah! This fund will be used to help protect and provide shelter to Palestinian orphaned children. Through this fund, we're able to deliver essentials such as tents, hygiene kits, blankets, pillows, mattresses and more basic necessities required for temporary settlement.`,
    img: shelterOrphanImg,
    products: [],
  });
  const [donateMeal] = useState({
    title: 'Donate a Meal',
    description: `Stand with Families in Palestine Facing Hunger. Donate a Meal Today. Your donation provides essential nutrients for a family in need.`,
    img: donateMealImg,
    products: [],
  });
  const [donateCleanWater] = useState({
    title: 'Donate Clean Water',
    description: `Donate Clean Water Today: Every dollar provides essential hydration for a family in need.`,
    img: donateCleanWaterImg,
    products: [],
  });
  const [babyMilk] = useState({
    title: 'Baby milk',
    description: `Provide a delivery of an urgent baby milk supply to families who cannot afford to provide this staple to their infant children.`,
    img: babyMilkImg,
    products: [],
  });
  const [sliderRefImages, instanceRefImages] = useKeenSlider({
    mode: 'free-snap',
    slideChanged(slider) {
      setCurrentSlideImages(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
    slides: {
      origin: 'auto',
      perView: 1,
      spacing: 8,
    },
  });

  const [currentImpactSlide, setCurrentImpactSlide] = useState(0);
  const [loadedImpact, setLoadedImpact] = useState(false);
  const [sliderRefImpactSlider, instanceRefImpactSlider] = useKeenSlider({
    mode: 'snap',
    slideChanged(slider) {
      setCurrentImpactSlide(slider.track.details.rel);
    },
    created() {
      setLoadedImpact(true);
    },
    loop: true,

    slides: {
      origin: 'center',
      perView: 3,
      spacing: 32,
    },
  });
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */

  const getAllProducts = () => {
    setIsLoaded(true);
    let tempPalestineEmergencyProducts = [];
    let tempSaveOrphansProducts = [];
    let tempPalestineRefugeeSettlementProducts = [];
    let tempPalestineHungerProducts = [];
    let tempFeedOrphanProducts = [];
    let tempSupportFamilyProducts = [];
    let tempShelterOrphanProducts = [];
    // let tempPalestineRefugeeProducts = [];
    let tempSponsorFamilyProducts = [];
    let tempSupportRefugeeSettlementProducts = [];
    let tempDonateMealProducts = [];
    let tempDonateWaterProducts = [];
    let tempBabyMilkProducts = [];

    products.map(item => {
      item.sub_categories.map(inner_item => {
        inner_item.products.map(inner_item_product => {
          // palestineEmergencyProducts
          if (inner_item_product.creator === 'EMR-DIS-PSE-2023-01') {
            // inner_item_product = Object.assign({}, inner_item_product, {
            //   name: 'PALESTINE APPEAL',
            // });
            tempPalestineEmergencyProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-PSE-2023-01-01') tempPalestineEmergencyProducts[1] = inner_item_product;
          if (inner_item_product.creator === 'EMR-DIS-BFPL-2023-01-102') tempPalestineEmergencyProducts[2] = inner_item_product;

          // Feed orphans
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-315') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: 'Feed An Orphan',
            });
            tempFeedOrphanProducts[0] = inner_item_product
          };
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-316') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: 'Feed 10 Orphans',
            });
            tempFeedOrphanProducts[1] = inner_item_product
          };
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-317') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: 'Feed 20 Orphans',
            });
            tempFeedOrphanProducts[2] = inner_item_product
          };

          // Support family
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-311') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: 'Family With Orphans For 1 Month',
            });
            tempSupportFamilyProducts[0] = inner_item_product
          };
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-312') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: '5 Families With Orphans for 1 month',
            });
            tempSupportFamilyProducts[1] = inner_item_product
          };

          // Shelter orphans
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-313') {
            inner_item_product = Object.assign({}, inner_item_product, {
              anyAmount: true,
            });
            tempShelterOrphanProducts[0] = inner_item_product
          };
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-314') tempShelterOrphanProducts[1] = inner_item_product;

          // sponsorFamilyProducts
          if (inner_item_product.creator === 'EMR-DIS-BFPL-2024-12-208') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alt: 'Palestine Appeal: Sponsor a palestinian family',
              alternativeName: 'Family of 12 for 1 Month',
            });
            tempSponsorFamilyProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-BFPL-2024-8-208') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alt: 'Palestine Appeal: Sponsor a palestinian family',
              alternativeName: 'Family of 8 for 1 Month',
            });
            tempSponsorFamilyProducts[1] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-BFPL-2024-4-208') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alt: 'Palestine Appeal: Sponsor a palestinian family',
              alternativeName: 'Family of 4 for 1 Month',
            });
            tempSponsorFamilyProducts[2] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-208') {
            inner_item_product = Object.assign({}, inner_item_product, {
              anyAmount: true,
              alternativeName: 'Any Amount',
            });
            tempSponsorFamilyProducts[3] = inner_item_product;
          }

          // SupportRefugeeSettlementProducts
          if (inner_item_product.creator === 'EMR-DIS-BFPL-2024-01-207') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alt: 'Palestine Appeal: Sponsor a palestinian family',
              alternativeName: 'Palestinian Refugee Egypt Settlement',
            });
            tempSupportRefugeeSettlementProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-01-207') {
            inner_item_product = Object.assign({}, inner_item_product, {
              anyAmount: true,
            });
            tempSupportRefugeeSettlementProducts[1] = inner_item_product;
          }

          // Donate meal
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-401') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: 'Donate a meal - 20 people',
            });
            tempDonateMealProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-402') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: 'Donate a meal - 50 people',
            });
            tempDonateMealProducts[1] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-400') {
            inner_item_product = Object.assign({}, inner_item_product, {
              anyAmount: true,
              alternativeName: 'Any Amount',
            });
            tempDonateMealProducts[2] = inner_item_product;
          }

          // Donate water
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-404') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: 'Donate Clean Water - 500 people',
            });
            tempDonateWaterProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-405') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: 'Donate Clean Water - 1500 people',
            });
            tempDonateWaterProducts[1] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-406') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: 'Donate Clean Water - 2500 people',
            });
            tempDonateWaterProducts[2] = inner_item_product;
          }
          if (inner_item_product.creator === 'EMR-DIS-AA-2024-403') {
            inner_item_product = Object.assign({}, inner_item_product, {
              anyAmount: true,
              alternativeName: 'Any Amount',
            });
            tempDonateWaterProducts[3] = inner_item_product;
          }

          // Baby milk
          if (inner_item_product.creator === 'FWD-DIS-BMW-2023-01-048') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: 'Baby Milk for 1 Week',
            });
            tempBabyMilkProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'FWD-DIS-BMM-2023-01-049') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: 'Baby Milk for 4 Weeks',
            });
            tempBabyMilkProducts[1] = inner_item_product;
          }
          if (inner_item_product.creator === 'FWD-DIS-BMY-2023-01-050') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alternativeName: 'Baby Milk for 52 Weeks',
            });
            tempBabyMilkProducts[2] = inner_item_product;
          }
          return inner_item_product;
        });
        return inner_item;
      });
      return item;
    });
    tempSaveOrphansProducts[0] = feedAPalestineOrphan;
    tempSaveOrphansProducts[0].products = tempFeedOrphanProducts;
    tempSaveOrphansProducts[1] = supportFamilyOrphan;
    tempSaveOrphansProducts[1].products = tempSupportFamilyProducts;
    tempSaveOrphansProducts[2] = shelterOrphan;
    tempSaveOrphansProducts[2].products = tempShelterOrphanProducts;

    tempPalestineRefugeeSettlementProducts[0] = sponsorFamily;
    tempPalestineRefugeeSettlementProducts[0].products = tempSponsorFamilyProducts;
    tempPalestineRefugeeSettlementProducts[1] = palestineRefugee;
    tempPalestineRefugeeSettlementProducts[1].products = tempSupportRefugeeSettlementProducts;

    tempPalestineHungerProducts[0] = donateMeal;
    tempPalestineHungerProducts[0].products = tempDonateMealProducts;
    tempPalestineHungerProducts[1] = donateCleanWater;
    tempPalestineHungerProducts[1].products = tempDonateWaterProducts;
    tempPalestineHungerProducts[2] = babyMilk;
    tempPalestineHungerProducts[2].products = tempBabyMilkProducts;
    console.log("humger=", tempDonateMealProducts)
    console.log("humger=", tempDonateWaterProducts)
    tempPalestineEmergencyProducts = tempPalestineEmergencyProducts.filter(n => n)
    tempSaveOrphansProducts = tempSaveOrphansProducts.filter(n => n)
    tempPalestineRefugeeSettlementProducts = tempPalestineRefugeeSettlementProducts.filter(n => n)
    tempPalestineHungerProducts = tempPalestineHungerProducts.filter(n => n)
    setPalestineEmergencyProducts(tempPalestineEmergencyProducts);
    setSaveOrphansProducts(tempSaveOrphansProducts);
    setPalestineRefugeeSettlementProducts(tempPalestineRefugeeSettlementProducts);
    setPalestineHungerProducts(tempPalestineHungerProducts);
    // setRefugeeSettlementProducts(tempSupportRefugeeSettlementProducts);
  };
  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Start hooks ------------------------------ */
  // useEffect(() => {
  //   selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
  // }, [selectedCurrencyStore]);
  useEffect(() => {
    // getProductsByName('palestine');
    products && Object.keys(products).length && !isLoaded && getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);
  /* -------------------------------------------------------------------------- */
  //init

  return (
    <Layout className="!bg-white" isRamadanPage>
      {seo && (
        <Helmet>
          <title>{`${seo['donateToGaza']['title']}`}</title>
          <meta name="title" content={`${seo['donateToGaza']['title']}`} />
        </Helmet>
      )}

      <section className="bg-[#DFEEF2] sticky top-16 z-30 md:hidden p-2 md:p-4">
        <CrisisWidget
          ramadanMobileWidget
          className={'bg-[#DFEEF2] h-auto'}
          fixPrice={false}
          lang={language}
          defaultProduct={{
            creator: 'EMR-DIS-PSE-2023-01',
            label: 'Save Lives In Palestine',
            value: 255,
          }}
        />
      </section>

      <section className="flex justify-center">
        <img src={banner} alt="Donate to Gaza with MATW Projects" className="max-[500px]:hidden w-full" />
        <img src={bannerMobile} alt="Donate to Gaza with MATW Projects" className="min-[501px]:hidden w-full" />
      </section>
      {/* /* --------------------------------- Widget --------------------------------- */}
      <section className="bg-[#DFEEF2] hidden md:block sticky top-16 lg:top-24 z-30">
        <div className="max-w-[1160px] px-3 mx-auto">
          <CrisisWidget
            floating
            className={'bg-[#DFEEF2] h-auto pt-10'}
            fixPrice={false}
            lang={language}
            defaultProduct={{
              creator: 'EMR-DIS-PSE-2023-01',
              label: 'Save Lives In Palestine',
              value: 255,
            }}
          />
        </div>
      </section>
      {/* /* -------------------------------------------------------------------------- */}
      {/* ------------------- Products Section -------------------- */}
      <PalestineTabs
        palestineEmergencyProducts={palestineEmergencyProducts}
        saveOrphansProducts={saveOrphansProducts}
        palestineRefugeeSettlementProducts={palestineRefugeeSettlementProducts}
        palestineHungerProducts={palestineHungerProducts}
        lang={language}
      />
      {/* ------------------------ Total Impact Section */}
      <section  className="bg-[#E1EFF2] py-6 md:py-8">
        <div className="flex items-center justify-center mb-4 md:mb-5">
          <img src={matwImpactPhoto} alt="Donate to Gaza with MATW Projects" />
        </div>
        <div className="flex flex-col justify-center mb-8 sm:mb-4 md:mb-10 px-1 md:px-2 ">
          <h2 className="text-[18px] md:text-4xl font-bold text-[#253B7E] text-center">
            <span className="font-normal text-[18px] md:text-4xl text-[#78716C] uppercase mr-1">Total Impact:</span>
            &nbsp; {impactInfo.total}
          </h2>
        </div>
        <div className="hidden sm:flex sm:flex-wrap gap-16 justify-center items-center">
          {impactSliderData.map(({ img, value, title }, index) => (
            <div className="keen-slider__slide flex basis-1/8 flex-col items-center gap-1" key={index}>
              <img src={img.src} alt={img.alt} />
              <div className="flex flex-col items-center text-center">
                <h4 className="md:text-2xl font-bold text-[#253B7E]">{value}</h4>
                <small className="text-xs md:text-base uppercase text-[#78716C]">{title}</small>
              </div>
            </div>
          ))}
        </div>
        <div className="sm:hidden w-full relative px-6">
          <div ref={sliderRefImpactSlider} className="keen-slider flex justify-between items-center">
            {impactSliderData.map(({ img, value, title }, index) => (
              <div className="keen-slider__slide flex w-full basis-36 flex-col items-center gap-1" key={index}>
                <img src={img.src} alt={img.alt} />
                <div className="flex flex-col items-center text-center">
                  <h4 className="md:text-2xl font-bold text-[#253B7E]">{value}</h4>
                  <small className="text-xs md:text-base uppercase text-[#78716C]">{title}</small>
                </div>
              </div>
            ))}
          </div>
          {loadedImpact && instanceRefImpactSlider.current && (
            <>
              <Arrow
                left
                onClick={e => e.stopPropagation() || instanceRefImpactSlider.current?.prev()}
                className="bg-transparent"
                disabled={currentImpactSlide === 0}
                icon={impactSliderLeftIcon}
              />

              <Arrow
                onClick={e => e.stopPropagation() || instanceRefImpactSlider.current?.next()}
                disabled={currentImpactSlide === instanceRefImpactSlider.current.track.details.slides.length - 1}
                className="bg-transparent"
                icon={impactSliderRightIcon}
                rightMargin
              />
            </>
          )}
        </div>
      </section>
      {/* /* -------------------------------------------------------------------------- */}
      <OurPromiseSection alt="Donate to Gaza with our 100% Donation policy" />

      {/* /* -------------------------------------------------------------------------- */}
      <div className="md:container md:mx-auto px-4 py-8 md:py-12" dir={language === 'ar' ? 'rtl' : 'ltr'}>
        <h1 className="text-2xl md:text-[40px] text-[#253b7e] font-bold mb-5 md:mb-8 md:text-center">
          Donate to <span className="text-[#f60362]">Gaza with MATW Projects</span>
        </h1>
        <div className="mt-6 md:mt-8 text-[#78716C] leading-7 md:text-[18px] space-y-4">
          <p>
            Since the start of the conflict on October 7, more than 30,000 Palestinians have lost their lives, including more than 12,000 children.
            Over 63,000 Palestinians have been injured, and more than 1.9 million people have become internally displaced. Families have fled south,
            where conditions are barely liveable. Urgent food, water, and medical supplies are required to ensure their survival.
          </p>
          <p>
            MATW is on the ground providing life-saving assistance for displaced families in Palestine and, more recently, for Palestinian families
            seeking refuge in Egypt. We urgently need your support to help the people of Palestine survive and to help refugee families start over.
          </p>
          <p>
            You can start by using your Sadaqah for Palestine, which will help us provide urgent medical support, food, clean water, shelter, and
            family relocation assistance today. You can also donate your Zakat to Palestine, as our Palestine emergency response program is 100%
            Zakat-applicable.
          </p>
          {/* <p>This Ramadan let’s perform these good deeds and feel Allah’s Blessings and Mercy together.</p> */}
        </div>
      </div>

      {/* /* --------------------------------- Muslim Arounds ------------------------- */}
      <MuslimsAroundSection lang={language} />

      {/* /* -------------------------------------------------------------------------- */}
      <section className="bg-[#F8F8F8] mt-5 pb-5 sm:py-8">
        {/* ----------------- videos section ---------------- */}
        <VideosSection youtube videos={videos} videoBoxClass={'p-0 bg-[#F8F8F8]'} />
        {/* ----------------- videos section --END-------------- */}
        {/* /* --------------------------------- Images --------------------------------- */}
        <section className="mt-4 md:mt-12">
          <div className="md:container md:mx-auto flex justify-center flex-wrap ">
            <div className="w-full  flex-wrap justify-center items-center px-6 sm:px-0 relative">
              <div ref={sliderRefImages} className="keen-slider">
                {images.map((item, index) => {
                  return (
                    <div key={`carousel_images${index}`} className={`keen-slider__slide basis-full  flex justify-center rounded-xl sm:rounded-none`}>
                      {/* <div className={`bg-[url('../src/images/zakat/zakat-alfitr${index + 1}.jpeg')] bg-center rounded bg-cover h-[300px] w-full`} /> */}
                      <img src={item.src} alt={item.alt} className=" bg-top object-cover w-full object-top" />
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="w-full  flex justify-center">
              {loaded && instanceRefImages.current && (
                <div className="dots flex pt-3 ">
                  {[...Array(instanceRefImages.current.track.details.slides.length).keys()].map(idx => {
                    return (
                      <button
                        key={idx}
                        onClick={() => {
                          instanceRefImages.current?.moveToIdx(idx);
                        }}
                        className={
                          'dot border-0 w-[10px] h-[10px] mx-[5px] rounded-full p-1 cursor-pointer' +
                          (currentSlideImages === idx ? ' active bg-[#253A7E]' : ' bg-gray-300')
                        }
                      ></button>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </section>
        {/* /* -------------------------------------------------------------------------- */}
      </section>

      <section className="max-w-[1440px] md:mx-auto px-4 xl:px-20 mt-10"  dir={language === 'ar' ? 'rtl' : 'ltr'}>
        <h2 className="text-[#253B7E] text-left  sm:text-center mb-6 md:mb-8 text-[25px] sm:text-[50px] font-bold">
        Gaza Charity Donations <span className="text-[#F60362]"> FAQ’s</span>: Discover How Your Support Fuels Our Palestine charity Initiatives
        </h2>
        <div className="grid grid-cols-3 gap-x-16 gap-y-8">
          <div className="col-span-3 sm:col-span-1">
            <p className="text-[#253B7E] text-[18px] md:text-2xl font-bold">{palestineFaqs[0].question}</p>
            <p className="text-base mt-3 text-[#78716C]">{palestineFaqs[0].answer}</p>
          </div>
          <div className="col-span-3 sm:col-span-1">
            <p className="text-[#253B7E] text-[18px] md:text-2xl font-bold">{palestineFaqs[1].question}</p>
            <p className="text-base mt-3 text-[#78716C]">{palestineFaqs[1].answer}</p>
          </div>
          <div className="col-span-3 sm:col-span-1">
            <p className="text-[#253B7E] text-[18px] md:text-2xl font-bold">{palestineFaqs[2].question}</p>
            <p className="text-base mt-3 text-[#78716C]">{palestineFaqs[2].answer}</p>
          </div>
        </div>
        <div className="md:container md:mx-auto flex flex-col flex-wrap items-center justify-center md:px-0 mt-6">
                    {palestineFaqs.map((item, index) => {
                        return index > 2 && (
                            <div className='bg-white rounded-md p-4 mt-2 w-full shadow-md' key={`faq${index}`}>
                                <Accordion key={`keyallfaq${index}`} shadow title={item.question} index={index} level={1} noBorder={true}>
                                    {item.answer}
                                </Accordion>
                            </div>
                        );
                    })}
                </div>
      </section>
      {/* /* -------------------------------------------------------------------------- */}
    </Layout>
  );
};
export default DonateToGaza;

function Arrow(props) {
  const disabeld = props.disabled ? ' arrow--disabled' : '';
  return (
    <div
      className={`${props.bg ? props.bg : 'bg-[#F60362]'
        } w-6 h-6 -top-[18px] absolute rounded-full translate-y-1/2 cursor-pointer flex items-center justify-center arrow ${disabeld} ${props.className ? props.className : ''
        } ${props.left ? 'arrow--left left-1' : 'arrow--right left-auto right-1'}`}
      onClick={props.onClick}
    >
      {props.icon ? (
        <img alt="arrow-img" src={props.icon} className={`left-0 relative top-10 ${props.rightMargin ? 'ml-3' : '-ml-3'} `} />
      ) : (
        <svg
          className={`w-4 h-4 fill-white cursor-pointer arrow ${props.left ? 'arrow--left' : 'arrow--right'} ${disabeld}`}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          {props.left && <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />}
          {!props.left && <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />}
        </svg>
      )}
    </div>
  );
}
