import { useEffect, useState } from 'react';
import Layout from '../../components/general/layout';
import aliBanner from '../../images/ali-banat/ali-banat-banner.jpg';
// import AliBannerMobile from '../images/ali-banat/head-banner-mobile.jpg';
import { Helmet } from 'react-helmet';
import SeoData from '../../utils/seo.json';
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../store/user';
import { useSelector } from 'react-redux';
import { selectProducts } from '../../store/products';
import ProductBox from '../../components/general/productBox';
const AliBanatPage = () => {
    /* ----------------------------- Start variables ---------------------------- */
    // const [youtubeID] = useState('dzL6BLPAFBo');
    const selectedcountryCode = useSelector(selectSelectedCountryCode);
    const seo = SeoData[selectedcountryCode];
    const products = useSelector(state => selectProducts(state));
    const [aliBanatProducts, setAliBanatProducts] = useState([0, 0, 0, 0, 0]);
    const [isLoaded, setIsLoaded] = useState(false);
    const selectedCurrency = useSelector(selectSelectedCurrency);
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    const getAllProducts = () => {
        setIsLoaded(true);
        const tempProducts = [];

        products.forEach(item => {
            item.sub_categories.forEach(inner_item => {
                inner_item.products.forEach(inner_item_product => {
                    if (inner_item_product.creator === 'EMR-WNT-FND-2023-04') {
                        tempProducts[0] = inner_item_product;
                    }
                    if (inner_item_product.creator === 'MKD-MN-001') {
                        tempProducts[1] = inner_item_product;
                    }
                    if (inner_item_product.creator === 'OPR-GEN-SUP-2023-01-01') {
                        tempProducts[2] = inner_item_product;
                    }
                    if (inner_item_product.creator === 'MSJ-WA-SMJ-2023-01') {
                        tempProducts[3] = inner_item_product;
                    }
                    if (inner_item_product.creator === 'EVT-BASIC-WATER-WELL-2023') {
                        tempProducts[4] = inner_item_product;
                    }
                });
            });
        });
        setAliBanatProducts(tempProducts);
    };
    /* -------------------------------------------------------------------------- */

    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        products && Object.keys(products).length && !isLoaded && getAllProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products]);
    useEffect(() => {
        // window.scroll({top: 0, left: 0, behavior: 'smooth' })
        window.scroll(0, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    /* -------------------------------------------------------------------------- */
    return (
        <Layout hideFooter>
            {seo &&
                <Helmet>
                    <title>{`${seo['aboutUs']['aliBanat']['title']}`}</title>
                    <meta name="title" content={`${seo['aboutUs']['aliBanat']['title']}`} />
                    <meta name="description" content={`${seo['aboutUs']['aliBanat']['description']}`} />
                </Helmet>
            }
            <div className="h-[300px] bg-cover sm:hidden w-full bg-[url('../src/images/ali-banat/head-banner-mobile.jpg')]"/>

            {<img src={aliBanner} alt='Ali Banat' className='w-full hidden sm:block' onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = "/images/general/logo-replace.png";
                currentTarget.className = 'max-h-[180px] mx-auto'
            }} />}
            <div className="md:container md:mx-auto mt-10 sm:mt-20 mb-10">
                <div className="grid grid-cols-12 px-4 md:px-2 lg:px-0">
                    <div className="px-4 sm:px-6 md:px-10 lg:px-16  col-span-12   rounded-md bg-white pt-6 sm:py-16">
                        <h1 className="col-span-12 font-gotcha text-center text-[24px] sm:text-2xl md:text-5xl text-[#253B7E]  ">
                        Ali Banat: MATW Charity Founder
                        </h1>
                        <h2 className="col-span-12  font-montserratBold text-center text-[30px] sm:text-2xl pb-4 sm:py-10 md:text-6xl text-[#253B7E] ">
                            From Dunya to Destiny
                        </h2>
                        <div className="w-full mt-5- col-span-12 ">
                            <div className="col-span-12 h-[300px] md:h-[400px] lg:h-[600px]">
                                <iframe className='video rounded'
                                    width={'100%'}
                                    height={'100%'}
                                    title='Gifted with Cancer - Ali Banat'
                                    sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
                                    src={`https://youtube.com/embed/dzL6BLPAFBo?autoplay=0?feature=oembed`}>
                                </iframe>
                            </div>
                        </div>
                        <div className=" text-[#78716C] my-6 font-montserratMedium text-[16px] sm:text-[24px] lead">
                            <p className=''>
                                Born and raised in Sydney, Australia, Ali Banat, was an ordinary guy.  Successful and hard-working, Ali became a self-made businessman by the age of 29 years old.
                                <br /><br />
                                Like many others his age with his success, he lived a life of abundance and luxury, sharing the fruits of his hard work with family and friends. He enjoyed life to the fullest, making the most of what the world had to offer.
                                <br /> <br />
                                He loved what he had and the life that he led.
                                <br /> <br />
                                It all changed with a deadly diagnosis. One that would serve as a reminder that time on earth is limited. A gift.
                                <br /> <br />
                                When Ali received this diagnosis, he began to reflect on the life that he had led up until that very point and decided that he hadn’t invested enough in his Akhirah. He couldn’t present what he had - all the material things he’d accumulated, to Allah SWT.
                                <br /> <br />
                                Ali had a choice to make; knowing that his life was drastically cut short. He could have easily fallen into despair, as is common with most people diagnosed with cancer.
                                <br /> <br />
                                Ali chose to see this diagnosis as an opportunity to make a difference in this world and for his Akhirah.
                                <br /> <br />
                                So he sold everything he had, booked a flight to Togo and thus began the journey for Muslims Around The World Project in 2016.
                                <br /> <br />
                                Using his own wealth, Ali began by feeding and supporting orphans and widows in the most impoverished communities within Togo. He invested in agricultural tools and tractors so the community could farm, helping lift themselves out of poverty. He built water wells so they could have clean water, a mosque to pray in, and a school for the children to learn and play.
                                <br /> <br />
                                He began to build a legacy that today has expanded to more than 30 countries around the world.
                                <br /> <br />
                            </p>
                            <p className='text-[#253B7E] font-montserratBold'> A legacy that has fed more than 90 million people.
                                <br /> <br />
                                A legacy that each and every single one of us can now call ours.
                                <br /> <br />
                                It started with Ali. It continues with YOU.
                            </p>
                        </div>
                        <div className="flex justify-center flex-wrap">
                            {aliBanatProducts.map(prod => {
                            return (
                                prod.name !== 'Pakistan Solar Powered Well' && (
                                    <div className="basis-1/1 sm:basis-1/2 md:basis-1/3 p-2" key={prod.id}>
                                        <ProductBox product={prod} currency={selectedCurrency} />
                                    </div>
                                )
                            );
                            })}
                        </div>
                    </div>
                </div>
            </div>

        </Layout>
    );
}

export default AliBanatPage;
