import React, { useEffect, useState } from 'react'
import Layout from '../../components/general/layout'
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../store/user'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { getProducts } from '../../store/products'
import { useKeenSlider } from 'keen-slider/react'
import KaffarahProduct from '../../images/fidya-kaffarah/kaf-product.jpg';
import FidyaProduct from '../../images/fidya-kaffarah/fid-product.jpg';
import Widget from './widget'
import { OurPromiseSection } from '../../components/general'
import { Helmet } from 'react-helmet'
import SeoData from '../../utils/seo.json';
// import banner from '../../images/fidya-kaffarah/fidya-kaffarah-hero-banner.jpg'
// import bannerMobile from '../../images/fidya-kaffarah/fidya-kaffarah-hero-banner-mobile.jpg'
import banner from '../../images/fidya-kaffarah/Fidya&Kaffarah-Mercy-Desktop-Hero-Banner.jpg'
import bannerMobile from '../../images/fidya-kaffarah/Fidya&Kaffarah-Mercy-Mobile-Hero-Banner.jpg'
import YoutubeVideoHero from '../../components/general/youtubeVideoHero'
const images = [
    'img1',
    'img2',
    'img3',
    'img4',
    'img5'
];

const ShowMoreLessComponent = ({ image, name, desc, btnText }) => {


    const [showMoreButton, setShowMoreButton] = useState(true);

    const toggleContent = () => {

        setShowMoreButton(!showMoreButton);
    };


    return (

        <div className="w-full p-2 md:p-3 md:pb-5 rounded-xl shadow-lg bg-white   xl:min-h-[600px]">
            <div

                className="overflow-hidden img-hover-zoom rounded-md  w-full  tooltip"
                onClick={() => window.scrollTo(0, 500)}
            >
                <img className='w-full' src={image} alt="" />
            </div>
            <div className='text-2xl text-[#14A2DC] font-bold text-center my-6'> {name}</div>
            <div>
                <p className={`font-montserratMedium text-[#78716C] text-[16px] px-6 ${showMoreButton ? "line-clamp-3" : ""}`}>
                    {desc}
                </p>
            </div>
            <div className='flex flex-col items-center justify-center pb-4'>
                {desc?.length > 174 &&


                    <button
                        onClick={toggleContent}
                        className="mt-2 text-blue-500  text-sm  hover:underline cursor-pointer"
                    >
                        {showMoreButton ? "Show More" : "Show Less"}
                    </button>

                }
                <button onClick={() => window.scrollTo(0, 500)}
                    className=' bg-[#F60362] mt-6 rounded-full text-white font-montserratMedium w-[230px] h-[50px]'>
                    {btnText}
                </button>
            </div>


        </div>
    );
};

const kaffarh = {
    creator: "RM24-FA-010",
    label: "Kaffarah",
    value: 347
},
    fidya = {
        creator: "RM24-FA-011",
        label: "Fidya",
        value: 348
    }
const FidyaKaffarah = () => {
    /* ----------------------------- Start variables ---------------------------- */
    const [selectedCurrency, setSelectedCurrency] = useState();
    const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    const [ramadanProducts, setRamadanProducts] = useState([0, 0]);
    const [isLoaded, setIsLoaded] = useState(false);
    const products = useSelector((state) => getProducts(state, 'Ramadan', 'General'));
    const location = useLocation();
    const [loaded, setLoaded] = useState(false);
    const [currentSlideImages, setCurrentSlideImages] = useState(0);
    const [sliderRefImages, instanceRefImages] = useKeenSlider({
        mode: "free-snap",
        slideChanged(slider) {
            setCurrentSlideImages(slider.track.details.rel)
        },
        created() {
            setLoaded(true)
        },
        slides: {
            origin: "auto",
            perView: 1,
            spacing: 8,

        },
    })
    const selectedcountryCode = useSelector(selectSelectedCountryCode);
    const seo = SeoData[selectedcountryCode];
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    const getAllProducts = () => {
        setIsLoaded(true);
        let tempProductsList = [];

        let p = products.subCategory.products;
        p.forEach(item => {
            if (item.creator === 'RM24-FA-010') {
                tempProductsList[0] = { ...item };
                tempProductsList[0].image = KaffarahProduct;
                tempProductsList[0].btnText = 'Pay your Kaffarah here';

            }
            if (item.creator === 'RM24-FA-011') {
                tempProductsList[1] = { ...item };
                tempProductsList[1].image = FidyaProduct;
                tempProductsList[1].btnText = 'Pay your Fidya here';

            }
        });
        tempProductsList = tempProductsList.filter(n => n)
        setRamadanProducts(tempProductsList);
    };


    /* -------------------------------------------------------------------------- */
    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
    }, [selectedCurrencyStore]);
    useEffect(() => {

        products && Object.keys(products).length && !isLoaded && getAllProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products])

    /* -------------------------------------------------------------------------- */

    return (
        <Layout className='bg-white' hideFooter>
            {seo &&
                <Helmet>
                    <title>{`${seo['fidyaKaffarah']['title']}`}</title>
                    <meta name="title" content={`${seo['fidyaKaffarah']['title']}`} />
                </Helmet>
            }
            {/* /* --------------------------------- Banner ---------------------------------  */}
            <section className="flex justify-center" >
                <img src={banner} alt="banner" className='max-[500px]:hidden w-full' />
                <img src={bannerMobile} alt="banner" className='min-[501px]:hidden w-full' />
            </section>
            {/* <div className='flex justify-center'><img src={HeroBanner} alt="hero-banner"/></div> */}
            <section className='bg-[#E1EFF2]'>
                <section className='max-w-[1440px] mx-auto'>

                    <div className='w-full  lg:mx-auto  xl:px-10 py-3 sm:flex justify-center items-center'>
                        <Widget className={'bg-[#DFEEF2] px-6 h-auto pt-10'}
                            defaultProduct={location?.state?.productName === 'Fidya' ? fidya : kaffarh}
                            selectedCurrency={selectedCurrency}
                        />
                    </div>

                </section>
            </section>
            <OurPromiseSection />
            <main className='max-w-[1440px] mx-auto'>
                <section className='xl:px-20 mb-8 sm:mt-8 sm:mb-16'>
                    <div className='relative overflow-hidden flex flex-wrap rounded'>
                        <div className='order-2 z-10 sm:order-1 bg-[#F60362] flex flex-col items-center md:items-start justify-center w-full md:max-w-[315px] min-h-[300px]
                        md:min-h-[300px] lg:min-h-[305px] md:top-1/2 md:-translate-y-1/2
                        right-16 bg-no-repeat bg-cover bg-center md:rounded-2xl md:absolute  ' >
                            <div className='leading-[50px] pt-8 w-full text-[30px] sm:text-[50px] md:text-[30px] lg:text-[50px] px-8 text-white text-center font-gotcha'>Fidya and Kaffarah</div>
                            <p className='text-[18px] sm:text-[18px] font-montserratMedium leading-6 mt-3 px-10 text-white text-center'>
                                Make sure to give your Fidya and Kaffarah for your missed or broken fasts.
                                <br /><br />
                            </p>
                        </div>
                        <div className='hidden sm:block absolute w-full h-full bg-[#00000085]' />
                        <YoutubeVideoHero src={`https://www.youtube.com/embed/bC7xyV8_r10?controls=0&autoplay=1&loop=1&mute=1&playlist=bC7xyV8_r10`} />

                    </div>
                </section>
                <section className='xl:px-20'>
                    <div className='flex justify-center'>
                        <div className='text-left sm:text-center font-bold text-[#093686] text-2xl sm:text-6xl px-4 w-full '>
                            <span className='text-[#F60362]'>Fidya</span> and <span className='text-[#F60362] mr-2'>kaffarah</span>
                            donations are made when fasts during Ramadan are missed or broken. This is used to feed the poor and needy.
                        </div>
                    </div>
                    <div className='px-4  mt-8 sm:mt-16 text-[18px]  leading-5'>
                        <div className='text-[#14A2DC] text-left sm:text-center '>
                            <div className='text-[24px] font-bold'>In the Quran it states: </div>
                            <br /><br />
                            <div>
                                "[Observing fasts] for a fixed number of days, but if any of you is ill or on a journey, the same number
                                (should be made up) from other days. And as for those who can fast with difficulty, they have
                                (a choice either to fast or) to feed a Miskin (poor person) (for every day). But whoever does good
                                of his own accord, it is better for him. And that you fast, it is better for you if
                                only you know." — Quran: 2:184
                            </div>
                        </div>

                        <div className="flex  flex-row justify-center flex-wrap col-span-12 mt-10 sm:mb-16">
                            {ramadanProducts.map((item, index) => {
                                return (
                                    <div key={`makedonation${index}`} className="basis-full sm:basis-2/4 md:basis-1/3 xl:basis-2/5 p-2">
                                        <ShowMoreLessComponent image={item.image} name={item.name} desc={item.short_description || item.description} btnText={item.btnText} />
                                    </div>
                                );
                            })}
                        </div>

                    </div>
                </section>



                <section className='xl:px-20 bg-white sm:bg-[#F8F8F8] py-8'>

                    {/* /* --------------------------------- Images --------------------------------- */}
                    <div className="md:container md:mx-auto flex justify-center flex-wrap  mt-12">
                        <div className="basis-full hidden sm:flex flex-wrap">
                            <div className="basis-full md:basis-1/3 md:pr-2 mb-4">
                                <div className="bg-[url('../src/images/fidya-kaffarah/fidya-kaf-1.jpg')] bg-center bg-cover sm:h-[300px] md:h-[350px] lg:h-[400px] xl:h-[465px] 2xl:h-[500px]" />
                            </div>
                            <div className="basis-full md:basis-1/3 md:px-2 mb-4">
                                <div className="bg-[url('../src/images/fidya-kaffarah/fidya-kaf-2.jpg')] bg-center bg-cover sm:h-[300px] md:h-[110px] lg:h-[130px] xl:h-[150px] 2xl:h-[170px] mb-2" />
                                <div className="bg-[url('../src/images/fidya-kaffarah/fidya-kaf-3.jpg')] bg-top bg-cover sm:h-[300px] md:h-[114px] lg:h-[124px] xl:h-[150px] 2xl:h-[150px] mb-2" />
                                <div className="bg-[url('../src/images/fidya-kaffarah/fidya-kaf-4.jpg')] bg-center bg-cover sm:h-[300px] md:h-[110px] lg:h-[130px] xl:h-[150px] 2xl:h-[170px]" />
                            </div>
                            <div className="basis-full md:basis-1/3 md:pl-2 mb-4">
                                <div className="bg-[url('../src/images/fidya-kaffarah/fidya-kaf-5.jpg')] bg-center bg-cover sm:h-[300px] md:h-[350px] lg:h-[400px] xl:h-[465px] 2xl:h-[500px]" />
                            </div>
                            <div className="basis-full">
                                <div className="bg-[url('../src/images/fidya-kaffarah/fidya-kaf-6.jpg')] bg-center bg-cover sm:h-[300px] md:h-[350px] lg:h-[400px] xl:h-[450px] 2xl:h-[500px]" />
                            </div>
                        </div>
                        <div className="w-full sm:hidden flex-wrap justify-center items-center  relative">
                            <div ref={sliderRefImages} className="keen-slider">
                                {images.map((item, index) => {
                                    return <div key={`carousel_images${index}`} className={`keen-slider__slide basis-full px-9 flex justify-center`}>
                                        <div className={`bg-[url('../src/images/fidya-kaffarah/fidya-kaf-${index + 1}.jpg')] bg-center bg-cover h-[300px] w-full`} />
                                    </div>
                                })}
                            </div>
                            {loaded && instanceRefImages.current && (
                                <>
                                    <Arrow
                                        left
                                        bg={'bg-[#253A7E]'}
                                        onClick={(e) =>
                                            e.stopPropagation() || instanceRefImages.current?.prev()
                                        }
                                        disabled={currentSlideImages === 0}
                                    />

                                    <Arrow
                                        bg={'bg-[#253A7E]'}
                                        onClick={(e) =>
                                            e.stopPropagation() || instanceRefImages.current?.next()
                                        }
                                        disabled={
                                            currentSlideImages ===
                                            instanceRefImages.current.track.details.slides.length - 1
                                        }
                                    />
                                </>
                            )}
                        </div>
                        <div className="w-full sm:hidden flex justify-center">
                            {loaded && instanceRefImages.current && (
                                <div className="dots flex pt-3 pb-6">
                                    {[
                                        ...Array(instanceRefImages.current.track.details.slides.length).keys(),
                                    ].map((idx) => {
                                        return (
                                            <button
                                                key={idx}
                                                onClick={() => {
                                                    instanceRefImages.current?.moveToIdx(idx)
                                                }}
                                                className={"dot border-0 w-[10px] h-[10px] mx-[5px] rounded-full p-1 cursor-pointer" + (currentSlideImages === idx ? " active bg-[#253A7E]" : " bg-gray-300")}
                                            ></button>
                                        )
                                    })}
                                </div>
                            )}
                        </div>
                    </div>
                    {/* /* -------------------------------------------------------------------------- */}

                </section>




                {/* -------------------------------------FAQS--------------------------------- */}
                {/* <section className='px-4 xl:px-20 mt-10 bg-white'>
                    <h1 className='text-[#253B7E] text-left  sm:text-center mb-8 text-[30px] sm:text-[60px] font-bold'>
                        Zakat donations <span className='text-[#F60362]'> FAQ’s</span>
                    </h1>
                    <div className='grid grid-cols-3 gap-x-16 gap-y-8'>
                        <div className='col-span-3 sm:col-span-1'>
                            <h1 className='text-[#253B7E] font-bold text-2xl leading-7'>{zakatFaqs[0].question}</h1>
                            <p className='text-base font-montserratMedium leading-4 mt-3'>
                                {zakatFaqs[0].answer}
                            </p>
                        </div>
                        <div className='col-span-3 sm:col-span-1'>
                            <h1 className='text-[#253B7E] font-bold text-2xl leading-7'>{zakatFaqs[1].question}</h1>
                            <p className='text-base font-montserratMedium leading-4 mt-3'>
                                {zakatFaqs[1].answer}
                            </p>
                        </div>
                        <div className='col-span-3 sm:col-span-1'>
                            <h1 className='text-[#253B7E] font-bold text-2xl leading-7'>{zakatFaqs[2].question}</h1>
                            <p className='text-base font-montserratMedium leading-4 mt-3'>
                                {zakatFaqs[2].answer}
                            </p>
                        </div>
                    </div>
                    <div className="md:container md:mx-auto flex flex-col flex-wrap items-center justify-center md:px-0 mt-6">
                        {zakatFaqs.map((item, index) => {
                            return index > 2 && (
                                <div className='bg-white rounded-md p-4 mt-2 w-full shadow-md' key={`faq${index}`}>
                                    <Accordion key={`keyallfaq${index}`} shadow title={item.question} index={index} level={1} noBorder={true}>
                                        {item.answer}
                                    </Accordion>
                                </div>
                            );
                        })}
                    </div>
                </section> */}
            </main>
        </Layout>
    )
}

export default FidyaKaffarah;
function Arrow(props) {
    const disabeld = props.disabled ? " arrow--disabled" : ""
    return (
        <div
            className={`${props.bg ? props.bg : 'bg-[#F60362]'} w-[30px] h-[60px] absolute top-[calc(50%-60px)] translate-y-1/2 cursor-pointer flex items-center justify-center arrow ${disabeld} ${props.left ? "arrow--left left-[0px]" : "arrow--right left-auto right-[0px]"}`}
            onClick={props.onClick}
        >
            <svg
                className={`w-[24px] h-[24px] fill-white cursor-pointer arrow ${props.left ? "arrow--left" : "arrow--right"
                    } ${disabeld}`}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
            >
                {props.left && (
                    <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
                )}
                {!props.left && (
                    <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
                )}
            </svg>
        </div>
    )
}







