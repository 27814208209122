import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectSelectedCurrency } from "../../store/user";
import ReactSelect from "react-select";
// import services from "../../services";
import ShowCurrencies from "../../utils/showCurrencies";
import useHandleAddProduct from "../../utils/handleAddProduct";
import { selectProducts } from "../../store/products";
import WidgetCurrency from "./widgetCurrency";
const styles = {
    control: (base) => ({
        ...base,
        height: '56px',
        border: '1px solid #cccac5',
        borderRadius:0,
        fontSize:20
        // boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.10)'
    }),
    menuList: (base) => ({
        ...base,
        "::-webkit-scrollbar": {
            width: "6px",
            height: "0px",
        },
        "::-webkit-scrollbar-track": {
            background: "#f1f1f1"
        },
        "::-webkit-scrollbar-thumb": {
            background: "#888"
        },
        "::-webkit-scrollbar-thumb:hover": {
            background: "#555"
        }
    }),
    shadow: {
        // boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.10)',
        // border:'1px solid #cccac5',
        color:'#b2a79d',
        background:'white',
    }
}

const Widget = (props) => {
    /* ----------------------------- Start variables ---------------------------- */
    const { defaultProduct, hasSuggestPrice, className } = props;
    const selectedCurrencyStore = useSelector(selectSelectedCurrency);
    const products = useSelector((state) => selectProducts(state));
    const [productsList, setProductsList] = useState([]);
    const [allProducts, setAllProducts] = useState([]);
    const [productAmount, setProductAmount] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    const [currentCurrency, setCurrentCurrency] = useState();
    const [selectedRecurringMode, setSelectedRecurringMode] = useState('one-off');
    const [selectedPrice, setSelectedPrice] = useState(100);
    const [selectedProduct, setSelectedProduct] = useState(defaultProduct);
    const [addProduct] = useHandleAddProduct();
    const suggestedPrices = [
        50, 100, 150
    ]
    const suggestedRecurringModes = [
        { lable: 'Single', value: 'one-off' },
        // { lable: 'Weekly', value: 'week' },
        { lable: 'Monthly', value: 'month' }
    ]
    /* -------------------------------------------------------------------------- */

    /* ------------------------------ Start methods ----------------------------- */
    const getAllProducts = () => {
        setIsLoaded(true);
        let tempMakeDonationProducts = [];
        let tempProductsList = [];
        products.map(item => {
            item.sub_categories.map(inner_item => {
                inner_item.products.map(inner_item_product => {
                    if (inner_item_product.creator === defaultProduct.creator) {
                        setSelectedProduct(inner_item_product)
                    }
                    if (inner_item.name === 'Earthquake Appeal') {
                        if (inner_item_product.creator === 'EMR-MR-WMN-001-2023') { // Morocco Appeal
                            tempMakeDonationProducts.push(inner_item_product);
                            tempProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator })
                        }
                        if (inner_item_product.creator === 'EMR-DIS-AFG-2023-02') { // Afghanistan Earthquake Appeal
                            tempMakeDonationProducts.push(inner_item_product);
                            tempProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator })
                        }
                    };
                    if (inner_item.name === 'Libya Floods') {
                        if (inner_item_product.creator === 'EMR-LBY-WMN-002-2023') { // Libya Floods
                            tempMakeDonationProducts.push(inner_item_product);
                            tempProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator })
                        }
                    };
                    if (inner_item.name === 'Winter Campaign') {
                        if (inner_item_product.creator === 'MKD-EMR-WNT-2023-01') { // Keep Orphans Warm Where Most Needed
                            tempMakeDonationProducts.push(inner_item_product);
                            tempProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator })
                        }
                    };
                    if (inner_item.name === 'Make A Donation') {
                        tempMakeDonationProducts.push(inner_item_product);
                        tempProductsList.push({ value: inner_item_product.id, label: inner_item_product.name, creator: inner_item_product.creator })
                    }
                    return inner_item_product;
                });
                return inner_item;
            })
            return item;
        });
        console.log(tempProductsList)
        setProductsList(tempProductsList);
        setAllProducts(tempMakeDonationProducts)
    };
    const handleCurrency = (currency) => {
        setCurrentCurrency(currency.value)
        // selectedCurrency(currency);
    }
    const handleProduct = (value) => {
        // console.log("product", value, selectedProduct,allProducts);
        allProducts.map(item => item.id === value.value && setSelectedProduct(item))
    }
    const handleAddNewProduct = () => {
        let tempProduct = selectedProduct;
        let customAmount = Number(productAmount) ? Number(productAmount) : Number(selectedPrice) ? Number(selectedPrice) : 100;
        tempProduct = Object.assign({}, tempProduct, {
            quantity: customAmount,
            recurring: selectedRecurringMode,
        })
        addProduct({product: tempProduct, currency: currentCurrency})
    }

    /* -------------------------------------------------------------------------- */

    /* ------------------------------- Start hooks ------------------------------ */
    useEffect(() => {
        products.length && !isLoaded && getAllProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products])
    useEffect(() => {
        selectedCurrencyStore ? setCurrentCurrency(selectedCurrencyStore) : setCurrentCurrency('AUD')
    }, [selectedCurrencyStore])
    /* -------------------------------------------------------------------------- */
    return (
        <div className="bg-[#f1efe9] p-3">
            <h1 className="text-center font-bold text-2xl"> Donate Now</h1>
            <div className={`max-w-[380px]  rounded-lg p-3 md:p-5 flex items-center ${className}`}>
                <div className="w-full grid grid-cols-12 gap-4">
                    <div className="col-span-12 grid grid-cols-6 gap-2 h-12">
                        {/* <div className="absolute -rotate-12 right-4 -top-8">

                        <span className="font-gotcha text-[18px] text-[#253B7E] rotate-180">Greater Impact</span>
                    </div>
                    <div className="absolute  right-[100px] -top-2">
                        <span><img src={Arrow}/></span>

                    </div> */}
                        {suggestedRecurringModes.map((mode, index) => {
                            return <div key={`modes${index}`} className="col-span-3" style={styles.shadow}>
                                <div
                                    className={`h-12 border-[#cccac5] border  text-lg md:text-xl transition-all duration-200 cursor-pointer flex justify-center items-center px-2 md:px-4 w-full ${selectedRecurringMode === mode.value ? 'bg-[#F60362] text-white' : 'bg-white text-[#F60362] hover:bg-[#F60362] hover:text-white'}`}
                                    onClick={() => {
                                        setSelectedRecurringMode(mode.value);
                                        setProductAmount('');
                                    }}
                                >
                                    {mode.lable}
                                </div>
                            </div>
                        })}
                    </div>
                    <div className="col-span-12">
                        <ReactSelect
                            options={productsList}
                            components={{
                                IndicatorSeparator: () => null
                              }}

                            className=' h-14 rounded-none'
                            defaultValue={selectedProduct}
                            isSearchable={false}
                            styles={styles}
                            onChange={(e) => handleProduct(e)}
                        />
                    </div>
                    {hasSuggestPrice && <div className="col-span-12 grid grid-cols-6 gap-2 h-12">
                        {suggestedPrices.map((price, index) => {
                            return <div key={`prices${index}`} className="col-span-2 border-[#cccac5] border" style={styles.shadow}>
                                <div
                                    className={`h-12  text-lg md:text-xl transition-all duration-200 cursor-pointer flex justify-center items-center px-2 md:px-4 min-w-[80px] ${selectedPrice === price ? 'bg-[#F60362] text-white' : 'bg-white text-[#F60362] hover:bg-[#F60362] hover:text-white'}`}
                                    onClick={() => {
                                        setSelectedPrice(price);
                                        setProductAmount('');
                                    }}
                                >
                                    {ShowCurrencies(currentCurrency, price, false)}

                                </div>
                            </div>
                        })}
                    </div>}
                    <div className="col-span-12 flex flex-wrap">
                        {/* <div className="flex justify-center items-center mr-2 bg-white text-[#F60362]  w-12 h-12" style={styles.shadow}>
                            {currentCurrency}
                        </div> */}
                        <div className="flex justify-center items-center  bg-white text-[#F60362] border-[#cccac5] border h-14 border-r-0" style={styles.shadow}>
                            <WidgetCurrency onChangeCurrency={(e) => handleCurrency(e)} />
                        </div>
                        <div className="flex grow justify-center items-center bg-white text-[#F60362] border-[#cccac5] border  h-14 px-2" style={styles.shadow}>
                            <input
                                value={productAmount}
                                className="w-full p-2   text-xl text-black focus:outline-none focus:ring-1 focus:ring-transparent focus:border-transparent"
                                type="number"
                                onChange={(e) => {
                                    setProductAmount(e.target.value);
                                    setSelectedPrice(0);
                                }}
                                placeholder="Enter Amount" />
                        </div>

                    </div>
                    {/* {show &&
                <div className="col-span-12 flex h-[63px] p-3  bg-white rounded">

                    <div className=" text-[12px] bold"><div>Want to make an even bigger impact?</div>
                        <div className="font-montserratMedium leading-3">Would you like  to make this a monthly donation?</div>
                    </div>
                    <div className="flex justify-between items-center basis-1/4">
                        <button onClick={()=> {setSelectedRecurringMode('month');setProductAmount('');setShow(false)}} className="bg-[#F60362] w-[48px] mr-1 h-[27px] text-white rounded">Yes</button>
                        <button onClick={()=> setShow(false)} className="bg-[#253B7E] w-[48px] h-[27px] text-white rounded">No</button>
                    </div>
                </div>
                }*/}
                    <div className="col-span-12">
                        <button
                            className={`text-white w-full text-sm md:text-2xl transition-all duration-200 flex items-center justify-center h-[58px] px-3 bold uppercase ${isLoaded ? 'bg-[#f60362]' : 'bg-stone-300 cursor-not-allowed'}`}
                            onClick={(e) => { handleAddNewProduct() }}
                        >Donate Now</button>
                    </div>
                    <div className="col-span-12 text-[#6C6B6A] text-center">
                        We accept all major cards and PayPal
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Widget;
