import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../../store/user';
import Layout from '../../../components/general/layout';
import SeoData from '../../../utils/seo.json';
import CrisisWidget from '../../crisis-and-emergencies/components/crisisWidget';
import { useNavigate } from 'react-router-dom';
import { selectProducts } from '../../../store/products';
import { faq, sadaqahJariyaText, tabButtonsSadaqah } from './fixtures';
import banner from '../../../images/landings/ramadan/laylatul-qadr-hero-banner-desktop.jpg';
import bannerMobile from '../../../images/landings/ramadan/laylatul-qadr-hero-banner-mobile.jpg';
import duasImg from '../../../images/landings/ramadan/laylatulqadr-duas-photo.png';
import babyMilkPhoto from '../../../images/landings/ramadan/palestine-baby-milk.jpg';
import iftarMealsEmergencyImg from '../../../images/landings/ramadan/palestine-iftar.jpg';
import iftarMealsRamadanImg from '../../../images/landings/ramadan/food-and-water-aid-food-aid-family-iftar.jpg';
import anyAmountMasjidPhoto from '../../../images/landings/ramadan/any-amount-masjid.png';
import basicWaterWell from '../../../images/landings/ramadan/sadaqah-jariyah-build-a-water-well-pakistan-basic-water-well.jpg';
import masjidIftarPhoto from '../../../images/landings/ramadan/food-and-water-aid-food-aid-masjid-iftar-for-50-people.jpg';
import feedOrphanImg from '../../../images/landings/ramadan/feed-an-orphan.jpg';

import { createPortal } from 'react-dom';
import ReportModal from '../../../components/general/reportModal';
import { Helmet } from 'react-helmet';
import MaxWidthContainer from '../../../components/general/maxWidthContainer';
import waterWellSubPhoto from '../../../images/landings/ramadan/water-well-sub.png';
import orphansSubPhoto from '../../../images/landings/ramadan/orphans-sub.png';
import projNoorSubPhoto from '../../../images/landings/ramadan/projectnoor-sub.png';
import SadaqahJariyahTabs from '../../home/components/sadaqaJariyahTabs';
import { QuoteWrapper } from '../../../components/general/quoteWrapper';
import HeroTab from '../../../components/general/heroTab';
import { generateLinkWithLang } from '../../../utils/linkGenerator';
import { useTranslation } from 'react-i18next';

const LaylaTulQadrLandingPage = () => {
  /* ----------------------------- Start variables ---------------------------- */
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const products = useSelector(state => selectProducts(state));
  const [selectedCurrency, setSelectedCurrency] = useState();
  const selectedcountryCode = useSelector(selectSelectedCountryCode);
  const seo = SeoData[selectedcountryCode];
  const [isLoaded, setIsLoaded] = useState(false);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [sadaqahProducts, setSadaqahProducts] = useState([0, 0, 0, 0, 0, 0]);
  const [waterWellProducts, setWaterWellProducts] = useState([0, 0, 0, 0, 0]);
  const [buildAMasjidProducts, setBuildAMasjidProducts] = useState([0, 0, 0, 0, 0]);
  const [zakatProducts, setZakatProducts] = useState([0, 0]);
  const [palestineEmergencyProducts, setPalestineEmergencyProducts] = useState([0, 0, 0, 0]);
  const [ramdanProducts, setRamdanProducts] = useState([0, 0, 0, 0]);
  const { i18n } = useTranslation();
  const lang = i18n.language;
  const [basicWaterWellProducts] = useState({
    title: 'Basic water well',
    description: 'Hand-operated water well pump for Pakistan, Bangladesh, and India.',
    img: basicWaterWell,
    products: [],
    alt: 'Muslim Charity to Iftar meals',
  });

  const [babyMilkProducts] = useState({
    title: 'BABY MILK',
    description: 'Provide a delivery of an urgent baby milk supply to families who cannot afford to provide this staple to their infant children.',
    img: babyMilkPhoto,
    products: [],
  });
  const [iftarMealsProduct] = useState({
    title: 'Iftar Meals',
    description: 'Help us provide hot Iftar Meals to help break the fasts of those in need during the holy month of Ramadan',
    img: iftarMealsEmergencyImg,
    products: [],
    alt: 'Muslim Charity to Iftar meals',
  });

  const [iftarMealsProductRamadan] = useState({
    title: 'Iftar Meals',
    description: 'Help us provide hot Iftar Meals to help break the fasts of those in need during the holy month of Ramadan',
    img: iftarMealsRamadanImg,
    products: [],
    alt: 'Muslim Charity to Iftar meals',
  });

  const [MasjidIftarProductRamadan] = useState({
    title: 'Masjid iftar ',
    description: 'Help us provide hot Iftar Meals to help break the fasts of those in need during the holy month of Ramadan',
    img: masjidIftarPhoto,
    products: [],
    alt: 'Muslim Charity to Iftar meals',
  });

  const [feedAnOrphanProductRamadan] = useState({
    title: 'FEED AN ORPHAN FOR 30 DAYS ',
    description: 'Help provide daily nutritious staple meals for an orphaned child every day this Ramadan!',
    img: feedOrphanImg,
    products: [],
    alt: 'Muslim Charity to Iftar meals',
  });

  /* -------------------------------------------------------------------------- */

  const tabButtonsProducts = [
    {
      title: 'Zakat al mal',
      description: `Multiply your blessings and your reward by fulfilling your obligation on Laylatul Qadr.`,
      products: zakatProducts,
    },
    {
      title: 'Build a Masjid',
      description: `Take your Sadaqah Jariyah to the next level by building a house in Jannah on Laylatul Qadr! Build a Masjid in your name today.`,
      products: buildAMasjidProducts,
    },
    {
      title: 'Build a well',
      description: `Prophet Muhammad PBUH said “The best charity to give is water to drink” - Ahmad. Give this beautiful Sadaqah Jariyah this Laylatul Qadr. Build a well in your name or for a loved one. `,
      products: waterWellProducts,
    },
    {
      title: 'RAMADAN',
      description: `Let the duas of those in need be your testament on the day of Judgement. Feed the hungry this Laylatul Qadr. `,
      products: ramdanProducts,
    },
    {
      title: 'palestine emergency',
      description: `Be the vessel for Allah’s Mercy by saving a life on Laylatul Qadr. Help save lives in Palestine.`,
      products: palestineEmergencyProducts,
    },
  ];

  const [tempGiftSounds] = useState({
    id: 394,
    stripe_product_id: 'prod_PmtIo7JjmgnvhY',
    new_stripe_product_id: 'prod_PmtMBfM07SHcOL',
    us_stripe_product_id: 'prod_PmtQUUj9Knk317',
    paypal_product_id: 'PROD-4W662696LG762711M',
    creator: 'HLT-DIS-EAR-2024-01',
    name: 'Gift Of Sound For 1 Person',
    description:
      "With your Sadaqah Jariyah, we can provide the gift of sound! Your donation delivers much-needed hearing aids and improved access to healthcare. Together, let's improve the lives of underprivileged people with hearing loss. ",
    aud: 300,
    usd: 200,
    gbp: 160,
    cad: 280,
    eur: 190,
    sgd: 280,
    myr: 1000,
    custom_amount: 'Yes',
    image_link: 'https://backend.matwcheckout.org/api/checkout/product/image/sadaqah-jariyah-gift-of-sound.jpg',
    recurring: 'Allowed',
    status: 'Active',
    created_at: '2024-03-23 12:28:12',
    updated_at: '2024-03-23 12:44:40',
    deleted_at: null,
    category_id: 28,
    aed: 750,
    idr: 3000000,
    navigationLink: '/sadaqah-jariyah/gift-of-sound',
  });

  /* ------------------------------ Start methods ----------------------------- */
  const getAllProducts = () => {
    setIsLoaded(true);
    let tempSadaqahProducts = [];
    // Water Well Temp Products
    let tempWaterWellProducts = [];
    let tempMainBasicWaterWellProducts = [];
    // Build Masjid Temp Products
    let tempBuildAMasjidProducts = [];
    // Zakat Temp Products
    let tempZakateProducts = [];
    // Palestin Mergency Temp Products
    let tempMainBabyMilkProducts = [];
    let tempIftarProducts = [];
    let tempIftarMealsProducts = [];
    // Ramadan Temp Products
    // let tempIftarMealsRamadanProducts = [];
    let tempMasjidIftarProductRamadan = [];
    let tempfeedAnOrphanProductRamadan = [];
    let tempRamadanProducts = [];

    let tempGiftSoundsProducts = [];

    products.map(item => {
      item.sub_categories.map(inner_item => {
        inner_item.products.map(inner_item_product => {
          // Subscription Tab Starts
          if (inner_item_product.creator === 'GD-MP015') {
            // General Masjid
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'general masjid donations',
              description:
                'Use your Sadaqah Jariyah to contribute what you can to help build a Mosque or Masjid in a poverty-stricken community today.',
              subTitle:
                'Millions of people in underserved communities around the world don’t have access to a Mosque or Masjid. MATW wishes to change that. We believe that everybody should have access to a place of worship that serves as a hub for their community.',
              subTitleCopy:
                'Millions of people in underserved communities around the world don’t have access to a Mosque or Masjid. MATW wishes to change that. We believe that everybody should have access to a place of worship that serves as a hub for their community.',
              image_link: '/images/landings/uk/buildMasjid1.png',
              navigationLink: '/sadaqah-jariyah/build-a-masjid',
            });
            tempSadaqahProducts[0] = inner_item_product;
          }

          if (inner_item_product.creator === 'MKD-WEL-SDQ-2023-01') {
            // Water Well
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Water Wells',
              description:
                'This donation will go towards building wells to provide safe and clean drinking water in impoverished communities and will also help with the maintenance of existing wells.',
              subTitle:
                'Every year, 3.5 million people lose their lives due to water-related diseases, of which 2.2 million are children. We can make a difference in these lives by providing them with a source of clean water.',
              subTitleCopy:
                'Every year, 3.5 million people lose their lives due to water-related diseases, of which 2.2 million are children. We can make a difference in these lives by providing them with a source of clean water.',
              image_link: waterWellSubPhoto,
              navigationLink: '/sadaqah-jariyah/build-a-water-well',
            });
            tempSadaqahProducts[1] = inner_item_product;
          }

          if (inner_item_product.creator === 'MKD-GEN-MOR-2023-01-119') {
            // Food and Water Distribution
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'ORPHANS',
              description:
                'Join us in making a difference by contributing to support orphans with essential provisions such as clothing, food, healthcare, and education.',
              subTitle:
                'Almost 5,700 children are orphaned every day due to war, natural disasters, poverty, and diseases. Whatever the cause, many of these children are alone and vulnerable. Help us give them a fighting chance at a brighter future.',
              subTitleCopy:
                'Almost 5,700 children are orphaned every day due to war, natural disasters, poverty, and diseases. Whatever the cause, many of these children are alone and vulnerable. Help us give them a fighting chance at a brighter future.',
              image_link: orphansSubPhoto,
              navigationLink: '/orphans',
            });
            tempSadaqahProducts[2] = inner_item_product;
          }

          if (inner_item_product.creator === 'HLT-DIS-EAR-2024-01') {
            inner_item_product = Object.assign({}, inner_item_product, {
              alt: 'Muslim Charity to help palestinian refugees',
            });
            tempGiftSoundsProducts[0] = inner_item_product;
          }

          if (inner_item_product.creator === 'MKD-GEN-MPN-2023-01-124') {
            // Sadaqah Jariyah
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'project noor',
              description: 'Project Noor aims to improve the quality of life for 1 visually impaired individual.',
              subTitle: `Our Muslims Around The World team has been hard at work in Bangladesh, Indonesia and Pakistan restoring sight for vulnerable people living in impoverished communities. By giving this Sadaqah Jariyah, you’re changing lives. Help us give the gift of sight.`,
              subTitleCopy:
                'Our Muslims Around The World team has been hard at work in Bangladesh, Indonesia and Pakistan restoring sight for vulnerable people living in impoverished communities. By giving this Sadaqah Jariyah, you’re changing lives. Help us give the gift of sight.',
              status: '1',
              image_link: projNoorSubPhoto,
              navigationLink: '/sadaqah-jariyah/emergency',
            });
            tempSadaqahProducts[4] = inner_item_product;
          }
          // Water Well Products
          if (inner_item_product.creator === 'WWC-CON-PBW-2023-01-030') tempMainBasicWaterWellProducts[0] = inner_item_product;
          if (inner_item_product.creator === 'WWC-CON-BBW-2023-01-029') tempMainBasicWaterWellProducts[1] = inner_item_product;
          if (inner_item_product.creator === 'WWC-CON-IBW-2023-01-031') tempMainBasicWaterWellProducts[2] = inner_item_product;
          if (inner_item_product.creator === 'WWC-CON-ABW-2023-01-028') {
            tempWaterWellProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'MKD-WEL-SDQ-2023-01') {
            tempWaterWellProducts[2] = inner_item_product;
          }
          if (inner_item_product.creator === 'WWC-CON-H2W-2023-01-025') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Heavy Duty Hand Pump Water Well in Uganda',
              description: 'Give a community in Uganda clean water with an H2 hand pump well, reliable for over a decade and backed by a 3-year',
            });
            tempWaterWellProducts[3] = inner_item_product;
          }
          if (inner_item_product.creator === 'WWC-CON-PSPW-2023-01-027') {
            tempWaterWellProducts[4] = inner_item_product;
          }

          // Build A Masjid
          if (inner_item_product.creator === 'MSJ-WA-SMJ-2023-01') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'small Masjid',
            });
            tempBuildAMasjidProducts[0] = inner_item_product;
          }
          if (inner_item_product.creator === 'MSJ-WA-LRG-2023-02') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'LARGE Masjid',
            });
            tempBuildAMasjidProducts[1] = inner_item_product;
          }

          if (inner_item_product.creator === 'MSJ-CON-IMJ-2023-01-014') tempBuildAMasjidProducts[2] = inner_item_product;
          // if (inner_item_product.creator === 'MSJ-CON-PKS-2023-01-016') tempBuildAMasjidProducts[3] = inner_item_product;
          if (inner_item_product.creator === 'GD-MP015') {
            inner_item_product = Object.assign({}, inner_item_product, {
              name: 'Any amount masjid donation',
              image_link: anyAmountMasjidPhoto,
            });
            tempBuildAMasjidProducts[3] = inner_item_product;
          }

          // Zakat Products
          if (inner_item_product.creator === 'RM24-ZM-013') {
            inner_item_product = Object.assign({}, inner_item_product, {
              isStaticProduct: true,
              staticLink: '/zakat',
              staticLinkText: 'GIVE ZAKAT AL MAL',
            });
            tempZakateProducts[0] = inner_item_product;
            tempZakateProducts[1] = Object.assign({}, inner_item_product, {
              name: 'ZAKAT CALCULATOR',
              image_link: '/images/zakat/zakat-cal.jpg',
              isStaticProduct: true,
              staticLink: '/zakat-calculator',
              staticLinkText: 'CALCULATE YOUR ZAKAT',
            });
          }

          // Baby Milk
          if (inner_item_product.creator === 'FWD-DIS-BMW-2023-01-048') tempMainBabyMilkProducts[0] = inner_item_product;
          if (inner_item_product.creator === 'FWD-DIS-BMM-2023-01-049') tempMainBabyMilkProducts[1] = inner_item_product;
          if (inner_item_product.creator === 'FWD-DIS-BMY-2023-01-050') tempMainBabyMilkProducts[2] = inner_item_product;

          // Iftar Products


          return inner_item_product;
        });
        return inner_item;
      });
      return item;
    });

    tempWaterWellProducts[1] = basicWaterWellProducts;
    tempWaterWellProducts[1].products = tempMainBasicWaterWellProducts;
    setWaterWellProducts(tempWaterWellProducts);

    tempIftarMealsProducts[0] = iftarMealsProduct;
    tempIftarMealsProducts[0].products = tempIftarProducts;
    tempIftarMealsProducts[1] = babyMilkProducts;
    tempIftarMealsProducts[1].products = tempMainBabyMilkProducts;

    setPalestineEmergencyProducts(tempIftarMealsProducts);

    tempRamadanProducts[1] = iftarMealsProductRamadan;
    tempRamadanProducts[1].products = tempMasjidIftarProductRamadan;
    tempRamadanProducts[2] = MasjidIftarProductRamadan;
    tempRamadanProducts[2].products = tempMasjidIftarProductRamadan;
    tempRamadanProducts[3] = feedAnOrphanProductRamadan;
    tempRamadanProducts[3].products = tempfeedAnOrphanProductRamadan;

    tempSadaqahProducts[3] = tempGiftSounds;

    tempRamadanProducts = tempRamadanProducts.filter(n => n)
    tempBuildAMasjidProducts = tempBuildAMasjidProducts.filter(n => n)
    tempZakateProducts = tempZakateProducts.filter(n => n)
    tempSadaqahProducts = tempSadaqahProducts.filter(n => n)

    setRamdanProducts(tempRamadanProducts);
    setBuildAMasjidProducts(tempBuildAMasjidProducts);
    setZakatProducts(tempZakateProducts);
    setSadaqahProducts(tempSadaqahProducts);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
  }, [selectedCurrencyStore]);
  useEffect(() => {
    products && Object.keys(products).length && !isLoaded && getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);
  /* -------------------------------------------------------------------------- */
  //init

  return (
    <Layout className="bg-white" isRamadanPage>
      {showModal && createPortal(<ReportModal closeModal={closeModal} />, document.body)}
      {seo && (
        <Helmet>
          <title>{`${seo['ramadan']['laylatulQadr']['title']}`}</title>
          <meta name="title" content={`${seo['ramadan']['laylatulQadr']['title']}`} />
        </Helmet>
      )}
      {/* /* --------------------------------- Banner ---------------------------------  */}
      <section className="bg-[#DFEEF2] sticky top-16 z-30 md:hidden p-2 md:p-4">
        <CrisisWidget
          ramadanMobileWidget
          className={'bg-[#DFEEF2] h-auto'}
          fixPrice={false}
          defaultProduct={{
            creator: 'MKD-MN-001',
            label: 'Where Most Needed',
            value: 122,
          }}
        />
      </section>
      <section className="flex justify-center">
        <img src={banner} alt="Laylatul Qadr 2024: When is laylatul qadr" className="max-[500px]:hidden w-full" />
        <img src={bannerMobile} alt="Laylatul Qadr 2024: Signs of Laylatul Qadr" className="min-[501px]:hidden w-full" />
      </section>
      {/* /* --------------------------------- Widget --------------------------------- */}
      <section className="bg-[#DFEEF2] hidden md:block sticky top-16 lg:top-24 z-30">
        <div className="max-w-[1160px] px-3 mx-auto">
          <CrisisWidget
            floating
            className={'bg-[#DFEEF2] h-auto pt-10'}
            fixPrice={false}
            defaultProduct={{
              creator: 'MKD-MN-001',
              label: 'Where Most Needed',
              value: 122,
            }}
          />
        </div>
      </section>
      {/* Section Intro */}
      <section className="bg-white py-12 hidden md:block">
        <MaxWidthContainer>
          <div className="mx-auto max-w-[1080px]">
            <div className="mx-auto flex flex-col gap-8 text-center max-w-[850px] md:text-[28px] lg:text-[40px] text-[#253B7E] leading-[42px]">
              <p className="font-montserratBold">
                This is the best night of the entire year: Laylatul Qadr – <span className="text-[#ED1463]">The night of power!</span>
              </p>
              <h1 className="font-gotcha">
                Seek Laylatul Qadr in these <span className="text-[#ED1463]">last ten nights!</span>
              </h1>
            </div>
            <div className="mt-8 space-y-5 text-xl text-[#78716C] font-montserratMedium">
              <p>Insha’Allah you’ve reached Laylatul Qadr on this odd night, the night that’s better than 1,000 months.</p>
              <p>
                On this beautiful night in Ramadan, Allah showers His blessings, His Mercy and Forgiveness on all of us. The gates of heaven are open,
                and the gates of Hell are shut with the Shaytaan locked away. With no distractions, we have every opportunity to please Allah and gain
                an incredible amount of good deeds for our simple actions. Every good deed on this night is multiplied by over 1,000. Allah’s Mercy
                and bounty is truly incredible.
              </p>
              <p>Use this night to feel Allah’s Blessings and Mercy, seek His Forgiveness and reap the Rewards.</p>
            </div>
          </div>
        </MaxWidthContainer>
      </section>
      {/* /* ------------------------------- Tab ------------------------------ */}
      {/* <RamadanPageTabs
        riceBagProduct={riceBagsProducts}
        foodAidProducts={foodAidProducts}
        zakatProducts={zakatProducts}
        zakatAlFitrProducts={zakatAlFitrProducts}
        orphansProducts={orphansProducts}
      /> */}
      <HeroTab tabButtons={tabButtonsProducts} />
      <section className="bg-[#253B7E] md:hidden py-5">
        <MaxWidthContainer>
          <div className="flex flex-col gap-8 text-white text-2xl">
            <p className="font-montserratBold leading-7">
              This is the best night of the entire year: Laylatul Qadr – <span className="text-[#ED1463]">The night of power!</span>
            </p>
            <p className="font-gotcha leading-7">
              Seek Laylatul Qadr in these <span className="text-[#ED1463]">last ten nights!</span>
            </p>
          </div>
          <div className="mt-5 space-y-4 text-white font-montserratMedium">
            <p>Insha’Allah you’ve reached Laylatul Qadr on this odd night, the night that’s better than 1,000 months.</p>
            <p>
              On this beautiful night in Ramadan, Allah showers His blessings, His Mercy and Forgiveness on all of us. The gates of heaven are open,
              and the gates of Hell are shut with the Shaytaan locked away. With no distractions, we have every opportunity to please Allah and gain
              an incredible amount of good deeds for our simple actions. Every good deed on this night is multiplied by over 1,000. Allah’s Mercy and
              bounty is truly incredible.
            </p>
            <p>Use this night to feel Allah’s Blessings and Mercy, seek His Forgiveness and reap the Rewards.</p>
          </div>
        </MaxWidthContainer>
      </section>
      {/* Section Quote Wrapper */}
      <section className="py-6 md:py-9 lg:py-12 bg-[#E1EFF2]">
        <QuoteWrapper quoteText='[Holy Quran 97:1-6]'/>
      </section>
      {/* /* ----------------------------------------------------------------------- */}
      <section className="py-7 md:py-12 bg-white">
        <MaxWidthContainer className="max-w-[1080px]">
          <div className="flex flex-col md:flex-row gap-5 md:gap:8 lg:gap-10">
            <div>
              <img src={duasImg} alt="laylatul qadr 2024: ramadan 2024 laylatul qadr" />
            </div>
            <div className="basis-full sm:basis-[540px] flex flex-col justify-center">
              <div className="text-2xl md:text-[30px] text-[#253A7E] font-montserratBold leading-7 sm:leading-8">
                Be the answer to the duas of those who need it most. Give your Zakat this Laylatul Qadr.
              </div>
              <p className="text-[14px] text-[#78716C] sm:text-base font-montserratMedium my-3 sm:my-6 text-left  sm:pr-12 pr-0">
                Those of us who are eligible to pay Zakat Al Mal would generally plan to fulfil this beautiful obligation in the month of Ramadan.
                Why? Your Zakat Al Mal redeems hundreds of times in rewards with Allah SWT as He multiplies this already rich recompense alongside
                every sincere act of worship in this Holy month.
                <br />
                <br />
                Imagine your giving being multiplied by a 1,000! Calculate and give your Zakat Al Mal now, on Laylatul Qadr and reap the rewards of
                this blessed night.
              </p>
              <div className="flex flex-col sm:flex-row justify-center sm:justify-start gap-3 items-center">
                <button
                  onClick={() => navigate(generateLinkWithLang('/zakat', lang))}
                  className="bg-[#F60362] hover:bg-[#00a3da] px-4 md:px-8 py-2 text-white rounded-lg text-[14px] md:text-base text-center uppercase font-bold font-['Montserrat'] min-w-[200px]"
                >
                  Give Zakat Al Mal
                </button>
                <button
                  onClick={() => navigate(generateLinkWithLang('/zakat-calculator', lang))}
                  className="bg-[#F60362] hover:bg-[#00a3da] px-4 md:px-8 py-2 text-white rounded-lg text-[14px] md:text-base text-center uppercase font-bold font-['Montserrat'] min-w-[200px]"
                >
                  Calculate My Zakat
                </button>
              </div>
            </div>
          </div>
        </MaxWidthContainer>
      </section>
      {/* /* ------------------------------- Tabs ------------------------------------- */}
      <div className="mt-4">
        <SadaqahJariyahTabs
          sadaqahProducts={sadaqahProducts}
          currency={selectedCurrency}
          text={sadaqahJariyaText}
          tabButtonsSadaqah={tabButtonsSadaqah}
        />
      </div>

      {/* -------------------------------------FAQS--------------------------------- */}
      <section className="px-4 xl:px-20 mt-12 md:mt-16 bg-white">
        <div className="hidden md:block md:container md:mx-auto">
          <h2 className="text-[#253B7E] md:text-center mb-8 text-[30px] sm:text-[48px] font-bold">
            Laylatul Qadr
            <span className="block md:inline-block ml-2 text-[#F60362]">FAQ’s</span>
          </h2>
          <div className="grid grid-cols-3 gap-x-16 gap-y-8">
            <div className="col-span-3 sm:col-span-1">
              <h3 className="text-[#253B7E] font-bold text-[18px] md:text-2xl leading-7">{faq[0].question}</h3>
              <p className="text-base font-montserratMedium mt-3 text-[#78716C]">{faq[0].answer}</p>
            </div>
            <div className="col-span-3 sm:col-span-1">
              <h3 className="text-[#253B7E] font-bold text-[18px] md:text-2xl leading-7">{faq[1].question}</h3>
              <p className="text-base font-montserratMedium mt-3 text-[#78716C]">{faq[1].answer}</p>
            </div>
            <div className="col-span-3 sm:col-span-1">
              <h3 className="text-[#253B7E] font-bold text-[18px] md:text-2xl leading-7">{faq[2].question}</h3>
              <p className="text-base font-montserratMedium mt-3 text-[#78716C]">{faq[2].answer}</p>
            </div>
          </div>
        </div>
        <div className="md:hidden md:container md:mx-auto">
          <h1 className="text-[#253B7E] md:text-center mb-8 text-[25px] font-bold">
            My Last 10 Nights
            <span className="inline-block ml-2 text-[#F60362]">FAQ’s</span>
          </h1>
          <div className="grid grid-cols-3 gap-x-16 gap-y-8">
            <div className="col-span-3 sm:col-span-1">
              <p className="text-[#253B7E] font-bold text-[18px] md:text-2xl leading-7">{faq[3].question}</p>
              <p className="text-base font-montserratMedium mt-3 text-[#78716C]">{faq[3].answer}</p>
            </div>
            <div className="col-span-3 sm:col-span-1">
              <p className="text-[#253B7E] font-bold text-[18px] md:text-2xl leading-7">{faq[4].question}</p>
              <p className="text-base font-montserratMedium mt-3 text-[#78716C]">{faq[4].answer}</p>
            </div>
            <div className="col-span-3 sm:col-span-1">
              <p className="text-[#253B7E] font-bold text-[18px] md:text-2xl leading-7">{faq[5].question}</p>
              <p className="text-base font-montserratMedium mt-3 text-[#78716C]">{faq[5].answer}</p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default LaylaTulQadrLandingPage;
