import { useEffect, useState } from 'react';
// import { StepOne, StepThree, StepTwo } from '../components/steps';
import { loadStripe } from '@stripe/stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedCurrency, selectUserProductsInStore, setPaypalPlanId, setUserProductsInStore } from '../store/user';
import {
  Elements,
} from '@stripe/react-stripe-js';
import { Helmet } from "react-helmet";
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import StepOneWithLocalStorage from '../components/steps/step1-localstorage-new';
import StepThreeWithLocalStorage from '../components/steps/step3-localstorage-new';
import StepTwoWithLocalStorage from '../components/steps/step2-localstorage-new';

import { BASKET_CACHE_KEY } from '../utils/handleLocalStorage';
import { useSearchParams } from 'react-router-dom';
import { Base64 } from 'js-base64';
import services from '../services';
import IsGTMScriptLoaded from '../utils/isGTMScriptLoaded';
import AddUniversalUSAScript from '../utils/addUniversalUSAScript';
import { useTranslation } from 'react-i18next';
// import StepOneUAT from '../components/steps/step1-uat';

/* ------------------------------- Develop key ------------------------------ */
// const stripePromise = loadStripe('pk_test_51MgmOrDQkDWpqS7iVkczCY6jU43i8BiHm3L002j0vGchBzbUcks0nsHyWb25iP8M0aHLT4FG0CJH3esvXgJFL0lW00D2s5R2lW');
/* -------------------------------------------------------------------------- */

/* ----------------------------- AUD Production key ------------------------- */
// const stripeAUDPromise = loadStripe('pk_live_51HKCkSD75ny8E7UiLn5Vj0f3UW0qwQYEFYXW0HiOWh8hUtw0c2ZwYkC3dr5uN9IzrFrNCbe97j7NUWJCIitFkeae00riWtMndI'); // AUD
/* -------------------------------------------------------------------------- */

/* ----------------------------- New Production key --------------------- */
// const stripeUKPromise = loadStripe('pk_live_51O25i3EpTLP297fAAuxdDPYNX04EtyEqgVKa0Wxl7bdAG1elEa0Gr6kbOKsw1ntgCUX4bkSWKv7m5cF4GZ6POGM600TRvCpUVI'); // Other Currencies
/* -------------------------------------------------------------------------- */

/* ----------------------------- USD Production key ------------------------- */
// const stripeUSDPromise = loadStripe('pk_live_51OUajUBZAMFfbiuoVI4AkoXs29h1Y1cumm2neQQfSl4NgMrZOs3LoqkEcGkfrHNRjpY3BWxTdvZwqIg0uDdQxsGE008wra77T1'); // USD
/* -------------------------------------------------------------------------- */



/* ----------------------------- AUD Develop key ------------------------- */
const stripeAUDPromise = loadStripe('pk_test_51HKCkSD75ny8E7UiW7lXoPe7GbHElAOIvosETrYOEQx3g0RMFV99wnBL35fK87E2rihZtnvpXphdnJwuCEsQTH2k00Hbmi67iu'); // AUD
/* -------------------------------------------------------------------------- */

/* ----------------------------- New Develop key --------------------- */
const stripeUKPromise = loadStripe('pk_test_51O25i3EpTLP297fAo1VY0lvmarj420AumobJeAj9g2BSF0wmGHVw8rNgNA43zmneJu1aA7Nx5mAlYPHYexLa5MrN0013dVC4NM'); // Other Currencies
/* -------------------------------------------------------------------------- */

/* ----------------------------- USD Develop key ------------------------- */
const stripeUSDPromise = loadStripe('pk_test_51OUajUBZAMFfbiuoyF8W0L32jisfM2HSQlvPrxaMwptpMZks5S1b6XbmkWPla22NGb6L4N2ySj4tdBSKyyj3x7GB00tGZJNCPq'); // USD
/* -------------------------------------------------------------------------- */

const CheckoutPage = () => {
  const dispatch = useDispatch();
  const [params] = useSearchParams();
  const ProductID = params.get("id");
  const userProducts = useSelector(selectUserProductsInStore);
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [activeStep, setActiveStep] = useState(0);
  const [isReloaded, setIsReloaded] = useState(true);
  const [isComeFromHome, setIsComeFromHome] = useState(true);
  const [isLaylatulQadr, setIsLaylatulQadr] = useState(false);
  // const [currentLang, setCurrentLang] = useState('en');
  const [basketStates, setBasketStates] = useState({
    timestamp: Date.now() + 24 * 60 * 60 * 7 * 1000,
    stripe_customer_id: '',
    currency: selectedCurrencyStore,
    mode: 'payment',
    adminCost: 0,
    grandTotal: 0,
    recurring: 'one-off',
    paymentFlow: '',
    donor: {
      name: '',
      email: '',
      phone: '',
      address: '',
      city: '',
      postal_code: '',
      countryCode: '',
      country: ''
    },
    user: {
      currency: selectedCurrencyStore,
      email: '',
      id: '',
      name: '',
      phone: '',
      plaque_name: ''
    },
    products: [{
      product: null,
      quantity: 1,
      amount: 0,
      total: 0,
      currency: selectedCurrencyStore,
      recurring: 'one-off',
      onBehalf: 'For the sake of Allah SWT',
    }]
  });
  const steps = [
    { step: 1, title: 'Donation' },
    { step: 2, title: 'Donor' },
    { step: 3, title: 'Payment Method' },
  ]
  const { i18n } = useTranslation();
  const currentLang = i18n.language;
  /* ------------------------------ Start methods ----------------------------- */
  const stripeHandle = (currency, _paymentFlow) => {
    // let _stripe = currency && currency === 'AUD' ? stripePromise : newStripePromise;
    // // let _stripe = stripePromise;
    // console.log("currency in stripe",currency,_stripe);
    // return (
    //     // <Elements stripe={newStripePromise}>
    //     <Elements stripe={currency && currency === 'AUD' ? stripePromise : newStripePromise}>
    //         <StepThree backStep={() => setActiveStep(1)} nextStep={() => setActiveStep(3)} />
    //         {/* <StepThreeWithLocalStorage backStep={() => setActiveStep(1)} nextStep={() => setActiveStep(3)} /> */}
    //     </Elements>
    // );
    let _stripe = stripeUKPromise;
    // if (currency) {
    //     if (currency === 'AUD') {
    //      _stripe = stripeAUDPromise;
    //     } else if (currency === 'USD') {
    //      _stripe = stripeUSDPromise;
    //     }
    // }
    if (_paymentFlow) {
      if (_paymentFlow === 'AUD') {
        _stripe = stripeAUDPromise;
      } else if (_paymentFlow === 'USD') {
        _stripe = stripeUSDPromise;
      } else if (_paymentFlow === 'UK') {
        _stripe = stripeUKPromise;
      }
    }
    return <Elements stripe={_stripe}>
      {/* <StepThree backStep={() => setActiveStep(1)} nextStep={() => setActiveStep(3)} isLaylatulQadr={isLaylatulQadr} /> */}
      {/* <StepThreeWithLocalStorage backStep={() => setActiveStep(1)} nextStep={() => setActiveStep(3)} /> */}
      <StepThreeWithLocalStorage backStep={() => setActiveStep(1)} nextStep={() => setActiveStep(3)} isLaylatulQadr={isLaylatulQadr} />
    </Elements>
  };
  // const handlePaypalProviderProduction = () => {
  //     const basketStatesFromLocalStorage = JSON.parse(localStorage.getItem(BASKET_CACHE_KEY));
  //     // console.log("handle-PaypalProvider==", basketStatesFromLocalStorage, userProducts)
  //     // switch (basketStatesFromLocalStorage.currency) {
  //     switch (userProducts.currency) {
  //         case "USD":
  //             return (
  //                 <PayPalScriptProvider
  //                     options={{
  //                         vault: true,
  //                         currency: 'USD',
  //                         "client-id":
  //                             // Its production id
  //                             "AQfHg_bwXgiVqxlhl9f-N2JWFn60PyZeyKE14GcvDl7DGRrlAm928oCEzd4RZEOVdIDmI-BBdMvMjIwy",

  //                         // Its development id
  //                         // "AXHMNws1OtNOmBaNYo-uRE6Rn0nNxLuY5dgdrqADKpIlOQAQad_wxnsol7Trps3IqWlWoejCrFjs0J_X",
  //                     }}
  //                 >
  //                     {stripeHandle('USD', basketStatesFromLocalStorage.paymentFlow)}
  //                 </PayPalScriptProvider>
  //             );
  //         case "AUD":
  //             return (
  //                 <PayPalScriptProvider
  //                     options={{
  //                         vault: true,
  //                         currency: 'AUD',
  //                         "client-id":
  //                             // Its production id
  //                             "AQfHg_bwXgiVqxlhl9f-N2JWFn60PyZeyKE14GcvDl7DGRrlAm928oCEzd4RZEOVdIDmI-BBdMvMjIwy",

  //                         // Its development id
  //                         // "AXHMNws1OtNOmBaNYo-uRE6Rn0nNxLuY5dgdrqADKpIlOQAQad_wxnsol7Trps3IqWlWoejCrFjs0J_X",
  //                     }}
  //                 >
  //                     {stripeHandle('AUD', basketStatesFromLocalStorage.paymentFlow)}
  //                 </PayPalScriptProvider>
  //             );
  //         case "CAD":
  //             return (
  //                 <PayPalScriptProvider
  //                     options={{
  //                         vault: true,
  //                         currency: 'CAD',
  //                         "client-id":
  //                             // Its production id
  //                             "AQfHg_bwXgiVqxlhl9f-N2JWFn60PyZeyKE14GcvDl7DGRrlAm928oCEzd4RZEOVdIDmI-BBdMvMjIwy",

  //                         // Its development id
  //                         // "AXHMNws1OtNOmBaNYo-uRE6Rn0nNxLuY5dgdrqADKpIlOQAQad_wxnsol7Trps3IqWlWoejCrFjs0J_X",
  //                     }}
  //                 >
  //                     {stripeHandle('CAD', basketStatesFromLocalStorage.paymentFlow)}
  //                 </PayPalScriptProvider>
  //             );
  //         case "GBP":
  //             return (
  //                 <PayPalScriptProvider
  //                     options={{
  //                         vault: true,
  //                         currency: 'GBP',
  //                         "client-id":
  //                             // Its production id
  //                             "AQfHg_bwXgiVqxlhl9f-N2JWFn60PyZeyKE14GcvDl7DGRrlAm928oCEzd4RZEOVdIDmI-BBdMvMjIwy",

  //                         // Its development id
  //                         // "AXHMNws1OtNOmBaNYo-uRE6Rn0nNxLuY5dgdrqADKpIlOQAQad_wxnsol7Trps3IqWlWoejCrFjs0J_X",
  //                     }}
  //                 >
  //                     {stripeHandle('GBP', basketStatesFromLocalStorage.paymentFlow)}
  //                 </PayPalScriptProvider>
  //             );
  //         case "EUR":
  //             return (
  //                 <PayPalScriptProvider
  //                     options={{
  //                         vault: true,
  //                         currency: 'EUR',
  //                         "client-id":
  //                             // Its production id
  //                             "AQfHg_bwXgiVqxlhl9f-N2JWFn60PyZeyKE14GcvDl7DGRrlAm928oCEzd4RZEOVdIDmI-BBdMvMjIwy",

  //                         // Its development id
  //                         // "AXHMNws1OtNOmBaNYo-uRE6Rn0nNxLuY5dgdrqADKpIlOQAQad_wxnsol7Trps3IqWlWoejCrFjs0J_X",
  //                     }}
  //                 >
  //                     {stripeHandle('EUR', basketStatesFromLocalStorage.paymentFlow)}
  //                 </PayPalScriptProvider>
  //             );
  //         case "SGD":
  //             return (
  //                 <PayPalScriptProvider
  //                     options={{
  //                         vault: true,
  //                         currency: 'SGD',
  //                         "client-id":
  //                             // Its production id
  //                             "AQfHg_bwXgiVqxlhl9f-N2JWFn60PyZeyKE14GcvDl7DGRrlAm928oCEzd4RZEOVdIDmI-BBdMvMjIwy",

  //                         // Its development id
  //                         // "AXHMNws1OtNOmBaNYo-uRE6Rn0nNxLuY5dgdrqADKpIlOQAQad_wxnsol7Trps3IqWlWoejCrFjs0J_X",
  //                     }}
  //                 >
  //                     {stripeHandle('SGD', basketStatesFromLocalStorage.paymentFlow)}
  //                 </PayPalScriptProvider>
  //             );
  //         case "MYR":
  //             return (
  //                 <PayPalScriptProvider
  //                     options={{
  //                         vault: true,
  //                         currency: 'MYR',
  //                         "client-id":
  //                             // Its production id
  //                             "AQfHg_bwXgiVqxlhl9f-N2JWFn60PyZeyKE14GcvDl7DGRrlAm928oCEzd4RZEOVdIDmI-BBdMvMjIwy",

  //                         // Its development id
  //                         // "AXHMNws1OtNOmBaNYo-uRE6Rn0nNxLuY5dgdrqADKpIlOQAQad_wxnsol7Trps3IqWlWoejCrFjs0J_X",
  //                     }}
  //                 >
  //                     {stripeHandle('MYR', basketStatesFromLocalStorage.paymentFlow)}
  //                 </PayPalScriptProvider>
  //             );
  //         default:
  //             return (
  //                 <PayPalScriptProvider
  //                     options={{
  //                         vault: true,
  //                         currency: 'USD',
  //                         "client-id":
  //                             // Its production id
  //                             "AQfHg_bwXgiVqxlhl9f-N2JWFn60PyZeyKE14GcvDl7DGRrlAm928oCEzd4RZEOVdIDmI-BBdMvMjIwy",

  //                         // Its development id
  //                         // "AXHMNws1OtNOmBaNYo-uRE6Rn0nNxLuY5dgdrqADKpIlOQAQad_wxnsol7Trps3IqWlWoejCrFjs0J_X",
  //                     }}
  //                 >
  //                     {stripeHandle('USD', basketStatesFromLocalStorage.paymentFlow)}
  //                 </PayPalScriptProvider>
  //             );
  //     }
  // };
  const handlePaypalProviderProductionWithPaymentFlow = () => {
    const basketStatesFromLocalStorage = JSON.parse(localStorage.getItem(BASKET_CACHE_KEY));
    let _paymentFlow = basketStatesFromLocalStorage.paymentFlow
    // console.log("basketess=",basketStatesFromLocalStorage)
    // Test keys
    let _paypalkey = 'AeWFzWKIPclSQveTkzCXhEdbzv9X6iGDweUTSn61pnCz-ajztPDnpUMNv7Y7miHyjIqXQ0CPvMvjQJxk';
    if (_paymentFlow) {
      if (_paymentFlow === 'AUD') {
        _paypalkey = 'AQfHg_bwXgiVqxlhl9f-N2JWFn60PyZeyKE14GcvDl7DGRrlAm928oCEzd4RZEOVdIDmI-BBdMvMjIwy';
      } else if (_paymentFlow === 'USD') {
        _paypalkey = 'ATzYlZdpF98QlXLmRM_fr31K2h9Vvtb4M5mWCcl36UC0sFv4pA2bNTfH6Pl-B_sZdG38EPWYV8B0-5E7';
      } else if (_paymentFlow === 'UK') {
        _paypalkey = 'ASWsz-Ym8UJnrQPMOPTm20FVgmCtqwNriJFKQ3_BPH682B0VtO5HOGo2SmIneyhx5yHTJHcZmjnVGfWq';
      }
    }
    // _paypalkey = 'AQfHg_bwXgiVqxlhl9f-N2JWFn60PyZeyKE14GcvDl7DGRrlAm928oCEzd4RZEOVdIDmI-BBdMvMjIwy';
    switch (basketStatesFromLocalStorage.currency) {
      // switch (userProducts.currency) {
      case "USD":
        return (
          <PayPalScriptProvider
            options={{
              vault: true,
              currency: 'USD',
              "client-id": _paypalkey
            }}
          >
            {stripeHandle('USD', basketStatesFromLocalStorage.paymentFlow)}
          </PayPalScriptProvider>
        );
      case "AUD":
        return (
          <PayPalScriptProvider
            options={{
              vault: true,
              currency: 'AUD',
              "client-id": _paypalkey
            }}
          >
            {stripeHandle('AUD', basketStatesFromLocalStorage.paymentFlow)}
          </PayPalScriptProvider>
        );
      case "CAD":
        return (
          <PayPalScriptProvider
            options={{
              vault: true,
              currency: 'CAD',
              "client-id": _paypalkey
            }}
          >
            {stripeHandle('CAD', basketStatesFromLocalStorage.paymentFlow)}
          </PayPalScriptProvider>
        );
      case "GBP":
        return (
          <PayPalScriptProvider
            options={{
              vault: true,
              currency: 'GBP',
              "client-id": _paypalkey
            }}
          >
            {stripeHandle('GBP', basketStatesFromLocalStorage.paymentFlow)}
          </PayPalScriptProvider>
        );
      case "EUR":
        return (
          <PayPalScriptProvider
            options={{
              vault: true,
              currency: 'EUR',
              "client-id": _paypalkey
            }}
          >
            {stripeHandle('EUR', basketStatesFromLocalStorage.paymentFlow)}
          </PayPalScriptProvider>
        );
      case "SGD":
        return (
          <PayPalScriptProvider
            options={{
              vault: true,
              currency: 'SGD',
              "client-id": _paypalkey
            }}
          >
            {stripeHandle('SGD', basketStatesFromLocalStorage.paymentFlow)}
          </PayPalScriptProvider>
        );
      case "MYR":
        return (
          <PayPalScriptProvider
            options={{
              vault: true,
              currency: 'MYR',
              "client-id": _paypalkey
            }}
          >
            {stripeHandle('MYR', basketStatesFromLocalStorage.paymentFlow)}
          </PayPalScriptProvider>
        );
      default:
        return (
          <PayPalScriptProvider
            options={{
              vault: true,
              currency: 'USD',
              "client-id": _paypalkey
            }}
          >
            {stripeHandle('USD', basketStatesFromLocalStorage.paymentFlow)}
          </PayPalScriptProvider>
        );
    }
  };
  const getProduct = async ({ product, currency = selectedCurrencyStore }) => {
    try {
      const response = await services.getProduct({ product: product, currency: currency });
      // console.log('response get Product in checkoutpage =', response);
      handleVIPPage(response.data, currency)
      // return response.data
    } catch (error) {
      console.error('error========', error);
    }
  };
  const handleVIPPage = (product, currency) => {
    // Create product in store for using in other method
    let commingProductInfo = JSON.parse(Base64.decode(ProductID));
    // let selectedProduct = getProduct({product: commingProductInfo.product, currency: commingProductInfo.currency})
    // console.log("product in checkout pagte before get product=",selectedProduct)
    let tempAmount = product.price_points.filter(item => item.currency === currency.toUpperCase() && item).map(item => item.amount)
    let temp = {
      stripe_customer_id: '',
      currency: currency,
      mode: 'payment',
      products: [{
        product: product,
        quantity: commingProductInfo.quantity,
        amount: tempAmount[0],
        total: commingProductInfo.quantity * tempAmount,
        currency: commingProductInfo.currency,
        recurring: 'one-off',
        onBehalf: 'For the sake of Allah SWT',
      }],
      grandTotal: commingProductInfo.quantity * tempAmount
    };
    let paypalId = product.paypal_plans;
    // console.log("userProducts[0]", temp, paypalId);
    // console.log("payp plan id", paypalId)
    dispatch(setPaypalPlanId(paypalId))
    dispatch(setUserProductsInStore(temp))
    handleAddToCartDataLayer(temp, currency)
  }
  const handleAddToCartDataLayer = (product, currency = selectedCurrencyStore) => {
    // console.log("datalayer add",product)
    let tempProduct = product.products ? product.products[0] : product;
    let dataLayerTemp = {
      event: 'add_to_cart',
      ecommerce: {
        value: tempProduct.product.unit_amount * tempProduct.quantity,
        currency: currency.toUpperCase(),
        items: [{
          item_id: tempProduct.product.creator,
          item_name: tempProduct.product.name,
          item_brand: tempProduct.product.category || '',
          item_category: tempProduct.product.category || '',
          item_description: tempProduct.product.description || '',
          price: tempProduct.product.unit_amount ? tempProduct.product.unit_amount : 1,
          quantity: tempProduct.quantity,
        }]
      }
    }
    window.dataLayer = window.dataLayer || [];
    IsGTMScriptLoaded() && window.dataLayer.push(dataLayerTemp);
  }
  // const customUseScriptOnHead = (content) => {
  //     const script = document.createElement('script');
  //     script.type = 'text/javascript';
  //     script.innerHTML = content;
  //     document.getElementsByTagName('head')[0].appendChild(script);
  //   };

  const customUseScript4 = url => {
    const script = document.createElement('script');
    script.src = url;
    document.getElementsByTagName('body')[0].appendChild(script);
  };
  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Start hooks ------------------------------ */

  useEffect(() => {
    // customUseScript2(`window.gtranslateSettings = {"default_language":"en","detect_browser_language":true,"languages":["en","fr"],"wrapper_selector":".gtranslate_wrapper"}`);
    // customUseScript1('https://cdn.gtranslate.net/widgets/latest/float.js', '');
    customUseScript4('https://maps.googleapis.com/maps/api/js?key=AIzaSyCXVJ062M2GPfxr4DvmF55qSbSp4qh0S6U&libraries=places');
    const basketStatesFromLocalStorage = JSON.parse(localStorage.getItem(BASKET_CACHE_KEY));
    // console.log("//=========basketStatesFromLocalStorage in newproductbox", basketStatesFromLocalStorage);
    if (basketStatesFromLocalStorage) {
      // console.log("******* in checkout page, localstorage", basketStatesFromLocalStorage, basketStates)
      setBasketStates(basketStatesFromLocalStorage);
    }
    // window.scroll({top: 0, left: 0, behavior: 'smooth' })
    window.scroll(0, 0);
    // customUseScriptOnHead(`var head = document.head;var script = document.createElement('script');script.type = 'text/javascript';script.src = "https://206788.t.hyros.com/v1/lst/universal-script?ph=01ca8fee456895e2b732862de7b2f523f7024ba386b77655db6e444828c725b8&tag=!clicked&ref_url=" + encodeURI(document.URL) ;head.appendChild(script);`)
    // customUseScriptOnHead(`var head = document.head;var script = document.createElement('script');script.type = 'text/javascript';script.src = "https://t.matwprojectusa.org/v1/lst/universal-script?ph=01ca8fee456895e2b732862de7b2f523f7024ba386b77655db6e444828c725b8&tag=!clicked&ref_url=" + encodeURI(document.URL);head.appendChild(script);`)
    AddUniversalUSAScript();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    if (ProductID) {
      // console.log("params in checkout page=", Base64.decode(ProductID, Base64.decode(ProductID).vip))
      var tempObj = JSON.parse(Base64.decode(ProductID))
      if (tempObj.vip) {
        setActiveStep(1);
        getProduct({ product: tempObj.product, currency: tempObj.currency })
        // handleVIPPage(ProductID)
      }

      // console.log("iLaylatulQDAR PRODUCT ONLY ===============================",)
      var obj = JSON.parse(Base64.decode(ProductID));
      // console.log((`**************obj******`, obj))
      if (obj.isLaylatuQadr) {
        setActiveStep(1);
        setIsLaylatulQadr(true);
        getProduct({ product: obj.product, currency: obj.currency })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ProductID])
  useEffect(() => {
    console.log("", isReloaded, basketStates)
    userProducts.products && userProducts.products.length > 0 ? setIsReloaded(false) : setIsReloaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProducts])

  useEffect(() => {
    if (ProductID) {

      // console.log("iLaylatulQDAR PRODUCT ONLY ===============================",)
      var obj = JSON.parse(Base64.decode(ProductID));
      // console.log((`**************obj******`, obj))

      if (obj.isLaylatuQadr) {
        setActiveStep(1);
        setIsLaylatulQadr(true);
        getProduct({ product: obj.product, currency: obj.currency })
      }
    }
    // dispatch(setUserProductsInStore(temp));
    // setActiveStep(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ProductID])
  /* -------------------------------------------------------------------------- */
  return (
    <div
      className={`md:container md:mx-auto ${currentLang === 'ar' ? 'font-[AlmariMedium]' : ''}`}
      lang={currentLang || 'en'}
    >
      <Helmet>
        {/* <link rel="canonical" href={`${window.location.protocol + "//" + window.location.host === 'matwproject.org.uk' ? 'matwproject.org' : window.location.host}${window.location.pathname}`} /> */}
        {/* {window.location.host === 'matwproject.org.uk'
                    ? <link rel="canonical" href={`${window.location.protocol + "//" + 'matwproject.org'}${window.location.pathname}`} />
                    : <link rel="canonical" href={`${window.location.protocol + "//" + window.location.host}${window.location.pathname}`} />
                } */}
        <link rel="canonical" href={`${window.location.protocol + "//matwproject.org"}${window.location.pathname}`} />
      </Helmet>
      {/* <div className="gtranslate_wrapper" onClick={handleClick}></div> */}
      <div className="flex flex-wrap justify-center py-4 md:py-4 sm:px-4 md:px-2 lg:px-0">
        {/* <div className="basis-full flex justify-center">
                    <div className='py-1 md:py-3 flex flex-col items-center h-4 md:h-6'>
                        <a href='/'><img className='w-[60px] md:w-[80px] rounded-full' src="/images/general/logo.png" alt="" /></a>
                    </div>
                </div> */}
        <div className="bg-white basis-full lg:basis-10/12 xl:basis-11/12 rounded-md p-3 md:p-6 shadow-md">
          <div className="mb-6 md:mb-6 px-8 md:px-6 mt-3-">
            <ol className="flex items-center w-full">
              {steps.map((item, index) => {
                return <li key={`stepsindex${index}`} className={`flex items-center ${index === steps.length - 1 ? "" : "w-full after:content-[''] after:w-full after:h-0.5 after:border after:inline-block"} ${index === activeStep ? 'text-[#4054b2] after:border-blue-100' : 'after:border-stone-200'}`}>
                  <div className="flex flex-col items-center relative">
                    <span className={`flex items-center justify-center w-8 h-8 md:w-10 md:h-10 rounded-full lg:h-10 lg:w-10 shrink-0 border ${index === activeStep ? 'bg-[#00a3da]' : activeStep > index ? 'bg-green-50 border-green-600' : 'bg-stone-100 border-stone-400'}`}>
                      {index < activeStep && <svg aria-hidden="true" className="w-5 h-5 text-green-600 lg:w-6 lg:h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path>
                      </svg>}
                      {index === 0 && activeStep < 1 && <svg aria-hidden="true" className={`w-5 h-5 lg:w-6 lg:h-6 ${index === activeStep ? 'text-white' : 'text-gray-400'}`} fill={`${index === activeStep ? 'currentColor' : 'none'}`} stroke={`${index !== activeStep ? 'currentColor' : 'none'}`} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z" />
                      </svg>}
                      {index === 1 && activeStep < 2 && <svg aria-hidden="true" className={`w-5 h-5 lg:w-6 lg:h-6 ${index === activeStep ? 'text-white' : 'text-gray-400'}`} fill={`${index === activeStep ? 'currentColor' : 'none'}`} stroke={`${index !== activeStep ? 'currentColor' : 'none'}`} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                      </svg>}
                      {index === 2 && <svg aria-hidden="true" className={`w-5 h-5 lg:w-6 lg:h-6 ${index === activeStep ? 'text-white' : 'text-gray-400'}`} fill={`${index === activeStep ? 'currentColor' : 'none'}`} stroke={`${index !== activeStep ? 'currentColor' : 'none'}`} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z" />
                      </svg>}
                    </span>
                    <span className={`absolute top-[42px] whitespace-nowrap text-xs sm:text-sm ${index === activeStep ? 'text-[#00a3da]' : 'text-stone-400'}`}>{item.title}</span>
                  </div>
                </li>
              })}
            </ol>
          </div>
          <div>

          </div>
          {/* {activeStep === 0 && <StepOne nextStep={() => { setActiveStep(1); setIsComeFromHome(false) }} reload={isReloaded} comeFromHome={isComeFromHome} isLaylatulQadr={isLaylatulQadr} />}
                    {activeStep === 1 && <StepTwo backStep={() => { setActiveStep(0); setIsReloaded(true) }} nextStep={() => setActiveStep(2)} isLaylatulQadr={isLaylatulQadr} />}
                    {activeStep === 2 && handlePaypalProviderProductionWithPaymentFlow(basketStates.paymentFlow)} */}
          {activeStep === 0 && <StepOneWithLocalStorage nextStep={() => { setActiveStep(1); setIsComeFromHome(false) }} isLaylatulQadr={isLaylatulQadr} comeFromHome={isComeFromHome} />}
          {activeStep === 1 && <StepTwoWithLocalStorage backStep={() => { setActiveStep(0); setIsReloaded(true) }} nextStep={() => setActiveStep(2)} isLaylatulQadr={isLaylatulQadr} />}
          {activeStep === 2 && handlePaypalProviderProductionWithPaymentFlow()}
        </div>
        {activeStep !== 2 && <div className="basis-full lg:basis-10/12 xl:basis-7/12 flex flex-wrap justify-center mt-2">
          <div className="basis-1/6 px-1 md:px-4">
            <img src="https://cdn.matwproject.org/images/payment/paypal2.png" alt="" className='w-full' />
          </div>
          <div className="basis-1/6 px-1 md:px-4">
            <img src="https://cdn.matwproject.org/images/payment/visa.png" alt="" className='w-full' />
          </div>
          <div className="basis-1/6 px-1 md:px-4">
            <img src="https://cdn.matwproject.org/images/payment/master.png" alt="" className='w-full' />
          </div>
          <div className="basis-1/6 px-1 md:px-4">
            <img src="https://cdn.matwproject.org/images/payment/apple-pay.png" alt="" className='w-full' />
          </div>
          <div className="basis-1/6 px-1 md:px-4">
            <img src="https://cdn.matwproject.org/images/payment/google-pay.png" alt="" className='w-full' />
          </div>
        </div>}
        <div className="basis-full lg:basis-10/12 xl:basis-9/12 flex flex-wrap mt-2">
          <div className="basis-1/3 flex flex-col justify-center items-center">
            <div className='border border-stone-200 rounded-full p-4 flex justify-center items-center bg-stone-50 text-green-700 w-14'>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                <path fillRule="evenodd" d="M12.516 2.17a.75.75 0 00-1.032 0 11.209 11.209 0 01-7.877 3.08.75.75 0 00-.722.515A12.74 12.74 0 002.25 9.75c0 5.942 4.064 10.933 9.563 12.348a.749.749 0 00.374 0c5.499-1.415 9.563-6.406 9.563-12.348 0-1.39-.223-2.73-.635-3.985a.75.75 0 00-.722-.516l-.143.001c-2.996 0-5.717-1.17-7.734-3.08zm3.094 8.016a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clipRule="evenodd" />
              </svg>
            </div>
            <span className='text-stone-500 mt-2 font-medium text-center min-h-[30px] text-[8px] sm:text-sm md:text-md lg:text-lg'>
              100% Secure Checkout
            </span>
          </div>
          <div className="basis-1/3 flex flex-col justify-center items-center">
            <div className='border border-stone-200 rounded-full p-4 flex justify-center items-center bg-stone-50 text-green-700 w-14'>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                <path fillRule="evenodd" d="M8.603 3.799A4.49 4.49 0 0112 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 013.498 1.307 4.491 4.491 0 011.307 3.497A4.49 4.49 0 0121.75 12a4.49 4.49 0 01-1.549 3.397 4.491 4.491 0 01-1.307 3.497 4.491 4.491 0 01-3.497 1.307A4.49 4.49 0 0112 21.75a4.49 4.49 0 01-3.397-1.549 4.49 4.49 0 01-3.498-1.306 4.491 4.491 0 01-1.307-3.498A4.49 4.49 0 012.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 011.307-3.497 4.49 4.49 0 013.497-1.307zm7.007 6.387a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clipRule="evenodd" />
              </svg>
            </div>
            <span className='text-stone-500 mt-2 font-medium text-center min-h-[30px] text-[8px] sm:text-sm md:text-md lg:text-lg'>
              100% Donation Policy
            </span>
          </div>
          <div className="basis-1/3 flex flex-col justify-center items-center">
            <div className='border border-stone-200 rounded-full p-4 flex justify-center items-center bg-stone-50 text-green-700 w-14'>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                <path fillRule="evenodd" d="M12 1.5a5.25 5.25 0 00-5.25 5.25v3a3 3 0 00-3 3v6.75a3 3 0 003 3h10.5a3 3 0 003-3v-6.75a3 3 0 00-3-3v-3c0-2.9-2.35-5.25-5.25-5.25zm3.75 8.25v-3a3.75 3.75 0 10-7.5 0v3h7.5z" clipRule="evenodd" />
              </svg>

            </div>
            <span className='text-stone-500 mt-2 font-medium text-center min-h-[30px] text-[8px] sm:text-sm md:text-md lg:text-lg'>
              We Protect Your Privacy
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CheckoutPage;