import React, { useState, useEffect } from 'react'
import Layout from '../../components/general/layout'
import banner from '../../images/ramadan/laylatulqadr/last-10-nights-banner-desktop.jpg';
import bannerMobile from '../../images/ramadan/laylatulqadr/last-10-nights-banner-mobile.jpg';
import TabContent from './laylatulQadr/tab-content';
// import services from '../../../services';
import { useSelector } from 'react-redux';
import { selectProducts } from '../../store/products';
import { lastTenNightsFaqs } from './faq';
import CrisisWidget from '../crisis-and-emergencies/components/crisisWidget';
import SeoData from '../../utils/seo.json';
import { selectSelectedCountryCode } from '../../store/user';
import { Helmet } from 'react-helmet';
import RamadanPageTabs from './components/tabs';
import iftarMealsImg from '../../images/landings/ramadan/iftar-meals.jpg';
import feedOrphanImg from '../../images/landings/ramadan/feed-orphan.jpg';
import useHandleAddProduct from '../../utils/handleAddProduct';


const LaylatulQadr2024LandingPage = () => {
    /* -------------------------------- Variables ------------------------------- */
    const products = useSelector((state) => selectProducts(state));
    const [isLoaded, setIsLoaded] = useState(false);
    const selectedcountryCode = useSelector(selectSelectedCountryCode);
    const seo = SeoData[selectedcountryCode];
    const [foodAidProducts, setFoodAidProducts] = useState([0, 0, 0, 0]);
    const [zakatProducts, setZakatProducts] = useState([0, 0]);
    const [zakatAlFitrProducts, setZakatAlFitrProducts] = useState([0]);
    const [orphansProducts, setOrphansProducts] = useState([0, 0]);
    const [riceBagsProducts, setRiceBagsProducts] = useState([0]);
    const [addProduct] = useHandleAddProduct();
    const [feedAnOrphanProduct] = useState({
        title: 'Feed an Orphan For 30 Days',
        description: 'Help provide daily nutritious staple meals for an orphaned child every day this Ramadan!',
        img: feedOrphanImg,
        products: [],
        alt: 'Muslim Charity to feed an orphan'
    });
    const [iftarMealsProduct] = useState({
        title: 'Iftar Meals',
        description: 'Help us provide hot Iftar Meals to help break the fasts of those in need during the holy month of Ramadan',
        img: iftarMealsImg,
        products: [],
        alt: 'Muslim Charity to Iftar meals'
    });
    const addLaylatulQadrProduct = (product, currency) => {
        addProduct({product: product, currency: currency})
    };
    const [tabs, setTabs] = useState([
        {
            title: 'Most Needed',
            isActive: true,
            content: <TabContent
                contributes={['']}
                product=''
                addLaylatulQadrProduct={addLaylatulQadrProduct}
            />
        },
        // {
        //     title: 'Orphan Care',
        //     isActive: false,
        //     content: <TabContent
        //         contributes={['']}
        //         product=''
        //     />
        // },
        // {
        //     title: 'Food Aid',
        //     isActive: false,
        //     content: <TabContent
        //         contributes={['']}
        //         product=''
        //     />
        // },
        // {
        //     title: 'Water Aid',
        //     isActive: false,
        //     content: <TabContent
        //         contributes={['']}
        //         product=''
        //     />
        // }
    ]);
    /* -------------------------------------------------------------------------- */
    // useEffect(() => {
    //     console.log('SEO',seo)
    // },[])

    /* --------------------------------- Methods -------------------------------- */
    const handleActiveTab = (index) => {
        const newTabs = tabs.map((obj, inner_index) => {
            obj.isActive = false;
            if (inner_index === index) {
                return {
                    ...obj,
                    isActive: true
                }
            }
            return obj;
        })
        setTabs(newTabs);
    }
    const getAllProducts = () => {
        setIsLoaded(true);
        let mostNeeded = {};
        // let orphanCare = {};
        // let foodAid = {};
        // let waterAid = {};
        let tempFoodAidProducts = [];
        let tempIftarProducts = [];
        let tempZakateProducts = [];
        let tempZakatAlFitrProducts = [];
        let tempOrphansProducts = [];
        let tempRiceBagsProducts = [];
        let tempFeedOrphansProducts = [];
        let tempTabs = tabs;
        products.map(item => {
            item.sub_categories.map(inner_item => {
                inner_item.products.map(inner_item_product => {
                    if (inner_item_product.creator === 'SSB-WMN-GEN-003') { // Most Needed Fund
                        inner_item_product = Object.assign({}, inner_item_product, {
                            name: 'HELP THOSE MOST IN NEED THIS RAMADAN',
                            description: `Whether it be a famine, conflict or disaster, suffering is widespread in many parts of
                            the world. Our team is working day and night to reach those most in need. Maximise your rewards in
                            these blessed nights and help us rebuild and restore lives.`
                        })
                        mostNeeded = inner_item_product;

                    }
                    if (inner_item_product.creator === 'SSB-ORP-GEN-001') { // Orphan Care Fund
                        inner_item_product = Object.assign({}, inner_item_product, {
                            name: 'Orphan Care',
                            description: `Imagine if your children had nowhere
                            to sleep. Let's build lives, providing
                            shelter, food, water, and education.
                            Every day, thousands of children
                            become orphans. Join us in reaching
                            every orphan worldwide today. Make
                            a lasting impact with your daily
                            support— automate your donations
                            today!`,
                        })
                        // orphanCare = inner_item_product;


                    }
                    if (inner_item_product.creator === 'MKD-GEN-MFA-2023-01-118') { // food aid
                        inner_item_product = Object.assign({}, inner_item_product, {
                            description: `Over 820 million go to bed hungry. Imagine
                            living uncertain about your next meal. Your
                            support ensures children, families and
                            communities receive nutritious warm meals. Sign up for our daily donation today and make
                            a difference in ending hunger.`,
                            image_link: '/images/ramadan/food-aid-laylatul-qdr.jpeg'
                        })
                        // foodAid = inner_item_product;

                    }
                    if (inner_item_product.creator === 'MKD-GEN-MWA-2023-01-120') { // water aid
                        inner_item_product = Object.assign({}, inner_item_product, {
                            description: `Imagine living the rest of your life
                            uncertain about water. 2.2 billion lack safe
                            drinking water. Your daily donations
                            ensure a family doesn't face that reality. Sign up today, not tomorrow, to help provide clean water for families in need
                            and reap blessings this Ramadan.`,
                            image_link: '/images/ramadan/water-aid-laylatul-qdr.jpeg'
                        })
                        // waterAid = inner_item_product;

                    }
                    if (inner_item_product.creator === 'RM24-OP-014') tempOrphansProducts[1] = inner_item_product;
                    if (inner_item_product.creator === 'RM24-ZM-013') {
                        inner_item_product = Object.assign({}, inner_item_product, {
                            isStaticProduct: true,
                            staticLink: '/zakat',
                            staticLinkText: 'GIVE ZAKAT AL MAL',
                        });
                        tempZakateProducts[0] = inner_item_product;
                        tempZakateProducts[1] = Object.assign({}, inner_item_product, {
                            name: 'ZAKAT CALCULATOR',
                            image_link: '/images/zakat/zakat-cal.jpg',
                            isStaticProduct: true,
                            staticLink: '/zakat-calculator',
                            staticLinkText: 'CALCULATE YOUR ZAKAT',
                        });
                    }
                    if (inner_item_product.creator === 'RM24-015') {
                        inner_item_product = Object.assign({}, inner_item_product, {
                            isStaticProduct: true,
                            staticLink: '/zakat/zakat-al-fitr',
                            staticLinkText: 'Donate Now',
                        });
                        tempZakatAlFitrProducts[0] = inner_item_product;
                    }
                    if (inner_item_product.creator === 'RM24-FA-010') {
                        inner_item_product = Object.assign({}, inner_item_product, {
                            isStaticProduct: true,
                            staticLink: '/ramadan/fidya-kaffarah?product=kafarrah',
                            staticLinkText: 'CALCULATE YOUR KAFFARAH',
                        });
                        tempFoodAidProducts[2] = inner_item_product;
                    }
                    if (inner_item_product.creator === 'RM24-FA-011') {
                        inner_item_product = Object.assign({}, inner_item_product, {
                            isStaticProduct: true,
                            staticLink: '/ramadan/fidya-kaffarah?product=fidya',
                            staticLinkText: 'CALCULATE YOUR FIDYA',
                        });
                        tempFoodAidProducts[3] = inner_item_product;
                    }
                    return inner_item_product;
                });
                return inner_item;
            })
            return item;
        });
        // tempTabs[0].content = <TabContent
        //     // contributes={['Orphan Feast', 'Orphan Care', 'Orphan Centre']}
        //     product={mostNeeded}
        // />
        tempTabs[0].content = <TabContent addLaylatulQadrProduct={addLaylatulQadrProduct} product={mostNeeded} />;
        // tempTabs[1].content = <TabContent
        //     // contributes={['Mosque', 'Water Wells', 'Project Noor']}
        //     product={orphanCare}
        // />
        // tempTabs[2].content = <TabContent
        //     // contributes={['Food & Water Aid', 'Medical Aid', 'Shelter Aid']}
        //     product={foodAid}
        // />
        // tempTabs[3].content = <TabContent
        //     // contributes={['Food & Water Aid', 'Medical Aid', 'Shelter Aid']}
        //     product={waterAid}
        // />
        setTabs(tempTabs);
        tempOrphansProducts[0] = feedAnOrphanProduct;
        tempOrphansProducts[0].products = tempFeedOrphansProducts;
        tempFoodAidProducts[1] = iftarMealsProduct;
        tempFoodAidProducts[1].products = tempIftarProducts;

        tempFoodAidProducts = tempFoodAidProducts.filter(n => n)
        tempZakateProducts = tempZakateProducts.filter(n => n)
        tempZakatAlFitrProducts = tempZakatAlFitrProducts.filter(n => n)
        tempOrphansProducts = tempOrphansProducts.filter(n => n)
        tempRiceBagsProducts = tempRiceBagsProducts.filter(n => n)

        setFoodAidProducts(tempFoodAidProducts);
        setZakatProducts(tempZakateProducts);
        setZakatAlFitrProducts(tempZakatAlFitrProducts);
        setOrphansProducts(tempOrphansProducts);
        setRiceBagsProducts(tempRiceBagsProducts);
    };
    /* -------------------------------------------------------------------------- */

    /* ---------------------------------- Hooks --------------------------------- */
    useEffect(() => {
        products && Object.keys(products).length && !isLoaded && getAllProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products])
    /* -------------------------------------------------------------------------- */
    return (
        <Layout className='bg-white' childClasses={'pb-0'} hideFooter>
            {seo && (
                <Helmet>
                    <title>{`${seo['ramadan']['laylatulQadr2024']['title']}`}</title>
                    <meta name="title" content={`${seo['ramadan']['laylatulQadr2024']['title']}`} />
                </Helmet>
            )}
            {/* /* --------------------------------- Banner ---------------------------------  */}
            <section className="bg-[#DFEEF2] sticky top-16 z-30 md:hidden p-2 md:p-4">
                <CrisisWidget
                    ramadanMobileWidget
                    className={'bg-[#DFEEF2] h-auto'}
                    fixPrice={false}
                    defaultProduct={{
                        creator: 'MKD-MN-001',
                        label: 'Where Most Needed',
                        value: 122,
                    }}
                />
            </section>
            <section className="flex justify-center" >
                <img src={banner} alt="Laylatul Qadr 2024: Seek the signs of laylatul qadr in ramadan 2024" className='max-[500px]:hidden w-full' />
                <img src={bannerMobile} alt="Laylatul Qadr 2024: Seek the signs of laylatul qadr in ramadan 2024" className='min-[501px]:hidden w-full' />
            </section>
            {/* /* --------------------------------- Widget --------------------------------- */}
            <section className="bg-[#DFEEF2] hidden md:block sticky top-16 lg:top-24 z-30">
                <div className="max-w-[1160px] px-3 mx-auto">
                    <CrisisWidget
                        floating
                        className={'bg-[#DFEEF2] h-auto pt-10'}
                        fixPrice={false}
                        defaultProduct={{
                            creator: 'MKD-MN-001',
                            label: 'Where Most Needed',
                            value: 122,
                        }}
                    />
                </div>
            </section>
            {/* /* ------------------------------- Seek Last 10 nights ------------------------------- */}
            <section className="w-full hidden md:block md:container md:mx-auto px-8 md:px-0 py-4 md:py-16">
                <div className=" flex justify-center">
                    <h1 className="hidden md:block text-left sm:text-center font-medium text-[#253B7E] text-[25px] sm:text-[40px] w-full sm:w-9/12">
                        Laylatul Qadr 2024:<span className="text-[#F60362]"> Unveiling the Night of Power</span>
                    </h1>
                    <h1 className="block md:hidden text-left sm:text-center font-medium text-[#253B7E] text-[25px] sm:text-[40px] w-full sm:w-9/12">
                        Laylatul Qadr 2024:
                        <br />
                        <span className="text-[#F60362]">Unveiling the Night of Power</span>
                    </h1>
                </div>
                <div className="mt-6 md:mt-8 text-[#78716C] leading-7 md:text-[18px] font-montserratMedium space-y-4">
                    <p>
                        Laylatul Qadr, also known as the Night of Power, holds unparalleled significance in the Islamic calendar,
                        offering a profound opportunity for believers to seek the mercy and blessings of Allah. It marks the sacred
                        occasion when the first verses of the Quran were revealed to Prophet Muhammad (peace be upon him), symbolizing
                        a period of immense spiritual grace and peace.
                        <br />
                        <br />
                        This revered night falls within the final 10 days of Ramadan, with the 27th night traditionally regarded as
                        the most likely date. However, due to the night's profound significance, believers are encouraged to seek its
                        blessings in the last 10 odd nights of Ramadan, following the example set by the Prophet (peace be upon him).
                        <br />
                        <br />
                        As Angel Jibril revealed the Holy Quran's first verses to Prophet Muhammad (peace be upon him) on this night,
                        Laylatul Qadr represents a time of deep spiritual reflection and connection with the divine. It is a night when
                        sincere prayers are believed to be answered, sins forgiven, and hearts uplifted with the light of faith.
                    </p>
                </div>
            </section>
            {/* /* ------------------------------- Tab ------------------------------ */}
            <RamadanPageTabs
                riceBagProduct={riceBagsProducts}
                foodAidProducts={foodAidProducts}
                zakatProducts={zakatProducts}
                zakatAlFitrProducts={zakatAlFitrProducts}
                orphansProducts={orphansProducts}
            />
            {/* /* ------------------------------- Automate your donations ------------------------------- */}
            <section className="w-full bg-[#253B7E] px-4 md:px-0">
                <div className="w-full md:container md:mx-auto px-4 md:px-0 py-4 md:py-16">
                    <div className=" flex justify-center">
                        <h2 className="text-left sm:text-center font-medium text-white text-2xl sm:text-[40px] sm:leading-[50px] w-full sm:w-8/12">
                            Automate your donations! Maximise all the days of Ramadan, including the last 10 nights!
                        </h2>
                    </div>
                    <div className="mt-6 md:mt-8 flex justify-center flex-wrap gap-3">
                        <div className="basis-full md:basis-1/4 flex bg-white rounded-md p-3">
                            <div className="flex justify-center items-center min-w-[32px] w-8 h-8 bg-[#F60362] text-white rounded-full mr-2 md:mr-4">1</div>
                            <p className="text-[#78716C] text-sm">
                                Simply select your start date which could be any start date throughout the month of Ramadan.
                            </p>
                        </div>
                        <div className="basis-full md:basis-1/4 flex bg-white rounded-md p-3">
                            <div className="flex justify-center items-center min-w-[32px] w-8 h-8 bg-[#F60362] text-white rounded-full mr-2 md:mr-4">2</div>
                            <p className="text-[#78716C] text-sm">
                                Enter the amount you’d like to give  throughout Ramadan. <strong className='text-[#253B7E]'>This amount will be deducted daily.</strong>
                            </p>
                        </div>
                        {/* <div className="basis-full flex flex-col items-center text-white mt-8">
                            <h2 className="text-left sm:text-center font-medium text-[15px] sm:text-[18px] w-full sm:w-8/12 mb-4">
                                HELP THOSE MOST IN NEED THIS RAMADAN
                            </h2>
                            <p className="text-sm text-left sm:text-center w-full sm:w-8/12">
                                Whether it be a famine, conflict or disaster, suffering is widespread in many parts of the world. Our team is working day and night
                                to reach those most in need. Maximise your rewards in these blessed nights and help us rebuild and restore lives.
                            </p>
                            <p className="text-sm text-left sm:text-center w-full sm:w-8/12 mt-4">
                                Automating your donations gives you the ability to focus on your duas, your prayers and your remembrance of Allah SWT.
                                <br />
                                Our causes are Zakat applicable with a 100% donation policy. Bismillah - let’s begin!
                            </p>
                        </div> */}
                    </div>
                </div>
            </section>
            {/* /* ---------------------------------- Tabs ---------------------------------- */}
            <section className='md:container mx-auto px-4 md:px-0 mt-4 md:mt-6'>
                <div className="flex flex-wrap justify-center basis-full md:container md:mx-auto  py-2 md:py-4">
                    <div className="w-full flex flex-wrap justify-center md:container md:mx-auto md:hidden bg-[#01A0D8] rounded-t-[20px] max-[400px]:px-1- px-3 pt-3">
                        <div className="w-full grid grid-cols-4 gap-1">
                            {tabs.map((item, index) => {
                                return <div key={`tabs4_${index}`} className={`col-span-1 flex justify-center items-center`}>
                                    <div
                                        className={`max-[500px]:py-2 py-3 max-[500px]:px-1 max-[500px]:text-center max-[500px]:text-xs text-base md:text-lg cursor-pointer w-full flex justify-center items-center ${item.isActive ? 'rounded-full bg-white text-[#00a3da]' : '  bg-transparent text-white border-[#00a3da]'}`}
                                        onClick={() => handleActiveTab(index)}
                                    >
                                        {item.title}
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                    <div className="w-full flex flex-wrap justify-center md:container md:mx-auto bg-[#01A0D8] rounded-b-[20px] md:rounded-2xl p-3 sm:p-6 md:p-8">
                        <div className="w-full hidden md:grid grid-cols-4 gap-4 pb-4">
                            {tabs.map((item, index) => {
                                return <div
                                    key={`tabs2-${index}`}
                                    className={`py-4 text-lg md:text-[24px] cursor-pointer col-span-1 border-2 flex justify-center items-center rounded-2xl ${item.isActive ? ' rounded-xl bg-white text-[#00a3da]' : ' text-white bg-transparent'} ${index === 5 && !item.isActive ? '!border-r' : ''}`}
                                    onClick={() => handleActiveTab(index)}
                                >
                                    {item.title}
                                </div>
                            })}
                        </div>
                        <div className='flex w-full'>
                            {tabs.map((item, index) => {
                                if (item.isActive) {
                                    return <React.Fragment key={`content-${index}`}>{item.content}</React.Fragment>
                                } else {
                                    return null
                                }
                            })}
                        </div>
                    </div>
                </div>
            </section>
            {/* /* ------------------------------- Seek Last 10 nights ------------------------------- */}
            <section className="w-full md:hidden md:container md:mx-auto px-8 md:px-0 py-4 md:py-16">
                <div className=" flex justify-center">
                    <h2 className="hidden md:block text-left sm:text-center font-medium text-[#253B7E] text-[25px] sm:text-[40px] w-full sm:w-8/12">
                        Seek Laylatul Qadr in these<span className="text-[#F60362]"> last ten nights!</span>
                    </h2>
                    <h2 className="block md:hidden text-left sm:text-center font-medium text-[#253B7E] text-[25px] sm:text-[40px] w-full sm:w-8/12">
                        Seek Laylatul Qadr in these
                        <br />
                        <span className="text-[#F60362]"> last ten nights!</span>
                    </h2>
                </div>
                <div className="mt-6 md:mt-8 text-[#78716C] leading-7 md:text-[18px] font-montserratMedium space-y-4">
                    <p>The last ten nights of Ramadan are filled with the most blessings. It’s within these nights that we are encouraged to seek Laylatul Qadr,
                        which falls on the 27th night of Ramadan but could also fall on any of the odd nights.
                        <br />
                        <br />
                        The last ten nights of Ramadan are about seeking the Mercy and Forgiveness of Allah of Allah.
                        <br />
                        <br />
                        It’s during these days and nights that Allah shows great Mercy to His creation. This is our chance to benefit from the immense blessings of
                        Ramadan before this blessed month comes to an end!
                    </p>
                </div>
            </section>
            {/* /* ------------------------------- Text -------------------------------------------------- */}
            <section className="w-full bg-[#E1EFF2] px-4 md:px-0 py-4 md:py-8">
                <div className="w-full md:container md:mx-auto p-4 md:p-8 bg-white rounded-bl-3xl rounded-tr-3xl flex flex-col md:flex-row gap-3">
                    <div className="flex md:justify-center items-start px-2 md:px-4">
                        <svg width="59" height="43" viewBox="0 0 59 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.6207 0C13.62 0 14.6259 0 15.6251 0C16.4566 0.185345 17.3016 0.324353 18.113 0.575893C28.2526 3.68704 30.9686 15.1519 25.3288 22.9629C24.2625 24.4457 22.8609 25.571 21.0503 26.0476C19.6085 26.4249 18.7501 27.3317 18.2538 28.6424C17.5765 30.4561 17.9252 32.1772 18.8239 33.8188C19.9438 35.8576 21.6672 37.3271 23.6254 38.5252C24.866 39.2799 26.1871 39.8955 27.468 40.5773C28.1051 40.9149 28.3465 41.4841 28.1319 42.1064C27.9106 42.7485 27.3741 43.0794 26.6901 42.9868C23.0487 42.4969 19.5146 41.6298 16.1548 40.147C11.863 38.2538 8.15451 35.6127 5.25078 31.9323C2.21293 28.0797 0.543119 23.6977 0.113931 18.839C-0.17443 15.5756 0.0669881 12.3718 1.23384 9.2871C2.78295 5.20289 5.45196 2.15132 9.73043 0.701663C10.6626 0.383929 11.6551 0.231681 12.6207 0Z" fill="#14A2DC" />
                            <path d="M59 41.365V41.9607C58.5507 42.8213 58.155 43.0794 57.4509 42.9868C53.8095 42.4969 50.2754 41.6298 46.9157 40.147C42.6238 38.2538 38.9153 35.6127 36.0116 31.9323C32.9737 28.0797 31.2972 23.6977 30.868 18.839C30.5797 15.5756 30.8211 12.3718 31.9879 9.2871C33.5371 5.20289 36.2061 2.15794 40.4845 0.708282C41.4234 0.383929 42.4159 0.231681 43.3816 0C44.3808 0 45.3867 0 46.3859 0C46.4731 0.0397167 46.5602 0.0992919 46.6474 0.112531C52.5286 0.900246 57.129 4.95135 58.5641 10.6176C58.7519 11.3722 58.8592 12.1533 59.0067 12.9212V14.9335C58.9665 15.0857 58.9061 15.2314 58.8927 15.3902C58.7452 17.2768 58.269 19.0773 57.4308 20.7785C56.1432 23.3932 54.3192 25.432 51.3283 26.2197C50.8588 26.3454 50.3961 26.6897 50.0407 27.0339C49.1085 27.9275 48.7129 29.0925 48.6861 30.3502C48.6391 32.4817 49.5981 34.2292 50.9997 35.7649C52.8572 37.7905 55.1775 39.1607 57.6588 40.2993C58.2087 40.5508 58.725 40.8156 59.0134 41.3716L59 41.365Z" fill="#14A2DC" />
                        </svg>
                    </div>
                    <p className="text-[#14A2DC] text-[18px] md:text-[24px] leading-5 md:leading-8 italic text-center px-8 md:px-0 py-2 md:py-4">
                        All of the nights of Ramadan are blessed which is why we’re encouraged to maximise every opportunity for Ibadah. Don’t miss your
                        chance to make the most of this month. Be the vessel of Allah’s Mercy for someone in need and reap endless blessings and rewards
                        of your own Insha’Allah!
                    </p>
                    <div className="flex justify-end md:justify-center items-end px-2 md:px-4">
                        <svg width="59" height="43" viewBox="0 0 59 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M46.3793 43C45.38 43 44.3741 43 43.3749 43C42.5434 42.8147 41.6984 42.6756 40.887 42.4241C30.7474 39.313 28.0314 27.8481 33.6713 20.0371C34.7375 18.5543 36.1391 17.429 37.9497 16.9524C39.3915 16.5751 40.2499 15.6683 40.7462 14.3576C41.4235 12.5439 41.0748 10.8228 40.1761 9.18119C39.0562 7.14239 37.3328 5.67287 35.3746 4.47475C34.134 3.72013 32.8129 3.10452 31.532 2.42272C30.8949 2.08513 30.6535 1.51585 30.8681 0.893625C31.0894 0.251535 31.6259 -0.0794396 32.3099 0.0132347C35.9513 0.503076 39.4854 1.37023 42.8452 2.85298C47.137 4.74615 50.8455 7.38731 53.7492 11.0677C56.7871 14.9203 58.4569 19.3023 58.8861 24.161C59.1744 27.4244 58.933 30.6282 57.7662 33.7129C56.2171 37.7971 53.548 40.8487 49.2696 42.2983C48.3374 42.6161 47.3449 42.7683 46.3793 43Z" fill="#14A2DC" />
                            <path d="M2.26897e-05 1.63501L2.27418e-05 1.03925C0.449329 0.178728 0.84499 -0.0794358 1.54913 0.0132385C5.19052 0.50308 8.72462 1.37023 12.0844 2.85299C16.3762 4.74615 20.0847 7.38732 22.9884 11.0677C26.0263 14.9203 27.7028 19.3023 28.132 24.161C28.4203 27.4244 28.1789 30.6282 27.0121 33.7129C25.463 37.7971 22.7939 40.8421 18.5155 42.2917C17.5766 42.6161 16.5841 42.7683 15.6185 43C14.6192 43 13.6133 43 12.6141 43C12.527 42.9603 12.4398 42.9007 12.3526 42.8875C6.47138 42.0998 1.87101 38.0486 0.435914 32.3824C0.248145 31.6278 0.140851 30.8467 -0.00668222 30.0788L-0.00668204 28.0665C0.0335535 27.9143 0.0939077 27.7686 0.107318 27.6098C0.254852 25.7232 0.730983 23.9227 1.56924 22.2215C2.85681 19.6068 4.68086 17.568 7.67176 16.7803C8.14119 16.6546 8.60391 16.3103 8.95933 15.9661C9.89147 15.0725 10.2871 13.9075 10.314 12.6498C10.3609 10.5183 9.40193 8.77078 8.00036 7.23507C6.14278 5.20952 3.82248 3.83928 1.34124 2.70074C0.791338 2.4492 0.274973 2.18442 -0.0133879 1.62839L2.26897e-05 1.63501Z" fill="#14A2DC" />
                        </svg>
                    </div>
                </div>
            </section>
            {/* /* ------------------------------- Text -------------------------------------------------- */}
            <section className="w-full px-4 md:px-0 py-4 md:py-8">
                <div className="w-full md:container md:mx-auto p-4 md:p-8 bg-white rounded-bl-3xl rounded-tr-3xl flex flex-col gap-3">
                    <h2 className="font-medium text-[#253B7E] text-[18px] sm:text-[25px] w-full">
                        Seeking Laylatul Qadr – When Exactly Is It In 2024?
                    </h2>
                    <p className="text-[#78716C] text-[14px] md:text-[18px] leading-5 md:leading-8 px-8 md:px-0 py-2 md:py-4">
                        Traditionally sought during the last ten nights of this blessed month of Ramadan, Laylatul Qadr’s
                        presence is felt most deeply on the odd-numbered nights.
                        <br />
                        This year, in 2024, from the eve of April 5th, Muslims across the world
                        will turn their hearts and souls towards this night of unparalleled importance. Engaging fervently
                        in prayer, reflection, and acts of charity, they seek the divine blessings and mercy that Laylatul
                        Qadr bestows upon the faithful.
                    </p>
                    <h2 className="font-medium text-[#253B7E] text-[18px] sm:text-[25px] w-full">
                        The Signs of Laylatul Qadr - A Night of Divine Spiritual Illumination
                    </h2>
                    <p className="text-[#78716C] text-[14px] md:text-[18px] leading-5 md:leading-8 px-8 md:px-0 py-2 md:py-4">
                        The signs of Laylatul Qadr encompass a night of serene tranquility, where the soul finds peace and solace.
                        The temperature is mild, neither too hot nor too cold, and the sun rises without its usual intensity, casting
                        a gentle glow over a clear sky. This blessed night is neither fleeting nor overly prolonged, offering believers
                        a precious span of time to draw closer to their Creator, seeking forgiveness, mercy, and abundant blessings.
                    </p>
                    <h3 className="font-medium text-[#253B7E] text-[18px] sm:text-[25px] w-full">
                        The Transformative Impact of Giving on Laylatul Qadr
                    </h3>
                    <p className="text-[#78716C] text-[14px] md:text-[18px] leading-5 md:leading-8 px-8 md:px-0 py-2 md:py-4">
                        Laylatul Qadr holds a profound significance for acts of charity and giving. On this sacred night, the virtues of
                        giving are multiplied manifold, offering a unique opportunity to help those in need while purifying one's wealth
                        and drawing closer to Allah through selfless generosity. It is believed that donating on Laylatul Qadr brings
                        blessings equivalent to giving charity every day for a thousand months, highlighting the immense spiritual rewards
                        and transformative power of giving on this auspicious night.
                    </p>
                    <h3 className="font-medium text-[#253B7E] text-[18px] sm:text-[25px] w-full">
                        Illuminate the Night with Your Generosity: Laylatul Qadr Donation Drive
                    </h3>
                    <p className="text-[#78716C] text-[14px] md:text-[18px] leading-5 md:leading-8 px-8 md:px-0 py-2 md:py-4">
                        As Laylatul Qadr approaches, let us heed the call to action and become instruments of hope for those in need. Your donation,
                        no matter how big or small, can provide crucial aid to the less fortunate, support educational initiatives, and strengthen
                        our community. Whether you choose to feed the hungry, aid the sick, or support a cause dear to your heart, your contribution
                        on this sacred night can truly change lives.
                        <br />
                        <br />
                        Seize this opportunity to earn countless rewards by making your Laylatul Qadr donation today. Let us unite in this blessed
                        month to make a positive impact, seeking the pleasure of Allah and the betterment of our society.
                        <br />
                        <br />
                        Join us in commemorating the Night of Power with generous giving and heartfelt prayers, as we strive to embody the spirit of
                        this auspicious night.
                    </p>
                </div>
            </section>
            {/* /* --------------------------------- FAQS --------------------------------- */}
            <section className='xl:px-8 px-4 md:container mx-auto pb-4 md:pb-8'>
                <h4 className='text-[#253B7E] font-bold text-[25px] md:text-[40px] leading-7 md:leading-[50px] md:text-center my-4 md:my-8'>
                    Laylatul qadr 2024: Donate on Laylatul Qadr.<span className="text-[#F60362] ml-1">FAQs</span>
                </h4>
                <div className="flex flex-wrap">
                    {lastTenNightsFaqs.map((item, index) => {
                        return <div key={`faq_${index}`} className="basis-full md:basis-1/3 flex flex-col px-1 md:px-2 font-bold mb-6 md:mb-0">
                            <h4 className='text-[#253B7E] text-[18px] mb-2 md:mb-3'>
                                {item.question}
                            </h4>
                            <p className="text-[#78716C] text-base font-montserratMedium">{item.answer}</p>
                        </div>
                    })}
                </div>
            </section>
        </Layout>
    )
}

export default LaylatulQadr2024LandingPage
