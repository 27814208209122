import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import services from '../../services';
import { setUserIP, selectUserIP } from '../../store/user';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { isValidPhoneNumber, AsYouType } from 'libphonenumber-js';
import { BASKET_CACHE_KEY, HandleLocalStorage } from '../../utils/handleLocalStorage';
import { toast } from 'react-toastify';
// import { Base64 } from 'js-base64';
import HandleShowRecurring from '../../utils/showRecurringOnDataLayer';
import { sha256 } from 'js-sha256';
import ShowCurrencies from '../../utils/showCurrencies';
import CheckBox from '../general/checkbox';
import Select from 'react-select';
// import HandleShowAdminCost from '../../utils/handleAdminCost';
import { useNavigate } from 'react-router-dom';
import AddressField from './components/addressField';
import IsGTMScriptLoaded from '../../utils/isGTMScriptLoaded';
import { generateLinkWithLang } from '../../utils/linkGenerator';
import { useTranslation } from 'react-i18next';
// import { AdyenCheckout, Dropin, Card } from '@adyen/adyen-web';
import '@adyen/adyen-web/styles/adyen.css';
const styles = {
  menuList: base => ({
    ...base,
    '::-webkit-scrollbar': {
      width: '6px',
      height: '0px',
    },
    '::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#888',
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
    '& *': {
      cursor: 'pointer',
    },
  }),
};

const StepTwoWithLocalStorage = props => {
  const { isLaylatulQadr } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userIP = useSelector(selectUserIP);
  const [isLoading, setIsLoading] = useState(false);
  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const [phoneError, setPhoneError] = useState('');
  const [pastedValue, setPastedValue] = useState('');
  const [basketStates, setBasketStates] = useState({});
  const [countryCode, setCountryCode] = useState('AU');
  const [countryName, setCountryName] = useState('Australia');
  const [isConsentChecked, setIsConsentChecked] = useState(false);
  const [isUkTaxPayer, setIsUkTaxPayer] = useState(basketStates.currency === 'GBP' ? true : false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [userInfo, setUserInfo] = useState({
    name: basketStates.donor ? basketStates.donor.name : '',
    email: basketStates.donor ? basketStates.donor.email : '',
    phone: basketStates.donor ? basketStates.donor.phone : '',
    phone_country_code: basketStates.donor ? basketStates.donor.phone_country_code : '',
    currency: basketStates.currency ? basketStates.currency : 'AUD',
    referal: document.referrer,
    address: basketStates.donor ? basketStates.donor.address : '',
    city: basketStates.donor ? basketStates.donor.city : '',
    postal_code: basketStates.donor ? basketStates.donor.postal_code : '',
    isUkTaxPayer: basketStates.donor ? basketStates.donor.isUkTaxPayer : false,
    isRemember: basketStates.donor ? basketStates.donor.isRemember : true,
  });
  const { i18n } = useTranslation();
  const lang = i18n.language;
  useEffect(() => {
    let isBasketEmpty = true;
    for (let key in basketStates.donor) {
      if (basketStates.donor.hasOwnProperty(key)) {
        if (basketStates.donor[key] !== '') {
          isBasketEmpty = false; // If any field is not empty, return false
        }
      }
    }
    if (!isBasketEmpty) {
      if (userInfo.name && userInfo.phone && userInfo.phone_country_code && userInfo.email) {
        setIsLoaded(true);
        let formattedPhone = userInfo.phone;

        if (userInfo.phone_country_code === '44' && formattedPhone.startsWith('+44 0')) {
          formattedPhone = `+44 ${formattedPhone.slice(5)}`;
        }
        console.log('countryCode', countryCode);

        // Check if the phone number is valid
        const isValid = isValidPhoneNumber(formattedPhone, countryCode);

        setIsPhoneValid(isValid);
      }
    } else {
      setIsLoaded(true);
    }
  }, [userInfo, countryCode, basketStates]);
  // const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const postalCodeRegExp =
    /^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})|[A-Z]{1,2}\d{1,2}(?:\s?\d?[A-Z]{0,2})?$/;
  // eslint-disable-next-line no-useless-escape
  const emailRegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  // const emailRegExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const StepOneSchema = Yup.object().shape({
    name: Yup.string().min(3, 'Should be 3 character long').required('Enter your name'),
    // phone: Yup.string()
    //   .required('Enter your phone number')
    //   .test('isValid-phone', 'Phone number is not valid', value => isValidPhoneNumber(value)),
    email: Yup.string().matches(emailRegExp, 'Invalid email address').required('Enter your email address'),
    isUkTaxPayer: Yup.boolean(),
    address: Yup.string().when('isUkTaxPayer', {
      is: value => value === true,
      then: StepOneSchema => StepOneSchema.required('Enter your home address'),
      otherwise: StepOneSchema => StepOneSchema,
    }),
    city: Yup.string().when('isUkTaxPayer', {
      is: value => value === true,
      then: StepOneSchema => StepOneSchema.required('Enter your city'),
      otherwise: StepOneSchema => StepOneSchema,
    }),
    postal_code: Yup.string().when('isUkTaxPayer', {
      is: value => value === true,
      then: StepOneSchema => StepOneSchema.matches(postalCodeRegExp, 'Invalid postal code').required('Enter your postal code'),
      otherwise: StepOneSchema => StepOneSchema,
    }),
  });
  const notify = text =>
    toast.error(text, {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  const paymentsFlow = [
    { value: 'UK', label: 'I am a UK citizen' },
    { value: 'USD', label: 'I am a US Tax payer' },
    { value: 'AUD', label: 'I am an Australian citizen' },
    { value: 'OTHER', label: 'None of the above or remain private' },
  ];
  const [selectedPaymentFlow, setSelectedPaymentFlow] = useState(paymentsFlow[3]);

  const handleSubmit = values => {
    if (!isPhoneValid) {
      notify('Please enter a valid phone number.');
      return;
    }

    setIsLoading(true);
    createUser(values);
  };
  const createUser = async values => {
    let tempBasket = basketStates;
    if (isLaylatulQadr) {
      let tempTotalProducts = 0;
      tempBasket.adminCost = 0;
      tempBasket.products.map(item => {
        item.product ? (tempTotalProducts += item.total) : (tempTotalProducts = 0);
        return null;
      });
      tempBasket.grandTotal = tempTotalProducts;
    }
    try {
      let tempUserIP = userIP;
      if (userIP === '') {
        fetch('https://pro.ip-api.com/json?key=04S31DLsvcX7pze')
          .then(res => res.json())
          .then(response => {
            dispatch(setUserIP(response.query));
            tempUserIP = response.query;
            setCountryCode(response.countryCode);
            setCountryName(response.country);
          })
          .catch((data, status) => {
            console.log('Request failed:', data);
          });
      }
      let tempUser = {
        name: values.name,
        email: values.email,
        phone: values.phone,
        phone_country_code: userInfo.phone_country_code,
        currency: basketStates.currency,
        referal: document.referrer,
        host: window.location.protocol + '//' + window.location.host,
        ip: tempUserIP,
        acceptSms: isConsentChecked,
        isGiftAid: isUkTaxPayer,
        grandTotal: tempBasket.grandTotal,
        address: userInfo.address,
        city: userInfo.city,
        postal_code: userInfo.postal_code,
        // country_code: selectedPaymentFlow.value,
        // country: userInfo.country || 'United Kingdom',
        country_code: selectedPaymentFlow.value === 'UK' ? 'UK' : countryCode,
        country: selectedPaymentFlow.value === 'UK' ? 'United Kingdom' : countryName,
        payment_flow: selectedPaymentFlow.value,
      };
      const response = await services.createNewUser(tempUser);
      tempBasket.user = response.data.user;
      console.log('userinfo phone_country_code=', userInfo);
      tempBasket.donor = {
        name: values.name,
        email: values.email,
        phone: values.phone,
        phone_country_code: userInfo.phone_country_code,
        countryCode: countryCode,
        country: values.country ? values.country : countryName,
        address: values.address,
        city: values.city,
        postal_code: values.postal_code,
        isUkTaxPayer: values.isUkTaxPayer,
        isRemember: values.isRemember,
      };
      tempBasket.stripe_customer_id = response.data.user.id;
      tempBasket.paymentFlow = selectedPaymentFlow.value;
      HandleLocalStorage(tempBasket);
      handleShippingInfoDataLayer(tempUser, response.data.user);
      setIsLoading(false);
      response.data.user.id && props.nextStep();
    } catch (error) {
      setIsLoading(false);
      notify('Something went wrong! please try later');
      console.error('error========', error);
    }
  };
  const handleOnPaste = event => {
    const pastedData = event.clipboardData.getData('Text');
    setPastedValue(pastedData);
  };
  const handlePhoneValidation = (value, country, setFieldValue) => {
    // Ensure the input value has the '+' prefix
    let inputValue = value.startsWith('+') ? value : `+${value}`;

    // Create an instance of AsYouType to format the phone number
    const asYouType = new AsYouType(country.iso2);
    let formattedPhone = asYouType.input(inputValue);

    if (country.dialCode === '44' && formattedPhone.startsWith('+44 0')) {
      formattedPhone = `+44 ${formattedPhone.slice(5)}`;
    }

    // Check if the phone number is valid
    const isValid = isValidPhoneNumber(formattedPhone, country.iso2);

    console.log('formattedPhone', formattedPhone);

    setIsPhoneValid(isValid);
    if (isValid) {
      // If the country is UK and the phone starts with zero after country code, remove it
      setUserInfo(prevState => ({
        ...prevState,
        phone: formattedPhone,
        phone_country_code: country.dialCode,
      }));
      setFieldValue('phone', formattedPhone);
      setPastedValue('');
      setPhoneError('');
    } else {
      setUserInfo(prevState => ({
        ...prevState,
        phone: formattedPhone,
        phone_country_code: country.dialCode,
      }));
      setFieldValue('phone', formattedPhone);
      setPastedValue('');
      setPhoneError('Invalid phone number. Please check and try again.');
    }
  };
  const handleInputsChange = (value, field) => {
    let tempBasket = basketStates;
    tempBasket.donor ? (tempBasket.donor[field] = value) : HandleLocalStorage(tempBasket);
  };
  const handleShippingInfoDataLayer = (userinfo, createdUser) => {
    let tempItems = basketStates.products.map(item => {
      return {
        item_id: item.product.creator,
        item_name: item.product.name,
        item_brand: item.product.category || '',
        item_category: item.product.category || '',
        item_description: item.product.description || '',
        price: item.amount,
        quantity: item.quantity,
      };
    });
    let dataLayerTemp = {
      event: 'add_shipping_info',
      name: userinfo.name,
      email: userinfo.email,
      phone: userinfo.phone,
      user_email_hashed: sha256(userinfo.email),
      pay_id: createdUser.id,
      frequency: HandleShowRecurring(basketStates.recurring),
      ecommerce: {
        value: basketStates.grandTotal,
        tax: basketStates.adminCost,
        currency: basketStates.currency.toUpperCase(),
        items: tempItems,
      },
    };
    window.dataLayer = window.dataLayer || [];
    IsGTMScriptLoaded() && window.dataLayer.push(dataLayerTemp);
  };
  const handlePaymentFlow = e => {
    setIsUkTaxPayer(false);
    setUserInfo({
      ...userInfo,
      isUkTaxPayer: false,
    });
    setSelectedPaymentFlow({ ...selectedPaymentFlow, value: e.value, label: e.label });
    console.log(selectedPaymentFlow);
  };

  const handleChildData = data => {
    setUserInfo({
      ...userInfo,
      city: data.locality,
      postal_code: data.postalCode,
    });
  };
  const getProductByCode = async (creator, currency, basketStates) => {
    const temp = { product: creator, currency: currency };
    try {
      const response = await services.getProduct(temp);
      let tempBasket = basketStates;
      let tempProduct = tempBasket.products[0];
      tempProduct.product.paypal_plans = response.data.paypal_plans;
      tempBasket.products[0] = tempProduct;
      setBasketStates(tempBasket); // Add new record to userProducts
      HandleLocalStorage(tempBasket);
    } catch (error) {
      toast.error("The product didn't find!");
    }
  };
  /* -------------------------------------------------------------------------- */

  /* ---------------------------------- Hooks --------------------------------- */
  useEffect(() => {
    if (Object.keys(basketStates).length !== 0) {
      setUserInfo({
        ...userInfo,
        name: basketStates.donor ? basketStates.donor.name : '',
        email: basketStates.donor ? basketStates.donor.email : '',
        phone: basketStates.donor ? basketStates.donor.phone : '',
        phone_country_code: basketStates.donor ? basketStates.donor.phone_country_code : '',
        currency: basketStates.currency ? basketStates.currency : 'AUD',
        country: basketStates.donor ? basketStates.donor.country : '',
        address: basketStates.donor ? basketStates.donor.address : '',
        city: basketStates.donor ? basketStates.donor.city : '',
        postal_code: basketStates.donor ? basketStates.donor.postal_code : '',
        isUkTaxPayer: basketStates.donor.postal_code ? true : false,
        isRemember: basketStates.donor ? basketStates.donor.isRemember : false,
      });
      let tempPaymentFlow = basketStates.paymentFlow ? basketStates.paymentFlow : 'OTHER';
      if (!basketStates.paymentFlow) {
        fetch('https://pro.ip-api.com/json?key=04S31DLsvcX7pze')
          .then(res => res.json())
          .then(response => {
            switch (response.countryCode) {
              case 'AU':
                tempPaymentFlow = 'AUD';
                break;
              case 'US':
                tempPaymentFlow = 'USD';
                break;
              default:
                tempPaymentFlow = 'OTHER';
            }
            if (response.timezone.includes('Europe')) {
              if (response.countryCode === 'GB') {
                tempPaymentFlow = 'UK';
              } else {
                tempPaymentFlow = 'OTHER';
              }
            }
            paymentsFlow.map(item => {
              if (item.value === tempPaymentFlow) {
                setSelectedPaymentFlow(item);
              }
              return item;
            });
          })
          .catch(data => {
            console.log('Request failed:', data);
          });
      } else {
        paymentsFlow.map(item => {
          if (item.value === tempPaymentFlow) {
            setSelectedPaymentFlow(item);
          }
          return item;
        });
      }
      if (basketStates.paymentFlow) {
        tempPaymentFlow = basketStates.paymentFlow;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basketStates]);
  useEffect(() => {
    if (userIP === '') {
      fetch('https://pro.ip-api.com/json?key=04S31DLsvcX7pze')
        .then(res => res.json())
        .then(response => {
          dispatch(setUserIP(response.query));
          setCountryCode(response.countryCode);
          setCountryName(response.country);
        })
        .catch((data, status) => {
          console.log('Request failed:', data);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userIP]);
  useEffect(() => {
    const basketStatesFromLocalStorage = JSON.parse(localStorage.getItem(BASKET_CACHE_KEY));
    if (basketStatesFromLocalStorage) {
      if (
        basketStatesFromLocalStorage.products.length === 1 &&
        !basketStatesFromLocalStorage.adminCost &&
        basketStatesFromLocalStorage.mode === 'subscription'
      ) {
        getProductByCode(
          basketStatesFromLocalStorage.products[0].product.creator,
          basketStatesFromLocalStorage.currency,
          basketStatesFromLocalStorage,
        );
      }
      setBasketStates(basketStatesFromLocalStorage);
    }
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    if (!basketStates.donor) {
      fetch('https://pro.ip-api.com/json?key=04S31DLsvcX7pze')
        .then(res => res.json())
        .then(response => {
          setCountryCode(response.countryCode);
          setCountryName(response.country);
        })
        .catch((data, status) => {
          console.log('Request failed:', data);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /* -------------------------------------------------------------------------- */
  return (
    <div className="w-full">
      <div className="content p-3 md:p-5">
        <Formik
          enableReinitialize
          initialValues={{
            name: userInfo.name,
            email: userInfo.email,
            phone: userInfo.phone,
            address: userInfo.address,
            city: userInfo.city,
            country: userInfo.country,
            postal_code: userInfo.postal_code,
            isUkTaxPayer: userInfo.isUkTaxPayer,
            isRemember: userInfo.isRemember,
          }}
          validationSchema={StepOneSchema}
          onSubmit={values => {
            handleSubmit(values);
          }}
        >
          {({ errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
            <form onSubmit={handleSubmit} id="user_info_form">
              <div className="grid sm:grid-cols-4 md:grid-cols-3 gap-4">
                {isLoaded ? (
                  <>
                    <div className="col-span-3 sm:col-span-2 md:col-span-1">
                      <label className="text-sm md:text-md text-[#777]">
                        Name<small className="text-[#f60362] text-lg leading-4 ml-1">*</small>
                        <input
                          type="text"
                          name="name"
                          id="name"
                          placeholder="Enter your name"
                          className="min-h-[40px] md:min-h-[48px] w-full text-base md:text-md rounded border border-stone-300 bg-transparent py-[0.32rem] px-3 outline-none transition-all duration-200 ease-linear"
                          onBlur={handleBlur}
                          value={userInfo.name}
                          onChange={e => {
                            handleChange(e);
                            handleInputsChange(e.target.value, 'name');
                            setUserInfo({
                              ...userInfo,
                              name: e.target.value,
                            });
                          }}
                        />
                        {errors.name && touched.name && (
                          <small id="name-error" className="text-[#f60362] text-sm">
                            {errors.name}
                          </small>
                        )}
                      </label>
                    </div>
                    <div className="col-span-3 sm:col-span-2 md:col-span-1 z-[11]">
                      <label className="text-sm md:text-md text-[#777] w-full">
                        Phone<small className="text-[#f60362] text-lg leading-4 ml-1">*</small>
                        <PhoneInput
                          name="phone"
                          className="min-h-[40px] md:min-h-[48px] w-full text-base md:text-md [&>input]:!w-full"
                          id="phone"
                          placeholder="Enter your phone number"
                          onBlur={val => {
                            let e = { target: { value: val, name: 'phone', id: 'phone' } };
                            handleBlur(e);
                          }}
                          inputClass={'!w-full'}
                          country={
                            basketStates.donor && basketStates.donor.countryCode
                              ? basketStates.donor.countryCode.toLowerCase()
                              : countryCode.toLowerCase()
                          }
                          value={userInfo.phone}
                          onChange={(val, country) => {
                            let e = { target: { value: val, name: 'phone', id: 'phone' } };
                            handlePhoneValidation(
                              pastedValue && pastedValue.length > 0
                                ? pastedValue.startsWith(`+${country.dialCode}`)
                                  ? pastedValue
                                  : `${country.dialCode}${pastedValue}`
                                : val,
                              country,
                              setFieldValue,
                            );
                            handleChange(e);
                          }}
                          inputProps={{
                            onPaste: handleOnPaste,
                          }}
                        />
                        {(phoneError || errors.phone) && (
                          <small id="phone-error" className="text-[#f60362] text-sm">
                            {phoneError ? phoneError : errors.phone}
                          </small>
                        )}
                      </label>
                    </div>
                    <div className="col-span-3 sm:col-span-2 md:col-span-1">
                      <label className="text-sm md:text-md text-[#777]">
                        Email<small className="text-[#f60362] text-lg leading-4 ml-1">*</small>
                        <input
                          type="text"
                          name="email"
                          id="email"
                          placeholder="Enter your email"
                          className="min-h-[40px] md:min-h-[48px] w-full text-base md:text-md rounded border border-stone-300 bg-transparent py-[0.32rem] px-3 outline-none transition-all duration-200 ease-linear"
                          onBlur={handleBlur}
                          value={userInfo.email}
                          onChange={e => {
                            handleChange(e);
                            handleInputsChange(e.target.value, 'email');
                            setUserInfo({
                              ...userInfo,
                              email: e.target.value,
                            });
                          }}
                        />
                        {errors.email && touched.email && (
                          <small id="email-error" className="text-[#f60362] text-sm">
                            {errors.email}
                          </small>
                        )}
                      </label>
                    </div>
                  </>
                ) : (
                  ''
                )}
                <div className="col-span-3 flex items-center mt-2">
                  <CheckBox name="consentcheckbox" value={isConsentChecked} checked={isConsentChecked} setter={e => setIsConsentChecked(e)}>
                    <span>
                      I agree to receive WhatsApp/SMS text messages and SMS data rates may apply. Reply STOP to cancel,{' '}
                      <a className="text-[#00a3da] pl-1" target={'_blank'} href="/privacypolicy/">
                        {' '}
                        See Privacy Policy.
                      </a>
                    </span>
                  </CheckBox>
                </div>
                <div className="col-span-3 sm:col-span-4 flex flex-wrap items-center mt-2 hidden-">
                  <div className="basis-full flex flex-col mb-3 pr-2 z-10">
                    <label htmlFor="paymentsFlow" className="text-[#78716C]">
                      Your residency or tax status:
                    </label>
                    <Select
                      options={paymentsFlow}
                      placeholder="Select Payment Flow"
                      value={selectedPaymentFlow}
                      styles={styles}
                      className="!text-[#78716C]"
                      name="paymentsFlow"
                      onChange={e => {
                        handlePaymentFlow(e);
                      }}
                    />
                  </div>
                  {selectedPaymentFlow.value === 'UK' && (
                    <>
                      <p className="text-[#f60362] text-base basis-full font-medium mb-4">
                        Are you a UK taxpayer? Add an additional 25p for every £1 you donate for free.
                      </p>
                      <div className="flex items-center">
                        <CheckBox
                          name="isUkTaxPayer"
                          value={userInfo.isUkTaxPayer}
                          checked={userInfo.isUkTaxPayer}
                          className="mt-1"
                          setter={val => {
                            let e = { target: { value: val, name: 'isUkTaxPayer', id: 'isUkTaxPayer' } };
                            handleChange(e);
                            setUserInfo({
                              ...userInfo,
                              isUkTaxPayer: val,
                            });
                            setIsUkTaxPayer(val);
                            console.log('uk=', val);
                          }}
                          label={`I am a UK tax payer (we’ll claim gift aid on your behalf)`}
                        />
                      </div>
                      {isUkTaxPayer && (
                        <div className={`w-full flex flex-wrap items-center`}>
                          <div className="basis-full flex flex-col items-center justify-center my-6">
                            <div className="flex border-2 border-dotted border-stone-300 rounded-md w-full md:w-auto py-4 md:py-8 px-2 md:px-4 relative">
                              <div className="flex rounded-full bg-[#71DAFF] text-white justify-center items-center mr-2 md:mr-6 text-sm md:text-base text-center px-1 md:px-0 py-1 md:py-2 w-1/3 md:w-[170px] z-[1]">
                                {ShowCurrencies(basketStates.currency, basketStates.grandTotal, false)}
                              </div>
                              <div className="flex rounded-full bg-[#16C4FF] text-white justify-center items-center mr-2 md:mr-6 text-sm md:text-base text-center max-[537px]:flex-col px-1 md:px-0 py-1 md:py-2 w-1/3 md:w-[170px] z-[1]">
                                +25% Free <span className="ml-1">Gift Aid</span>
                              </div>
                              <div className="flex rounded-full bg-[#00A3DA] text-white justify-center items-center text-sm md:text-base text-center px-1 md:px-0 py-1 md:py-2 w-1/3 md:w-[170px] z-[1]">
                                {ShowCurrencies(basketStates.currency, basketStates.grandTotal + basketStates.grandTotal * 0.25, false)}
                              </div>
                              <div className="border-t-2 border-dotted border-[#00A3DA] w-[90%] h-[1px] absolute left-0 right-0 mr-auto ml-auto top-1/2 z-[0]" />
                            </div>
                          </div>
                          <div className="basis-full grid grid-cols-6 gap-2">
                            <div className="col-span-6 lg:col-span-3 md:min-h-[96px]">
                              <AddressField
                                name="address"
                                label="Home Address"
                                defaultValue={userInfo.address}
                                placeholder={'Enter your home address'}
                                setFieldValue={setFieldValue}
                                errors={errors.address}
                                handleBlur={handleBlur}
                                handleChange={e => {
                                  handleChange(e);
                                  setFieldValue('address', e.target.value);
                                  setUserInfo({
                                    ...userInfo,
                                    address: e.target.value,
                                  });
                                }}
                                touched={touched.address}
                                cityName="city"
                                onData={handleChildData}
                              />
                            </div>
                            <div className="col-span-6 md:col-span-3 md:min-h-[96px]">
                              <AddressField
                                name="city"
                                label="City"
                                defaultValue={userInfo.city}
                                placeholder={'Enter your city'}
                                types={['locality']}
                                setFieldValue={setFieldValue}
                                errors={errors.city}
                                handleBlur={handleBlur}
                                handleChange={e => {
                                  handleChange(e);
                                  setFieldValue('city', e.target.value);
                                  setUserInfo({
                                    ...userInfo,
                                    city: e.target.value,
                                  });
                                }}
                                touched={touched.city}
                                value={userInfo.city}
                              />
                            </div>
                            <div className="col-span-6 md:col-span-3 md:min-h-[96px]">
                              <label className="text-sm md:text-md text-[#777]">
                                Postal Code<small className="text-[#f60362] text-lg leading-4 ml-1">*</small>
                                <input
                                  type="text"
                                  name="postal_code"
                                  id="postal_code"
                                  placeholder="Enter your postal code"
                                  className="min-h-[40px] md:min-h-[48px] w-full text-base md:text-md rounded border border-stone-300 bg-transparent py-[0.32rem] px-3 outline-none transition-all duration-200 ease-linear"
                                  onBlur={handleBlur}
                                  value={userInfo.postal_code}
                                  onChange={e => {
                                    handleChange(e);
                                    setUserInfo({
                                      ...userInfo,
                                      postal_code: e.target.value,
                                    });
                                  }}
                                />
                                {errors.postal_code && touched.postal_code && (
                                  <small id="postal_code-error" className="text-[#f60362] text-sm">
                                    {errors.postal_code}
                                  </small>
                                )}
                              </label>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
                <div className="col-span-3 flex items-center mt-2">
                  <CheckBox
                    name="isRemember"
                    value={userInfo.isRemember}
                    checked={userInfo.isRemember}
                    setter={val => {
                      let e = { target: { value: val, name: 'isRemember', id: 'isRemember' } };
                      handleChange(e);
                      setUserInfo({
                        ...userInfo,
                        isRemember: val,
                      });
                    }}
                  >
                    <span>Remember my details</span>
                  </CheckBox>
                </div>
              </div>
              <div className="grid grid-cols-6 mt-8 gap-2 md:gap-4">
                <div className="col-start-1 col-span-2 md:col-span-1">
                  <button
                    type="button"
                    className="w-full flex items-center justify-center rounded px-6 pt-2 pb-2 font-normal md:text-lg md:h-[48px] transition duration-200 bg-[#00a3da] text-white sm:px-2 hover:bg-[#f60362] focus:outline-none focus:ring-0"
                    onClick={() => {
                      if (isLaylatulQadr) {
                        navigate(generateLinkWithLang('/ramadan/last-10-nights', lang));
                      } else {
                        props.backStep();
                      }
                    }}
                  >
                    Back
                  </button>
                </div>
                <div className="col-span-4 sm:col-span-3 sm:col-start-4 md:col-start-5 md:col-span-2">
                  <button
                    type="submit"
                    disabled={isLoading}
                    className={`w-full flex items-center justify-center rounded px-6 pt-2 pb-2 font-normal md:text-lg md:h-[48px] transition duration-150 ease-in-out bg-[#f60362] text-white focus:bg-[#00a3da] focus:outline-none focus:ring-0 active:bg-[#00a3da] hover:bg-[#00a3da]`}
                  >
                    {isLoading ? (
                      <>
                        Processing...
                        <svg className="animate-spin ml-4 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      </>
                    ) : (
                      <>
                        <span>Next</span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="group-hover:ml-2 h-5 ml-1 transition-all duration-150 ease-in"
                        >
                          <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
                        </svg>
                      </>
                    )}
                  </button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default StepTwoWithLocalStorage;
