import React, { useEffect, useState } from 'react';
import Layout from '../../components/general/layout';
import { selectSelectedCountryCode, selectSelectedCurrency } from '../../store/user';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getProducts } from '../../store/products';
import { useKeenSlider } from 'keen-slider/react';
import KaffarahProduct from '../../images/fidya-kaffarah/kaf-product.jpg';
import FidyaProduct from '../../images/fidya-kaffarah/fid-product.jpg';
import Widget from './widget';
import { OurPromiseSection } from '../../components/general';
import { Helmet } from 'react-helmet';
import SeoData from '../../utils/seo.json';
import banner from '../../images/fidya-kaffarah/fidya-kaffarah-hero-banner.jpg';
import bannerMobile from '../../images/fidya-kaffarah/fidya-kaffarah-mobile-banner.jpg';
import YoutubeVideoHero from '../../components/general/youtubeVideoHero';
import ImageCarousel1 from '../../images/fidya-kaffarah/fidya-kaffrah-uat-1.jpg';
import ImageCarousel2 from '../../images/fidya-kaffarah/fidya-kaffrah-uat-2.jpg';
import ImageCarousel3 from '../../images/fidya-kaffarah/fidya-kaffrah-uat-3.jpg';
import ImageCarousel4 from '../../images/fidya-kaffarah/fidya-kaffrah-uat-4.jpg';
import ImageCarousel5 from '../../images/fidya-kaffarah/fidya-kaffrah-uat-5.jpg';

const images = [
  { src: ImageCarousel1, alt: 'Kafarrah Calculator 2024, calculate your kafarrah this ramadan' },
  { src: ImageCarousel2, alt: 'Kaffarah for breaking fast, how much?' },
  { src: ImageCarousel3, alt: 'Kaffarah donation for breaking fast in ramadan' },
  { src: ImageCarousel4, alt: 'how to pay kaffarah for breaking fast' },
  { src: ImageCarousel5, alt: 'kaffarah calculator: what is kaffarah?' },
];

const ShowMoreLessComponent = ({ image, name, desc, btnText }) => {
  const [showMoreButton, setShowMoreButton] = useState(true);

  const toggleContent = () => {
    setShowMoreButton(!showMoreButton);
  };

  return (
    <div className="w-full p-2 md:p-3 md:pb-5 rounded-xl shadow-lg bg-white   xl:min-h-[600px]">
      <div className="overflow-hidden img-hover-zoom rounded-md  w-full  tooltip" onClick={() => window.scrollTo(0, 500)}>
        <img className="w-full" src={image} alt="" />
      </div>
      <div className="text-2xl text-[#14A2DC] font-bold text-center my-6"> {name}</div>
      <div>
        <p className={`font-montserratMedium text-[#78716C] text-[16px] px-6 ${showMoreButton ? 'line-clamp-3' : ''}`}>{desc}</p>
      </div>
      <div className="flex flex-col items-center justify-center pb-4">
        {desc?.length > 174 && (
          <button onClick={toggleContent} className="mt-2 text-blue-500  text-sm  hover:underline cursor-pointer">
            {showMoreButton ? 'Show More' : 'Show Less'}
          </button>
        )}
        <button onClick={() => window.scrollTo(0, 500)} className=" bg-[#F60362] mt-6 rounded-full text-white font-montserratMedium w-[230px] h-[50px]">
          {btnText}
        </button>
      </div>
    </div>
  );
};

const kaffarh = {
    creator: 'RM24-FA-010',
    label: 'Kaffarah',
    value: 347,
  },
  fidya = {
    creator: 'RM24-FA-011',
    label: 'Fidya',
    value: 348,
  };
const KaffarahLandingPage = () => {
  /* ----------------------------- Start variables ---------------------------- */
  const selectedcountryCode = useSelector(selectSelectedCountryCode);
  const seo = SeoData[selectedcountryCode];
  const [selectedCurrency, setSelectedCurrency] = useState();
  const selectedCurrencyStore = useSelector(selectSelectedCurrency);
  const [ramadanProducts, setRamadanProducts] = useState([0, 0]);
  const [isLoaded, setIsLoaded] = useState(false);
  const products = useSelector(state => getProducts(state, 'Ramadan', 'General'));
  const location = useLocation();
  const [loaded, setLoaded] = useState(false);
  const [currentSlideImages, setCurrentSlideImages] = useState(0);
  const [sliderRefImages, instanceRefImages] = useKeenSlider({
    mode: 'free-snap',
    slideChanged(slider) {
      setCurrentSlideImages(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
    slides: {
      origin: 'auto',
      perView: 1,
      spacing: 8,
    },
  });
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Start methods ----------------------------- */
  const getAllProducts = () => {
    setIsLoaded(true);
    let tempProductsList = [];

    let p = products.subCategory.products;
    p.forEach(item => {
      if (item.creator === 'RM24-FA-010') {
        tempProductsList[0] = { ...item };
        tempProductsList[0].image = KaffarahProduct;
        tempProductsList[0].btnText = 'Pay your Kaffarah here';
      }
      if (item.creator === 'RM24-FA-011') {
        tempProductsList[1] = { ...item };
        tempProductsList[1].image = FidyaProduct;
        tempProductsList[1].btnText = 'Pay your Fidya here';
      }
    });
    tempProductsList = tempProductsList.filter(n => n)
    setRamadanProducts(tempProductsList);
  };

  /* -------------------------------------------------------------------------- */
  /* ------------------------------- Start hooks ------------------------------ */
  useEffect(() => {
    selectedCurrencyStore ? setSelectedCurrency(selectedCurrencyStore) : setSelectedCurrency('AUD');
  }, [selectedCurrencyStore]);
  useEffect(() => {
    products && Object.keys(products).length && !isLoaded && getAllProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  /* -------------------------------------------------------------------------- */

  return (
    <Layout className="bg-white" hideFooter>
      {seo && (
        <Helmet>
          <title>{`${seo['kaffara']['title']}`}</title>
          <meta name="title" content={`${seo['kaffara']['title']}`} />
        </Helmet>
      )}

      {/* /* --------------------------------- Banner ---------------------------------  */}

      <section className="flex justify-center">
        <img src={banner} alt="Kaffarah Calculator 2024" className="max-[500px]:hidden w-full" />
        <img src={bannerMobile} alt="Kaffarah Calculator 2024" className="min-[501px]:hidden w-full" />
      </section>

      {/* /* --------------------------------- Widget --------------------------------- */}

      {/* <div className='flex justify-center'><img src={HeroBanner} alt="hero-banner"/></div> */}
      <section className="bg-[#E1EFF2]">
        <section className="max-w-[1440px] mx-auto">
          <div className="w-full  lg:mx-auto  xl:px-10 py-3 sm:flex justify-center items-center">
            <Widget
              className={'bg-[#DFEEF2] px-6 h-auto pt-10'}
              defaultProduct={location?.state?.productName === 'Fidya' ? fidya : kaffarh}
              selectedCurrency={selectedCurrency}
            />
          </div>
        </section>
      </section>
      <OurPromiseSection />
      <main className="max-w-[1440px] mx-auto">
        <section className="xl:px-20 mb-8 sm:mt-8 sm:mb-16">
          <div className="relative overflow-hidden flex flex-wrap rounded">
            <div
              className="order-2 z-10 sm:order-1 bg-[#F60362] flex flex-col items-center md:items-start justify-center w-full md:max-w-[315px] min-h-[300px]
                        md:min-h-[300px] lg:min-h-[305px] md:top-1/2 md:-translate-y-1/2
                        right-16 bg-no-repeat bg-cover bg-center md:rounded-2xl md:absolute  "
            >
              <div className="leading-[50px] pt-8 w-full text-[30px] sm:text-[50px] md:text-[30px] lg:text-[50px] px-8 text-white text-center font-gotcha">
                Fidya and Kaffarah
              </div>
              <p className="text-[18px] sm:text-[18px] font-montserratMedium leading-6 mt-3 px-10 text-white text-center">
                Make sure to give your Fidya and Kaffarah for your missed or broken fasts.
                <br />
                <br />
              </p>
            </div>
            <div className="hidden sm:block absolute w-full h-full bg-[#00000085]" />
            <YoutubeVideoHero src={`https://www.youtube.com/embed/bC7xyV8_r10?controls=0&autoplay=1&loop=1&mute=1&playlist=bC7xyV8_r10`} />
          </div>
        </section>
        <section className="xl:px-20">
          <div className="flex justify-center">
            <h1 className="text-left sm:text-center font-bold text-[#093686] text-2xl sm:text-6xl px-4 w-full ">
              {/* <span className='text-[#F60362]'>Fidya</span> and <span className='text-[#F60362] mr-2'>kaffarah</span>
                            donations are made when fasts during Ramadan are missed or broken. This is used to feed the poor and needy. */}
              Kaffarah: Pay Kaffarah, find out how much to pay for not fasting with our kaffarah calculator.
            </h1>
          </div>
          <div className="px-4  mt-8 sm:mt-16 text-[18px]  leading-5">
            <div className="text-[#14A2DC] text-left sm:text-center ">
              <div className="text-[24px] font-bold">In the Quran it states: </div>
              <br />
              <br />
              <div>
                "[Observing fasts] for a fixed number of days, but if any of you is ill or on a journey, the same number (should be made up) from
                other days. And as for those who can fast with difficulty, they have (a choice either to fast or) to feed a Miskin (poor person) (for
                every day). But whoever does good of his own accord, it is better for him. And that you fast, it is better for you if only you know."
                — Quran: 2:184
              </div>
              <div className="flex flex-col  items-center mt-6">
                <h2 className="text-left sm:text-center w-full font-bold text-[#093686] text-2xl sm:text-3xl">
                  Understanding Kaffarah: Purification Through Compensation
                </h2>
                <p className="font-montserratMedium text-lg text-[#78716C] text-left sm:text-center sm:w-6/12">
                  Kaffarah (كفارة) represents a concept of expiation or compensation for certain types of transgressions or omissions during acts of
                  worship, particularly in relation to fasting and oaths. The term itself, rooted deeply in the fabric of Islamic practice, suggests a
                  pathway towards rectifying mistakes by offering something in return.
                </p>
              </div>
              <div className="flex flex-col  items-center my-6">
                <h2 className="text-left sm:text-center w-full font-bold text-[#093686] text-2xl sm:text-3xl">What is Kaffarah?</h2>
                <p className="font-montserratMedium text-lg text-[#78716C] text-left sm:text-center sm:w-8/12">
                  Kaffarah is an Arabic term that translates to expiation or atonement in English. It is prescribed in Islamic law as a form of
                  redemption for specific acts that violate the sanctity of spiritual obligations, primarily focusing on fasting during the holy month
                  of Ramadan and the breaking of oaths. The concept embodies the merciful and compassionate nature of Islamic teachings, offering
                  believers a chance to make amends for lapses and ensuring that the spiritual purity and balance of their faith are maintained.
                </p>
              </div>
              <div className="flex flex-col  items-center my-6">
                <h2 className="text-left sm:text-center w-full font-bold text-[#093686] text-2xl sm:text-3xl">The Significance of Kaffarah</h2>
                <p className="font-montserratMedium text-lg text-[#78716C] text-left sm:text-center sm:w-8/12">
                  The obligation of kaffarah underscores the importance of intentionality and discipline in the practice of Islam. It serves as a
                  reminder of the sanctity of oaths and the significance of fasting as a spiritual cleanse, a physical purification, and a means of
                  attaining closer proximity to the divine. By stipulating compensatory measures, kaffarah also instills a sense of responsibility and
                  accountability in the individual's relationship with Allah and with themselves.
                </p>
              </div>
              <div className="flex flex-col  items-center my-6">
                <h2 className="text-left sm:text-center w-full font-bold text-[#093686] text-2xl sm:text-3xl">Instances Requiring Kaffarah</h2>
                <p className="font-montserratMedium text-lg text-[#78716C] text-left sm:text-center sm:w-8/12">
                  Kaffarah is most commonly associated with two specific scenarios:
                  <br />
                  <br />
                  <b>Breaking Fast During Ramadan</b>: If a fast during Ramadan is deliberately broken without a valid reason (such as eating or
                  drinking during daylight hours), kaffarah is required. This typically involves fasting for a consecutive 60 days or feeding 60 poor
                  people as a form of compensation for each day missed.
                  <br />
                  <br />
                  <b>Violating Oaths</b>: When a person swears an oath to Allah and then fails to fulfill it, kaffarah becomes necessary. The
                  prescribed expiation in this case involves feeding or clothing 10 needy people, or freeing a slave.
                </p>
              </div>
              <div className="flex flex-col  items-center my-6">
                <h2 className="text-left sm:text-center w-full font-bold text-[#093686] text-2xl sm:text-3xl">How to Fulfill Kaffarah</h2>
                <p className="font-montserratMedium text-lg text-[#78716C] text-left sm:text-center sm:w-8/12">
                  The fulfillment of kaffarah varies depending on the nature of the transgression:
                  <br />
                  <br />
                  <b>For Breaking Fast</b>: The individual can either fast for 60 consecutive days or provide meals to 60 individuals in need. The
                  meals should be of the same quality that one feeds their own family.
                  <br />
                  <br />
                  <b>For Breaking Oaths</b>: The person may feed or clothe 10 needy individuals, or fast for three consecutive days if they are unable
                  to perform the former.
                  <br />
                  <br />
                  Understanding and adhering to the requirements of kaffarah is a testament to the depth and compassion of Islamic teachings. It not
                  only allows for atonement but also emphasises the significance of maintaining integrity and discipline in fulfilling religious
                  obligations. Through kaffarah, Islam offers a merciful avenue for believers to rectify their mistakes, thus reinforcing the faith's
                  inherent principles of forgiveness and redemption.
                </p>
              </div>
            </div>

            <div className="flex  flex-row justify-center flex-wrap col-span-12 mt-10 sm:mb-16">
              {ramadanProducts.map((item, index) => {
                return (
                  <div key={`makedonation${index}`} className="basis-full sm:basis-2/4 md:basis-1/3 xl:basis-2/5 p-2">
                    <ShowMoreLessComponent image={item.image} name={item.name} desc={item.short_description || item.description} btnText={item.btnText} />
                  </div>
                );
              })}
            </div>
          </div>
        </section>

        {/* /* --------------------------------- Images --------------------------------- */}
        <section className="bg-[#F8F8F8] mt-10 sm:mt-20 py-10">
          <div className="md:container md:mx-auto flex justify-center flex-wrap  mt-12">
            <div className="w-full  flex-wrap justify-center items-center px-6 sm:px-0 relative">
              <div ref={sliderRefImages} className="keen-slider">
                {images.map((item, index) => {
                  return (
                    <div key={`carousel_images${index}`} className={`keen-slider__slide basis-full  flex justify-center rounded-xl sm:rounded-none`}>
                      <img src={item.src} alt={item.alt} className=" bg-top object-cover w-full object-top" />
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="w-full  flex justify-center">
              {loaded && instanceRefImages.current && (
                <div className="dots flex pt-3 pb-6">
                  {[...Array(instanceRefImages.current.track.details.slides.length).keys()].map(idx => {
                    return (
                      <button
                        key={idx}
                        onClick={() => {
                          instanceRefImages.current?.moveToIdx(idx);
                        }}
                        className={
                          'dot border-0 w-[10px] h-[10px] mx-[5px] rounded-full p-1 cursor-pointer' +
                          (currentSlideImages === idx ? ' active bg-[#253A7E]' : ' bg-gray-300')
                        }
                      ></button>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default KaffarahLandingPage;
